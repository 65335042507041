import { CarouselConfigProps, CarouselStep, InfiniteCarousel, PageStateContainer } from '@metaswiss/ui-kit';
import { useQuery } from '@tanstack/react-query';
import { uniqueId } from 'lodash';
import { FC, useMemo } from 'react';

import { api } from '../../../../api/msApi';
import { Languages } from '../../../../enums/language';
import { ApiResource } from '../../../../enums/resource.enum';
import { TenantPermissions } from '../../../../enums/tenantPermissions';
import { usePermissions } from '../../../../hooks/use-permissions/usePermissions';
import { useTenantConfig } from '../../../../hooks/use-tenant-config/useTenantConfig';
import { useTextTranslation } from '../../../../hooks/use-text-translation/useTextTranslation';
import { getFormattedLanguageName } from '../../../../shared/helpers/getFormattedLanguageName.helper';
import { getQueryKey } from '../../../../shared/helpers/getQueryKey.helper';

import { AboutUs } from './components/about-us/AboutUs';
import { BuyCardsContainer } from './components/buy-cards-container/BuyCardsContainer';
import { Documents } from './components/documents/Documents';
import { Header } from './components/header/Header';
import { HomeBanner } from './components/home-banner/HomeBanner';
import { Newsletter } from './components/newsletter/Newsletter';
import {
  DesktopContainer,
  LeftContainer,
  LeftContainerContent,
  RightContainer,
  TabletContainer,
} from './dashboard.styles';

const sliderConfig: CarouselConfigProps = {
  placeSlideButtonsOutside: false,
  showAdjacentSlides: false,
  displayArrowButtons: false,
  slideButtonsClickable: true,
  autoSlide: true,
};

type BannerImages = { desktopImgs: string[]; tabletImgs: string[] };

export const Dashboard: FC = () => {
  const { textTranslation, currentLanguage } = useTextTranslation();
  const tenantConfig = useTenantConfig();
  const permissions = usePermissions();

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: getQueryKey(ApiResource.COMPANY_DETAILS, currentLanguage),
    queryFn: () => api.contentful.getCompanyDetails(getFormattedLanguageName(currentLanguage)),
  });

  const {
    data: bondOffering,
    isFetching: bondOfferingLoading,
    isError: bondOfferingError,
  } = useQuery({
    queryKey: ['bondOffering'],
    queryFn: () => api.bonds.getTenantOffering(),
    enabled: false,
  });

  const {
    data: shareOffering,
    isFetching: shareOfferingLoading,
    isError: shareOfferingError,
  } = useQuery({
    queryKey: ['shareOffering'],
    queryFn: () => api.shares.getTenantOffering(),
    enabled: permissions[TenantPermissions.SHARE],
  });

  const {
    data: nftOffering,
    isFetching: nftOfferingLoading,
    isError: nftOfferingError,
  } = useQuery({
    queryKey: ['nftOffering'],
    queryFn: () => api.nftCollectionCore.getTenantOffering(),
    enabled: permissions[TenantPermissions.NFT],
  });

  const carouselSteps: CarouselStep[] = useMemo(() => {
    const { desktopImgs, tabletImgs }: BannerImages =
      currentLanguage === Languages.ENGLISH
        ? { desktopImgs: tenantConfig.enDesktopHomeBanners, tabletImgs: tenantConfig.enTabletHomeBanners }
        : { desktopImgs: tenantConfig.deDesktopHomeBanners, tabletImgs: tenantConfig.deTabletHomeBanners };

    return desktopImgs.map((img, index) => ({
      id: uniqueId() + index,
      element: <HomeBanner desktopImg={img} tabletImg={tabletImgs[index]} />,
    }));
  }, [currentLanguage, tenantConfig]);

  const companyDocuments = useMemo(() => {
    return data || [];
  }, [data]);

  return (
    <PageStateContainer
      isLoading={isLoading || bondOfferingLoading || shareOfferingLoading || nftOfferingLoading}
      isError={isError || bondOfferingError || shareOfferingError || nftOfferingError}
      showError={true}
      showLoading={true}
      textTranslation={textTranslation}
      showEmptyState={false}
      onTryAgain={refetch}
      maxWidth="77.5rem"
    >
      <DesktopContainer>
        <LeftContainer>
          <LeftContainerContent>
            <Header />
            <BuyCardsContainer nftOffering={nftOffering} shareOffering={shareOffering} bondOffering={bondOffering} />
            <AboutUs />
            <Newsletter />
          </LeftContainerContent>
        </LeftContainer>
        <RightContainer>
          <InfiniteCarousel steps={carouselSteps} config={sliderConfig} />
          {!!companyDocuments.length && <Documents documents={companyDocuments} />}
        </RightContainer>
      </DesktopContainer>
      <TabletContainer>
        <InfiniteCarousel steps={carouselSteps} config={sliderConfig} />
        <Header />
        <BuyCardsContainer nftOffering={nftOffering} shareOffering={shareOffering} bondOffering={bondOffering} />
        <AboutUs />
        {!!companyDocuments.length && <Documents documents={companyDocuments} />}
        <Newsletter />
      </TabletContainer>
    </PageStateContainer>
  );
};
