import { mapIsoStringToDate } from '@metaswiss/lib';
import { AlertIcon, Text, ThemedIcon } from '@metaswiss/ui-kit';
import { useQuery } from '@tanstack/react-query';
import { useTheme } from 'styled-components';

import { api } from '../../../../../api/msApi';
import { ApiResource } from '../../../../../enums/resource.enum';
import { AppState, useAppState } from '../../../../../global-state/zustand';
import { useTextTranslation } from '../../../../../hooks/use-text-translation/useTextTranslation';
import { defaultUser } from '../../../../../shared/helpers/defaultUser';
import { getQueryKey } from '../../../../../shared/helpers/getQueryKey.helper';
import { NoValueWrapper, SingleInformationContainer } from '../overview.styles';

export const PrivateUserOverview = () => {
  const theme = useTheme();
  const { textTranslation } = useTextTranslation();
  const user = useAppState((state: AppState) => state.user) || defaultUser;

  const { data: privateUser } = useQuery({
    queryKey: getQueryKey(ApiResource.PRIVATE_USER, user.id),
    queryFn: () => api.users.getPrivateUser(user.id),
    enabled: !!user?.id,
  });

  const checkIfUserValueExist = (value: string | undefined, translation: string) => {
    if (value) {
      return (
        <SingleInformationContainer>
          <Text fontSize="xsm" lineHeight="extraSmall">
            {textTranslation(translation)}
          </Text>
          <Text
            fontWeight="semi"
            color={theme.v2.text.headingPrimary}
            dataAttributes={{ dataTestid: `overview-${textTranslation(translation).replace(' ', '-').toLowerCase()}` }}
          >
            {value}
          </Text>
        </SingleInformationContainer>
      );
    }

    return (
      <NoValueWrapper>
        <ThemedIcon icon={AlertIcon} customStrokeColor={theme.v2.icon.error} size="medium" />
        <Text color={theme.v2.text.headingPrimary}>{textTranslation(translation)}</Text>
      </NoValueWrapper>
    );
  };

  return (
    <>
      {checkIfUserValueExist(privateUser?.privateUser.firstName, 'account.firstName')}
      {checkIfUserValueExist(privateUser?.privateUser.lastName, 'account.lastName')}
      {checkIfUserValueExist(
        privateUser?.privateUser?.birthDate && mapIsoStringToDate({ date: privateUser.privateUser.birthDate }),
        'account.birthDate'
      )}
      {checkIfUserValueExist(privateUser?.privateUser?.citizenship, 'account.citizenship')}
      {checkIfUserValueExist(
        privateUser?.phoneNumber ? `+${privateUser.phoneNumberPrefix} ${privateUser.phoneNumber}` : undefined,
        'account.phoneNumber'
      )}
    </>
  );
};
