export type DataAttributes = {
  [key in `data${Capitalize<string>}`]?: string;
};

export const convertToDataAttributes = (attributes?: DataAttributes) => {
  if (!attributes) return {};
  return Object.fromEntries(
    Object.entries(attributes).map(([key, value]) => [key.replace(/([A-Z])/g, '-$1').toLowerCase(), value])
  );
};
