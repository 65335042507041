import { FormInput, FormInputProps } from '@metaswiss/ui-kit';
import { useEffect, useMemo, useRef } from 'react';
import { FieldValues, useWatch } from 'react-hook-form';
import { ClipLoader } from 'react-spinners';
import { useTheme } from 'styled-components';

import { useAuthContext } from '../../../shared/auth-context/authContext';

export type ValidatedInputFieldProps<T extends FieldValues> = {
  checkValueExists: () => void;
  isSpinnerIcon: boolean;
  dataAttribute?: string;
} & Omit<FormInputProps<T>, 'disabled'>;

export const ValidatedInputField = <T extends FieldValues>({
  checkValueExists,
  name,
  error,
  label,
  control,
  isSpinnerIcon,
  dataAttribute = 'email-registration',
}: ValidatedInputFieldProps<T>) => {
  const theme = useTheme();
  const value = useWatch({ control, name });
  const validatedValue = value ?? ('' as string);
  const { checkedEmail } = useAuthContext((state) => state);
  const prevValue = useRef<string>();
  const isValueChanged = useMemo(() => {
    if (!prevValue.current) {
      return checkedEmail !== validatedValue;
    }
    return prevValue.current !== validatedValue;
  }, [validatedValue]);

  useEffect(() => {
    prevValue.current = validatedValue;
    if (!validatedValue || !!error || !isValueChanged) return;

    const timeoutId = setTimeout(checkValueExists, 50);

    return () => clearTimeout(timeoutId);
  }, [validatedValue, error, checkValueExists, isValueChanged]);

  return (
    <FormInput
      name={name}
      error={error}
      control={control}
      fill
      label={label}
      renderEndIcon={() => isSpinnerIcon && <ClipLoader size={'1.5rem'} color={theme.v2.icon.primary} />}
      dataAttributes={{ dataTestid: dataAttribute }}
      customErrorExists={!!error}
    />
  );
};
