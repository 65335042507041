import { Button, Heading, Text, ThemedIcon, IconProps } from '@metaswiss/ui-kit';
import { FC } from 'react';
import { useTheme } from 'styled-components';

import { useTextTranslation } from '../../../../../../hooks/use-text-translation/useTextTranslation';

import { ButtonWrapper, DescriptionWrapper, IconWrapper, StyledBuyCard, Wrapper } from './buyCard.styles';

interface BuyCardProps {
  icon: FC<IconProps>;
  title: string | number;
  subtitle: string;
  descriptionCount: string | number;
  description: string;
  buttonText: string;
  onClick: () => void;
  dataAttribute?: string;
}

export const BuyCard: FC<BuyCardProps> = ({
  title,
  icon,
  subtitle,
  buttonText,
  descriptionCount,
  description,
  onClick,
  dataAttribute,
}) => {
  const theme = useTheme();
  const { textTranslation } = useTextTranslation();

  return (
    <StyledBuyCard
      disableStateEffects
      override={{
        borderRadius: theme.v2.componentsRadius.cards.default,
        backgroundColor: theme.v2.surface.primary,
        borderColor: theme.v2.border.primary,
      }}
    >
      <IconWrapper>
        <ThemedIcon icon={icon} size={'midLarge'} customStrokeColor={theme.v2.icon.primary} />
      </IconWrapper>
      <Text fontSize={'sm'} fontWeight={'semi'} lineHeight={'medium'} color={theme.v2.text.disabled}>
        {textTranslation('home.from')}
      </Text>
      <Heading headingType={'h3'} fontWeight={'bold'} lineHeight={'large'} color={theme.v2.text.headingPrimary}>
        {title.toString()}
      </Heading>
      <Wrapper>
        <Text fontWeight={'bold'} color={theme.v2.text.headingPrimary}>
          {subtitle}
        </Text>
        <DescriptionWrapper>
          <Text fontSize={'xsm'} color={theme.v2.text.action} lineHeight={'extraSmall'}>
            {descriptionCount.toString()}
          </Text>
          <Text fontSize={'xsm'} lineHeight={'extraSmall'}>
            {description}
          </Text>
        </DescriptionWrapper>
      </Wrapper>
      <ButtonWrapper>
        <Button
          text={buttonText}
          onClick={onClick}
          fill
          borderRadius={'large'}
          dataAttributes={{ dataTestid: dataAttribute }}
        />
      </ButtonWrapper>
    </StyledBuyCard>
  );
};
