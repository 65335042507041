import { useTheme } from 'styled-components';

import { BaseButtonProps, BaseIconButton } from './BaseIconButton';

export const SquareIconButton: React.FC<Omit<BaseButtonProps, 'radius' | 'borderWidth'>> = ({
  onClick,
  icon,
  padding = '0.5rem',
  selectedButton = false,
  dataAttribute,
}) => {
  const theme = useTheme();

  return (
    <BaseIconButton
      onClick={onClick}
      padding={padding}
      icon={icon}
      radius={theme.v2.radius.smaller}
      selectedButton={selectedButton}
      borderWidth="0"
      dataAttribute={dataAttribute}
    />
  );
};
