import styled from 'styled-components';

export const StyledLabel = styled.label<{ $checked?: boolean }>`
  display: flex;
  border: 1px solid ${({ theme, $checked }) => ($checked ? theme.v2.border.information : theme.v2.border.primary)};
  width: 100%;
  padding: 0.75rem;
  justify-content: space-between;
  border-radius: ${({ theme }) => theme.v2.componentsRadius.cards.medium};
  align-items: center;
  cursor: pointer;
  user-select: none;
  background-color: ${({ theme, $checked }) => ($checked ? theme.v2.surface.informationLight : theme.v2.colors.white)};
`;
