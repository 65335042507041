import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';

import { ButtonsContainer } from '../../molecules/selection-popup/styles/buttonsContainer';
import { Button } from '../button';
import { Heading } from '../heading';
import { Input } from '../input';
import { Popup } from '../popup';
import { Text } from '../text';

import { DayPicker } from './DayPicker';
import { MonthPicker } from './MonthPicker';
import { YearPicker } from './YearPicker';
import { DateOption } from './styles/dateOption';
import { DatePickerContainer, DatePickerSlider, SliderWrapper } from './styles/datePickerSlider';
import {
  SelectDateOptionsWrapper,
  SelectedOptionTitleWrapper,
  SubtitleWrapper,
  TitleWrapper,
} from './styles/datePickerTitle';
import { DatePickerProps, DateProps } from './types';

const pickerOptions = ['DD', 'MM', 'YYYY'];

const initialDate: DateProps = {
  day: '',
  month: '',
  year: '',
};

const dateKeys: (keyof DateProps)[] = ['day', 'month', 'year'];

export const DatePicker = ({
  selectedDate,
  onSelect,
  title,
  maxDate = `${new Date()}`,
  minDate = '',
  dateSelectOptions,
  selectText,
  closeBtnText,
  confirmBtnText,
  tooltipDescrption,
  disabled = false,
  containerId,
  preferredLanguage,
  renderStartIcon,
  dataAtrributeValue,
  ...props
}: DatePickerProps) => {
  const theme = useTheme();
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [activePicker, setActivePicker] = useState<number>(0);
  const [date, setDate] = useState<DateProps>(initialDate);

  const maximumDate = useMemo(() => (isNaN(Date.parse(maxDate)) ? new Date() : new Date(maxDate)), [maxDate]);

  const minimumDate = useMemo(
    () =>
      isNaN(Date.parse(minDate)) || new Date(minDate).getTime() > maximumDate.getTime()
        ? new Date(`${maximumDate.getFullYear() - 100}/${maximumDate.getMonth() + 1}/${maximumDate.getDate()}`)
        : new Date(minDate),
    [minDate, maximumDate]
  );
  useEffect(() => {
    if (selectedDate) {
      const [day, month, year] = selectedDate.split('/');
      if (date.day === day && date.month === month && date.year === year) {
        setActivePicker(0);
      }
    }
  }, [selectedDate, date]);

  const setDay = (day: string) => {
    setDate((prevState) => ({ ...prevState, day }));
    setActivePicker((prevState) => prevState + 1);
  };

  const setMonth = (month: string) => {
    setDate((prevState) => ({ ...prevState, month }));
    setActivePicker((prevState) => prevState + 1);
  };

  const setYear = (year: string) => {
    setDate((prevState) => ({ ...prevState, year }));
  };

  const isDateInvalid = useMemo(() => !date.day || !date.month || !date.year, [date]);

  const handleSelectPicker = (index: number) => {
    if (index === 1 && !date.day) return;

    if (index === 2 && (!date.day || !date.month)) return;

    setActivePicker(index);
  };

  const handlePopupClose = useCallback(() => {
    setIsPopupOpen(false);

    if (!selectedDate) {
      setDate(initialDate);
      setActivePicker(0);
      return;
    }

    const [day, month, year] = selectedDate.split('/');
    setDate({ day, month, year });
  }, []);

  const handlePopupOpen = () => {
    setIsPopupOpen(true);
    setActivePicker(0);
    if (selectedDate) {
      const [day, month, year] = selectedDate.split('/');
      setDate({ day, month, year });
    }
  };

  const handleSelectDate = useCallback(() => {
    onSelect(`${date.day}/${date.month}/${date.year}`);
    setIsPopupOpen(false);
  }, [date, onSelect]);

  const pickersProps = {
    activePicker,
    date,
    minDate: minimumDate,
    maxDate: maximumDate,
    preferredLanguage,
  };

  return (
    <>
      <Input
        renderStartIcon={renderStartIcon}
        tooltipDescrption={tooltipDescrption}
        tootlipPosition={{ leftPostion: '1.2rem', topPosition: '-0.8rem' }}
        onFocus={handlePopupOpen}
        onEndIconClicked={!disabled ? handlePopupOpen : undefined}
        disableFocus={isPopupOpen}
        value={selectedDate}
        disabled={disabled || isPopupOpen}
        dataAttributes={{ dataTestid: dataAtrributeValue }}
        {...props}
      />
      <Popup isActive={isPopupOpen} height="fit-content" onOutsideClick={handlePopupClose} containerId={containerId}>
        <DatePickerContainer>
          {title && (
            <TitleWrapper>
              <Heading headingType="h4" fontWeight="bold">
                {title}
              </Heading>
            </TitleWrapper>
          )}
          <SliderWrapper>
            <SubtitleWrapper>
              <SelectedOptionTitleWrapper $activeOption={activePicker}>
                {dateSelectOptions.map((option) => (
                  <Text key={option} fontSize="sm" color={theme.v2.text.disabled}>{`${selectText} ${option}`}</Text>
                ))}
              </SelectedOptionTitleWrapper>
              <SelectDateOptionsWrapper>
                {pickerOptions.map((option, index) => (
                  <React.Fragment key={option}>
                    <DateOption
                      type="button"
                      disabled={!!index && !date[dateKeys[index - 1]]}
                      $optionValue={date[dateKeys[index]]}
                      onClick={() => handleSelectPicker(index)}
                    >
                      <Text fontWeight="bold" color={theme.v2.text.headingPrimary}>
                        {pickerOptions[index]}
                      </Text>
                      <Text fontWeight="bold" color={theme.v2.text.information}>
                        {date[dateKeys[index]]}
                      </Text>
                    </DateOption>
                    {option !== pickerOptions.at(-1) && <Text color={theme.v2.icon.neutral}>/</Text>}
                  </React.Fragment>
                ))}
              </SelectDateOptionsWrapper>
            </SubtitleWrapper>
            <DatePickerSlider $activeSlide={activePicker}>
              <DayPicker onChange={setDay} {...pickersProps} />
              <MonthPicker onChange={setMonth} {...{ ...pickersProps }} />
              <YearPicker onChange={setYear} {...{ ...pickersProps }} />
            </DatePickerSlider>
          </SliderWrapper>
          <ButtonsContainer>
            <Button
              variant="outlined"
              color="neutral"
              text={closeBtnText}
              fill
              type="button"
              onClick={handlePopupClose}
            />
            <Button text={confirmBtnText} fill type="button" disabled={isDateInvalid} onClick={handleSelectDate} />
          </ButtonsContainer>
        </DatePickerContainer>
      </Popup>
    </>
  );
};
