import { FullRegistrationPrivateUserRequestV4 } from '@metaswiss/api';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { api } from '../../../../../../../../api/msApi';
import { setResendToken } from '../../../../../../../../api/tokenHelpers';
import ControlledForm from '../../../../../../../../components/controlled-form/ControlledForm.tsx';
import { IdentityVerification } from '../../../../../../../../enums/identityVerification';
import { useTextTranslation } from '../../../../../../../../hooks/use-text-translation/useTextTranslation';
import { routes } from '../../../../../../../../router/routes';
import { useAuthContext } from '../../../../../../shared/auth-context/authContext.ts';
import { useSetAuthConfig } from '../../../../../../shared/auth-context/useSetAuthConfig.ts';
import { RegisterHeader } from '../../../../../shared/register-header/RegisterHeader';
import { DocumentProps } from '../../../../../shared/types/documentUploadType';
import { FullPrivateRegistrationData } from '../fullPrivateRegistrationState';

import { SingleDocumentUpload } from './SingleDocumentUpload';
import { IdCardUploadWrapper } from './documentUpload.styles';
import {
  createFullPrivateDocumentUploadSchema,
  FullPrivateRegistrationDocumentUploadData,
} from './documentUploadSchema';
import { idUploadData } from './idUploadData';
import { DocumentSideType } from './types';

export const DocumentUploadView = () => {
  const { textTranslation } = useTextTranslation();
  const { setIsValid, setLoading, setUserDetails, prevStep, clearRegistrationContext, setUserEmailField } =
    useAuthContext((state) => state);
  const userDetails = useAuthContext((state) => state.userDetails) as FullPrivateRegistrationData;
  const navigate = useNavigate();

  const {
    getValues,
    setValue,
    formState: { isValid },
    handleSubmit,
  } = useFormContext<FullPrivateRegistrationDocumentUploadData>();

  const { currentLanguage } = useTextTranslation();

  const { mutate, isLoading } = useMutation(
    (data: FullRegistrationPrivateUserRequestV4) => {
      const [day, month, year] = data.birthDate.split('/').map(Number);
      return api.auth.fullRegistrationPrivateUser({
        ...data,
        birthDate: new Date(year, month - 1, day).toString(),
      });
    },
    {
      onSuccess: (result) => {
        setResendToken(result.resendToken);
        setUserDetails({});
        navigate(routes.register.registerSuccess);
        clearRegistrationContext();
        setUserEmailField(userDetails.email);
      },
      onError: () => {
        navigate(routes.register.registerFailed);
      },
    }
  );

  const setRegistrationFlowDocument = ({
    documentSide,
    ...documentProps
  }: DocumentProps & { documentSide: DocumentSideType }) => {
    setUserDetails({
      identityDocument: {
        ...userDetails.identityDocument,
        [documentSide]: {
          ...documentProps,
        },
      },
    });
  };

  useEffect(() => {
    setIsValid(isValid);
  }, [isValid, setIsValid]);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);

  useEffect(() => {
    return () => {
      setIsValid(true);
      setLoading(false);
    };
  }, [setIsValid, setLoading]);

  const createData = () => {
    return userDetails.identityVerification === IdentityVerification.ID
      ? {
          email: userDetails.email,
          password: userDetails.password,
          passwordConfirmation: userDetails.passwordConfirmation,
          firstName: userDetails.firstName,
          lastName: userDetails.lastName,
          birthDate: userDetails.birthDate,
          phoneNumber: userDetails.phoneNumber,
          phoneNumberPrefix: userDetails.phoneNumberPrefix,
          firstTimeLogin: false,
          preferredLanguage: currentLanguage,
          city: userDetails.city,
          zipCode: userDetails.zipCode,
          address: userDetails.address,
          citizenship: userDetails.citizenship.label,
          ethWallet: '',
          btcWallet: '',
          termsAndConditions: userDetails.termsAndConditions,
          richNetWorth: userDetails.qualifiedInvestor,
          corporateMarketExperience: userDetails.qualifiedInvestor,
          countryId: userDetails.country.value,
          image: userDetails.profilePhoto.fileId,
          proofOfDomicile: userDetails.proofOfDomicile.fileId,
          frontSide: userDetails.identityDocument.frontSide.fileId,
          backSide: userDetails.identityDocument.backSide.fileId,
        }
      : {
          email: userDetails.email,
          password: userDetails.password,
          passwordConfirmation: userDetails.passwordConfirmation,
          firstName: userDetails.firstName,
          lastName: userDetails.lastName,
          birthDate: userDetails.birthDate,
          phoneNumber: userDetails.phoneNumber,
          phoneNumberPrefix: userDetails.phoneNumberPrefix,
          firstTimeLogin: false,
          preferredLanguage: currentLanguage,
          city: userDetails.city,
          zipCode: userDetails.zipCode,
          address: userDetails.address,
          citizenship: userDetails.citizenship.label,
          ethWallet: '',
          btcWallet: '',
          termsAndConditions: userDetails.termsAndConditions,
          richNetWorth: userDetails.qualifiedInvestor,
          corporateMarketExperience: userDetails.qualifiedInvestor,
          countryId: userDetails.country.value,
          image: userDetails.profilePhoto.fileId,
          passport: userDetails.identityDocument.passport.fileId,
          proofOfDomicile: userDetails.proofOfDomicile.fileId,
        };
  };

  const handleFinalFormSubmit = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    mutate(createData());
  }, [currentLanguage, mutate, userDetails]);

  useSetAuthConfig(
    {
      wrapperStyle: 'registrationWizard',
      containerStyle: 'registrationWizard',
      backButton: {
        onClick: () => prevStep(),
        text: 'button.back',
        dataAttributes: { dataTestid: 'back-button' },
      },
      nextButton: {
        text: 'registration.createAccount',
        dataAttributes: { dataTestid: 'createaccount-button' },
        onClick: () => handleSubmit(() => handleFinalFormSubmit())(),
      },
      showStepper: true,
    },
    [prevStep, handleSubmit, handleFinalFormSubmit]
  );

  return (
    <>
      <RegisterHeader
        descriptionText={textTranslation(
          userDetails?.identityVerification === IdentityVerification.ID
            ? `registration.upload${IdentityVerification.ID}Title`
            : `registration.upload${IdentityVerification.PASSPORT}Title`
        )}
      />
      {userDetails?.identityVerification === IdentityVerification.ID ? (
        <IdCardUploadWrapper>
          {idUploadData.map(({ title, idSide }) => (
            <SingleDocumentUpload
              key={`${idSide}-${title}`}
              title={title}
              userDetails={userDetails}
              documentSide={idSide}
              setValue={setValue}
              getValue={getValues}
              setRegistrationFlowDocument={setRegistrationFlowDocument}
            />
          ))}
        </IdCardUploadWrapper>
      ) : (
        <SingleDocumentUpload
          title="registration.passportUploadTitle"
          userDetails={userDetails}
          documentSide="passport"
          setValue={setValue}
          getValue={getValues}
          setRegistrationFlowDocument={setRegistrationFlowDocument}
          isPassport
        />
      )}
    </>
  );
};

export const DocumentUpload = () => {
  const userDetails = useAuthContext((state) => state.userDetails) as FullPrivateRegistrationData;
  return (
    <ControlledForm
      validationSchema={createFullPrivateDocumentUploadSchema}
      defaultValues={{
        frontSide: userDetails?.identityDocument.frontSide.fileId,
        backSide: userDetails?.identityDocument.backSide.fileId,
        passport: userDetails?.identityDocument.passport.fileId,
      }}
    >
      <DocumentUploadView />
    </ControlledForm>
  );
};
