import { PortfolioShareAssetsDTO } from '@metaswiss/api';
import { formatAmountWithoutDecimals } from '@metaswiss/lib';
import {
  BarChartWithoutBorder,
  Button,
  DataStatusPage,
  ItemsCounter,
  PageStateContainer,
  PortfolioTransactionCard,
  Text,
  TextLink,
  ThemedIcon,
} from '@metaswiss/ui-kit';
import { BarChart } from '@metaswiss/ui-kit/src/iconography/BarChart';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { api } from '../../../../api/msApi';
import { ProductType } from '../../../../enums/productType.enum';
import { ApiResource } from '../../../../enums/resource.enum';
import { useTextTranslation } from '../../../../hooks/use-text-translation/useTextTranslation';
import { routes } from '../../../../router/routes';
import { getQueryKey } from '../../../../shared/helpers/getQueryKey.helper';
import { PortfolioBanner } from '../shared/components/portfolio-banner/PortfolioBanner';
import { PortfolioShareCard } from '../shared/components/portfolio-share-card/PortfolioShareCard';

import { HistoryWrapper, IndicatorGroup, TransactionsContainer } from './smartShares.styles';

const numberOfDisplayedShares = 4;

export const SmartSharesPortfolio = () => {
  const { textTranslation } = useTextTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  const {
    data: invested,
    isLoading: isLoadingInvested,
    isError: isErrorInvested,
    refetch: refetchInvested,
  } = useQuery({
    queryKey: getQueryKey(ApiResource.SHARE_PORTFOLIO_STATISTICS),
    queryFn: () => api.clientPortfolio.getSmartShareInvestmentStatistics(),
  });

  const {
    data: portfolio,
    isLoading: isLoadingPortfolio,
    isError: isErrorPortfolio,
    refetch: refetchPortfolio,
  } = useQuery({
    queryKey: getQueryKey(ApiResource.PORTFOLIO, ProductType.ACTIONS),
    queryFn: () => api.clientPortfolio.getSmartShareInvestments(0, numberOfDisplayedShares, 0),
    enabled: true,
  });

  const portfolioItems: PortfolioShareAssetsDTO[] = useMemo(
    () => portfolio?.items as unknown as PortfolioShareAssetsDTO[],
    [portfolio]
  );

  const portfolioTotalItems = useMemo(() => portfolio?.totalItems || 0, [portfolio]);

  return (
    <PageStateContainer
      showLoading={true}
      isLoading={isLoadingPortfolio || isLoadingInvested}
      showError={true}
      isError={isErrorPortfolio || isErrorInvested}
      onTryAgain={async () => await Promise.all([refetchPortfolio(), refetchInvested()])}
      showEmptyState={true}
      isEmptyState={!portfolioTotalItems}
      textTranslation={textTranslation}
      customEmptyStateComponent={
        <DataStatusPage
          icon={
            <ThemedIcon
              icon={BarChartWithoutBorder}
              customStrokeColor={theme.v2.text.information}
              customColor={theme.v2.surface.informationLight}
              size={'full'}
            />
          }
          title={textTranslation('portfolio.noShares')}
          content={textTranslation('portfolio.noSharesPurchased')}
          buttonText={textTranslation('portfolio.startInvesting')}
          action={() => navigate(routes.offering.smartShares.root)}
          additionalContent={textTranslation('portfolio.noSharesFollow')}
          iconWidth={'8.5rem'}
          iconHeight={'8.5rem'}
        />
      }
    >
      <PortfolioBanner
        type={ProductType.ACTIONS}
        heading={textTranslation('portfolio.investInSmartShares')}
        text={textTranslation('portfolio.fullyDigitalizedEquity')}
      />
      <PortfolioShareCard
        title={'portfolio.shareBalance'}
        quantity={invested?.totalSharesBought || 0}
        sharesText={'portfolio.shares'}
        totalAmount={invested?.totalInvestedAmount?.split(' ')[0] || '0'}
      />
      <HistoryWrapper>
        <Text fontSize={'lg'} lineHeight={'h4'} fontWeight={'bold'} color={theme.v2.text.headingPrimary}>
          {textTranslation('portfolio.smartShareBuyingHistory')}
        </Text>
        {portfolioTotalItems > numberOfDisplayedShares && (
          <TextLink onClick={() => navigate('/portfolio/smart-shares/history')}>{`${textTranslation(
            'portfolio.viewAll'
          )} (${formatAmountWithoutDecimals(portfolioTotalItems)})`}</TextLink>
        )}
      </HistoryWrapper>
      <TransactionsContainer>
        {portfolioItems?.map((portfolioShareAssets) => {
          return (
            <PortfolioTransactionCard
              key={portfolioShareAssets.id}
              headerLabel={textTranslation('portfolio.pricePerShare')}
              headerValue={`${portfolioShareAssets.pricePerShare}`}
              quantityLabel={textTranslation('portfolio.quantity')}
              quantity={formatAmountWithoutDecimals(portfolioShareAssets.sharesBought)}
              amountLabel={textTranslation('portfolio.amount')}
              amount={portfolioShareAssets.investedAmount}
              icon={BarChart}
              onClick={() => navigate(`${routes.portfolio.smartShares.root}/${portfolioShareAssets.id}`)}
            />
          );
        })}
      </TransactionsContainer>

      {portfolioTotalItems > numberOfDisplayedShares && (
        <IndicatorGroup>
          <ItemsCounter
            displayedItems={numberOfDisplayedShares}
            totalItems={portfolioTotalItems}
            ofText={textTranslation('portfolio.of')}
            itemsText={textTranslation('portfolio.assets')}
            displayedItemsText={formatAmountWithoutDecimals(numberOfDisplayedShares)}
            totalItemsText={formatAmountWithoutDecimals(portfolioTotalItems)}
          />

          <Button
            onClick={() => navigate(routes.portfolio.smartShares.history)}
            text={`${textTranslation('portfolio.viewAll')} (${formatAmountWithoutDecimals(portfolioTotalItems)})`}
            fill
          />
        </IndicatorGroup>
      )}
    </PageStateContainer>
  );
};
