import { FC, useMemo } from 'react';
import { useTheme } from 'styled-components';

import { ExpandableTabletCard, Heading, PaymentBill, Text } from '../../../components';

import {
  BillInfoWrapper,
  ExtandableBodyWrapper,
  PriceInfoWrapper,
  ResponsiveBillContainer,
} from './responsiveBill.styles';
import { ResponsiveBillProps } from './types';

export const ResponsiveBill: FC<ResponsiveBillProps> = ({
  title,
  amount,
  amountText,
  children,
  extraBillInfo,
  buttonText,
  onClick,
  isResponsive = true,
  billBottomPadding,
  isPaymentSuccessful,
  ...props
}) => {
  const theme = useTheme();
  const VisibleContentComponent = useMemo(
    () => (
      <BillInfoWrapper>
        <PriceInfoWrapper>
          <Text color={theme.v2.text.headingPrimary}>{amountText}</Text>
          <Heading
            headingType={'h4'}
            fontWeight={'bold'}
            color={theme.v2.text.action}
            dataAttributes="success-totalamount"
          >
            {amount}
          </Heading>
        </PriceInfoWrapper>
        {extraBillInfo &&
          extraBillInfo.map((info) => (
            <PriceInfoWrapper key={info.text}>
              <Text color={theme.v2.text.headingPrimary} dataAttributes={{ dataTestid: `success-${info.text}` }}>
                {info.text}
              </Text>
              <Text color={theme.v2.text.headingPrimary} fontWeight={'bold'}>
                {info.price}
              </Text>
            </PriceInfoWrapper>
          ))}
      </BillInfoWrapper>
    ),
    [amountText, amount, extraBillInfo]
  );

  const ExtandableContentComponent = useMemo(
    () => <ExtandableBodyWrapper>{children}</ExtandableBodyWrapper>,
    [children]
  );

  return (
    <ResponsiveBillContainer $isResponsive={isResponsive} $billBottomPadding={billBottomPadding}>
      <ExpandableTabletCard
        title={title}
        includeArrowButton={true}
        visibleContentComponent={VisibleContentComponent}
        extandableContentComponent={ExtandableContentComponent}
        buttonText={buttonText}
        onClick={onClick}
        {...props}
      />
      <PaymentBill
        title={title}
        billInfoComponent={VisibleContentComponent}
        isPaymentSuccessful={isPaymentSuccessful}
        {...props}
      >
        {children}
      </PaymentBill>
    </ResponsiveBillContainer>
  );
};
