import styled, { css } from 'styled-components';

import { TextTemplate } from '../../../../shared/typography/TextTemplate.styles';
import { BaseCard } from '../base-card/BaseCard';

import { NotificationCardWrapperProps } from './types';

export const NotificationBaseCard = styled(BaseCard)`
  overflow-y: visible;
`;
export const NotificationCardWrapper = styled.div<NotificationCardWrapperProps>`
  padding: 1rem;
  height: 6rem;
  display: flex;
  scroll-margin: 1rem;
  border-radius: ${({ theme }) => theme.v2.radius.smaller};
  background-color: ${({ theme, $selectedItem }) =>
    $selectedItem ? theme.v2.surface.informationLight : theme.v2.surface.primary};
  border: 1px solid
    ${({ theme, $selectedItem }) => ($selectedItem ? theme.v2.border.information : theme.v2.border.primary)};

  &:hover {
    cursor: pointer;
    ${({ $selectedItem }) =>
      !$selectedItem &&
      css`
        box-shadow:
          0px 0px 16px 0px #00000005,
          0px 1px 0px 0px #00000005,
          0px 10px 24px 0px #00000005;
      `};
  }
`;

export const NotificationItemContent = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const NotificationIconWrapper = styled.div`
  position: relative;
  width: 2.9rem;
  height: 2.5rem;
`;

export const NotificationItemIcon = styled.span`
  position: absolute;
  width: 2.5rem;
  height: 2.5rem;
  display: grid;
  place-content: center;
  border-radius: 50%;
`;

export const NotificationInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.25rem;
  height: 100%;
`;

export const NotificationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  > ${TextTemplate} {
    flex: 1;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

export const NotificationDate = styled.div`
  display: block;
  padding-left: 0.25rem;
`;

export const NotificationDot = styled.span`
  width: 0.5rem;
  display: inline-block;
  margin-left: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.v2.icon.error};
`;

export const TextEllipsisHelper = styled.div`
  ${TextTemplate} {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;
