import { FormInput } from '@metaswiss/ui-kit';
import { StyledCustomError } from '@metaswiss/ui-kit/src/components/atoms/input/input.styles.ts';
import { useEffect } from 'react';
import { Control, FieldError, FieldValues, Path, useWatch } from 'react-hook-form';

import { useTextTranslation } from '../../hooks/use-text-translation/useTextTranslation';

import { PasswordConfirmationWrapper } from './passwordsValidationInputs.styles';

type PasswordValidationProps<T extends FieldValues> = {
  control: Control<T>;
  passwordName: Path<T>;
  passwordConfirmationName: Path<T>;
  passwordError?: FieldError;
  passwordConfirmationError?: FieldError;
  passwordLabel: string;
  passwordConfirmationLabel: string;
  doPasswordsMatch: boolean;
  setDoPasswordsMatch: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
  dataAttributes?: {
    password?: string;
    confirmPassword?: string;
  };
};

export const PasswordValidationInputs = <T extends FieldValues>({
  control,
  passwordName,
  passwordConfirmationName,
  passwordError,
  passwordConfirmationError,
  passwordLabel,
  passwordConfirmationLabel,
  doPasswordsMatch,
  setDoPasswordsMatch,
  disabled,
  dataAttributes = { password: 'password-registration', confirmPassword: 'confirm-password' },
}: PasswordValidationProps<T>) => {
  const { textTranslation } = useTextTranslation();

  const [password = '', passwordConfirmation = ''] = useWatch({
    control,
    name: [passwordName, passwordConfirmationName],
  });

  useEffect(() => {
    if (passwordError && !doPasswordsMatch) setDoPasswordsMatch(true);

    if (password.length < 5 || passwordConfirmation.length < 5 || passwordError || passwordConfirmationError) return;

    if (password !== passwordConfirmation) {
      setDoPasswordsMatch(false);
    } else {
      setDoPasswordsMatch(true);
    }
  }, [password, passwordConfirmation, passwordError, passwordConfirmationError, setDoPasswordsMatch]);

  return (
    <>
      <FormInput
        type="password"
        name={passwordName}
        error={passwordError}
        control={control}
        fill
        label={passwordLabel}
        disabled={disabled}
        dataAttributes={{ dataTestid: dataAttributes.password }}
      />
      <PasswordConfirmationWrapper>
        <FormInput
          type="password"
          name={passwordConfirmationName}
          error={passwordConfirmationError}
          control={control}
          fill
          label={passwordConfirmationLabel}
          customErrorExists={!doPasswordsMatch}
          disabled={disabled}
          dataAttributes={{ dataTestid: dataAttributes.confirmPassword }}
        />
        {password && passwordConfirmation && !doPasswordsMatch && !passwordConfirmationError && (
          <StyledCustomError>{textTranslation('noMatchPassword')}</StyledCustomError>
        )}
      </PasswordConfirmationWrapper>
    </>
  );
};
