import { regex } from '@metaswiss/lib';
import { InputNumber, ItemWithCustomIconProps } from '@metaswiss/ui-kit';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import { InputNumberProps } from '../../atoms/input';
import { ItemProps } from '../selection-popup/types.ts';

import { StyledContainer } from './FormInput';

export type FormInputNumberProps<T extends FieldValues> = {
  name?: Path<T>;
  error?: string;
  control?: Control<T>;
  fill?: boolean;
  disabled?: boolean;
  defaultItem?: ItemProps | ItemWithCustomIconProps;
  pattern?: RegExp;
} & Omit<InputNumberProps, 'name' | 'error' | 'onChange' | 'value' | 'pattern'>;

export const FormInputNumber = <T extends FieldValues>({
  name,
  error,
  control,
  fill,
  disabled = false,
  pattern = regex.numbersOnly,
  ...inputProps
}: FormInputNumberProps<T>) => {
  return (
    <StyledContainer $fill={fill}>
      {name && (
        <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <InputNumber
              onChange={(e) => {
                const value = pattern && !pattern.test(e.target.value) ? field.value : e.target.value;
                field.onChange(value);
              }}
              value={field.value ?? ''}
              error={error}
              disabled={disabled}
              {...inputProps}
            />
          )}
        />
      )}
    </StyledContainer>
  );
};
