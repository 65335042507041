import { CSSProperties, FC } from 'react';

import { ColorPalette, Colors } from '../../../../..';
import { IconProps } from '../../../../../iconography/iconProps';
import { ResourceInfoVariant } from '../../../atoms/resource-info/enums';

export enum ProductType {
  BOND_PAPERS = 'BOND_PAPERS',
  ACTIONS = 'ACTIONS',
  NFT = 'NFT',
}

type StickyBannerProps = {
  isStickyBanner: true;
  headerClassName?: string | null;
  tabsClassName?: string | null;
  wrapperClassName?: string;
};

type NonStickyBannerProps = {
  isStickyBanner: false;
  headerClassName?: never;
  tabsClassName?: never;
  wrapperClassName?: never;
};

type WithBottomElement = {
  renderBottomElement?: JSX.Element;
  isBottomElementVisibleOnSticky?: boolean;
  bottomElementClassName?: string;
};

type WithoutBottomElement = {
  renderBottomElement?: never;
  isBottomElementVisibleOnSticky?: never;
  bottomElementClassName?: never;
};

type BaseSecondaryBannerProps = {
  title: string;
  subTitle: string;
  totalAmount?: string;
  renderElement?: JSX.Element;
  itemsAlignment?: CSSProperties['alignItems'];
  backgroundColor?: Colors<ColorPalette>;
  filters?: boolean;
  renderStickyElement?: JSX.Element;
} & (StickyBannerProps | NonStickyBannerProps) &
  (WithBottomElement | WithoutBottomElement);

type WithTypeSecondaryBannerProps = BaseSecondaryBannerProps & {
  type: ProductType;
  icon?: never;
  variant?: never;
};

type WithoutTypeSecondaryBannerProps = BaseSecondaryBannerProps & {
  icon: FC<IconProps>;
  variant: ResourceInfoVariant;
  type?: never;
};

export type SecondaryBannerProps = WithTypeSecondaryBannerProps | WithoutTypeSecondaryBannerProps;
