import { AssetUploadUnassignedResponse } from '@metaswiss/api';
import { UploadFile, Text } from '@metaswiss/ui-kit';
import { AcceptedFileTypes } from '@metaswiss/ui-kit/src/components/molecules/upload-file/enums';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import ControlledForm from '../../../../../../../../components/controlled-form/ControlledForm.tsx';
import { usePostAndGetDocument } from '../../../../../../../../hooks/use-post-and-get-document/usePostAndGetDocument';
import { useTextTranslation } from '../../../../../../../../hooks/use-text-translation/useTextTranslation';
import { useAuthContext } from '../../../../../../shared/auth-context/authContext.ts';
import { useSetAuthConfig } from '../../../../../../shared/auth-context/useSetAuthConfig.ts';
import { StepTextContainer } from '../../../../../register.styles';
import { RegisterHeader } from '../../../../../shared/register-header/RegisterHeader';
import {
  FullCorporateRegistrationData,
  initialFullCorporateRegistrationValues,
} from '../fullCorporateRegistrationState';

import {
  FullCorporateRegistrationUploadLogoData,
  fullCorporateRegistrationSchema,
  createFullCorporateLogoSchema,
} from './uploadCompanyLogoSchema';

export const UploadCompanyLogoView = () => {
  const { textTranslation } = useTextTranslation();
  const { setIsValid, setUserDetails, prevStep, nextStep } = useAuthContext((state) => state);
  const userDetails = useAuthContext((state) => state.userDetails) as FullCorporateRegistrationData;
  const {
    getValues,
    setValue,
    formState: { isValid },
  } = useFormContext<FullCorporateRegistrationUploadLogoData>();

  const setRegistrationFlowCompanyLogo = ({ fileId, url }: { fileId: string; url: string }) => {
    setUserDetails({
      picture: { fileId: fileId, url: url },
    });
  };

  const { mutate, isLoading, isError } = usePostAndGetDocument({
    onMutate: handleCompanyLogoMutation,
    onRefetch: handleCompanyLogoRefetch,
    fileId: userDetails?.picture?.fileId ?? '',
  });

  function handleCompanyLogoMutation({ fileId, url }: AssetUploadUnassignedResponse) {
    setValue(fullCorporateRegistrationSchema.picture, fileId, { shouldValidate: true });
    setRegistrationFlowCompanyLogo({ fileId: fileId, url: url });
  }

  function handleCompanyLogoRefetch(documentUrl: string) {
    setRegistrationFlowCompanyLogo({
      fileId: getValues('picture'),
      url: documentUrl,
    });
  }

  useEffect(() => {
    setIsValid(isValid);
    return () => {
      setIsValid(true);
    };
  }, [isValid, setIsValid]);

  const handleProfilePhotoUpload = (file: File) => {
    mutate(file);
  };

  const handleProfilePhotoDelete = () => {
    setRegistrationFlowCompanyLogo({ fileId: '', url: '' });
    setValue('picture', '', { shouldValidate: true });
  };

  useSetAuthConfig(
    {
      wrapperStyle: 'registrationWizard',
      containerStyle: 'registrationWizard',
      backButton: {
        onClick: () => prevStep(),
        text: 'button.back',
        dataAttributes: { dataTestid: 'back-button' },
      },
      nextButton: {
        text: 'button.next',
        onClick: () => nextStep(),
        dataAttributes: { dataTestid: 'next-button' },
      },
      showStepper: true,
    },
    [nextStep, prevStep]
  );

  return (
    <>
      <RegisterHeader descriptionText={textTranslation('registration.corporateRegistration.logoTitle')} />
      <StepTextContainer>
        <Text fontWeight="bold">{textTranslation('registration.corporateRegistration.logoSubtitle')}</Text>
      </StepTextContainer>
      <UploadFile
        uploadFile={handleProfilePhotoUpload}
        onDelete={handleProfilePhotoDelete}
        isLoading={isLoading}
        isError={isError}
        initialTitle={textTranslation('registration.corporateRegistration.uploadLogoTitle')}
        initialSubtitle={textTranslation('registration.corporateRegistration.uploadLogoSubtitle')}
        invalidTitle={textTranslation('registration.corporateRegistration.invalidLogoTitle')}
        invalidSubtitle={textTranslation('registration.corporateRegistration.uploadLogoSubtitle')}
        failedTitle={textTranslation('registration.corporateRegistration.failedPhotoLogoTitle')}
        failedSubtitle={textTranslation('registration.corporateRegistration.failedLogoUploadSubtitle')}
        acceptedFileTypes={AcceptedFileTypes.IMAGES}
        $width="37.5rem"
        $height="26rem"
        onViewDocument={() => window.open(userDetails.picture.url, '_blank')}
        value={userDetails?.picture || initialFullCorporateRegistrationValues.picture}
        dataAttributes={{ dataTestid: 'company-logo' }}
      />
    </>
  );
};

export const UploadCompanyLogo = () => {
  const userDetails = useAuthContext((state) => state.userDetails) as FullCorporateRegistrationData;

  return (
    <ControlledForm
      validationSchema={createFullCorporateLogoSchema}
      defaultValues={{ picture: userDetails.picture?.fileId || '' }}
    >
      <UploadCompanyLogoView />
    </ControlledForm>
  );
};
