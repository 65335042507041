import { NftCollectionV5Response } from '@metaswiss/api';
import { formatAmount, formatAmountWithoutDecimals, getFirstSixDigitsOfSerialNumber } from '@metaswiss/lib';
import {
  Button,
  DataStatusPage,
  ErrorRegionIcon,
  FlatList,
  ItemsCounter,
  PageStateContainer,
  Text,
  TextLink,
  ThemedIcon,
} from '@metaswiss/ui-kit';
import { NftHolder } from '@metaswiss/ui-kit/src/components/molecules/nft-holder/NftHolder';
import { useQuery } from '@tanstack/react-query';
import { FC, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { api } from '../../../../../api/msApi';
import { CollectionDetails } from '../../../../../components/collection-details/CollectionDetails';
import { Ribbon } from '../../../../../components/ribbon/Ribbon';
import { ApiResource } from '../../../../../enums/resource.enum';
import { AppState, useAppState, useHeaderOptions, useShellNavigationState } from '../../../../../global-state/zustand';
import { useGetCollectionByParams } from '../../../../../hooks/use-get-collection-by-params/useGetCollectionByParams';
import { useTextTranslation } from '../../../../../hooks/use-text-translation/useTextTranslation';
import { routes } from '../../../../../router/routes';
import { getQueryKey } from '../../../../../shared/helpers/getQueryKey.helper';
import { NftCommonResponse, NftCommonStatusEnum } from '../nft.response';
import { CollectiblesViewWrapper, CounterButtonWrapper } from '../smartCollectibles.styles';

const numberOfDisplayedNfts = 12;

interface MyCustomError {
  message: string;
  messageCode: string;
  code: number;
}

export interface Response {
  body: MyCustomError;
}

export const SingleCollection: FC = () => {
  const theme = useTheme();
  const { textTranslation } = useTextTranslation();
  const currency = useAppState((state: AppState) => state.currency);
  const navigate = useNavigate();
  const { removeTabs } = useShellNavigationState();
  const { setHeaderTitle, setHeaderLoading } = useHeaderOptions();

  const {
    id: collectionId,
    collection,
    isLoading: isLoadingCollection,
    isError: isErrorCollection,
    refetch: refetchCollection,
    regionError: regionError,
  } = useGetCollectionByParams();

  useEffect(() => {
    removeTabs();
    setHeaderTitle(collection?.name);
  }, [removeTabs, setHeaderTitle, textTranslation, collection?.name]);

  useEffect(() => {
    setHeaderLoading(!collection?.name);
  }, [collection?.name, setHeaderLoading]);

  const {
    data: nfts,
    isLoading: isLoadingNfts,
    isError: isErrorNfts,
    refetch: refetchNfts,
  } = useQuery({
    queryKey: getQueryKey(ApiResource.NFT, collectionId),
    queryFn: () => api.nft.getNftByCollectionId(collectionId, 0, numberOfDisplayedNfts),
    enabled: !!collectionId,
  });

  const {
    data: statistics,
    isError: isErrorStatistics,
    isLoading: isLoadingStatistics,
  } = useQuery({
    queryKey: getQueryKey(ApiResource.NFT_COLLECTION_STATISTICS, collectionId),
    queryFn: () => api.nftCollection.getNftCollectionStatistics(collectionId),
    enabled: !!collectionId,
  });

  const isLoading = useMemo(() => {
    if (regionError) {
      return false;
    }
    if (!collectionId) {
      return isLoadingCollection;
    }
    return isLoadingCollection || isLoadingNfts || isLoadingStatistics;
  }, [isLoadingCollection, isLoadingNfts, isLoadingStatistics, regionError, collectionId]);

  const isError = useMemo(() => {
    if (regionError) {
      return isErrorNfts || isErrorStatistics;
    }
    if (!collectionId) {
      return isErrorCollection;
    }
    return isErrorNfts || isErrorCollection || isErrorStatistics;
  }, [isErrorCollection, isErrorNfts, isErrorStatistics, regionError, collectionId]);

  const randomNftsFromCollection = useMemo(
    () => nfts?.items?.slice(0, numberOfDisplayedNfts) as unknown as NftCommonResponse[],
    [nfts?.items]
  );

  const onTryAgain = async () => {
    await Promise.all([refetchCollection(), refetchNfts(), refetchCollection()]);
  };

  return (
    <PageStateContainer
      showLoading={true}
      isLoading={isLoading}
      isError={isError}
      showError={true}
      showEmptyState={true}
      isEmptyState={nfts?.items.length === 0}
      onTryAgain={onTryAgain}
      emptyStateTitle={'offering.nothingHereYet'}
      emptyStateContent={'offering.noCollectiblesInTheCollection'}
      customEmptyStateComponent={
        regionError && (
          <DataStatusPage
            icon={<ThemedIcon icon={ErrorRegionIcon} size={'full'} />}
            title={textTranslation('global.nothingHere')}
            content={textTranslation('global.notPossibleCollectibles')}
            action={onTryAgain}
            iconWidth={'12rem'}
            iconHeight={'12rem'}
            buttonVariant={'outlined'}
            buttonColor={'neutral'}
          />
        )
      }
      textTranslation={textTranslation}
    >
      <FlatList<NftCommonResponse>
        data={randomNftsFromCollection}
        numColumns={'auto-fill'}
        headerComponent={
          <>
            <CollectionDetails nftCollection={collection as NftCollectionV5Response} />
            <CollectiblesViewWrapper>
              <Text fontWeight={'bold'} color={theme.v2.text.headingPrimary} fontSize="lg" lineHeight="h4">
                {textTranslation('offering.collectibles')}
              </Text>
              <TextLink
                dataTestId="collection-viewall-button"
                onClick={() => navigate(`${routes.offering.smartCollectibles.root}/${collectionId}/collectibles`)}
              >{`${textTranslation('global.viewAll')} (${formatAmountWithoutDecimals(statistics?.total).replace(
                /’/g,
                '.'
              )})`}</TextLink>
            </CollectiblesViewWrapper>
          </>
        }
        keyExtractor={(item) => item.id}
        renderItem={(item) => (
          <NftHolder
            serialNumber={getFirstSixDigitsOfSerialNumber(item.serialNumber)}
            price={formatAmount(item.price, currency?.decimals, currency?.rate, currency?.currencyCode)}
            priceText={textTranslation('global.price')}
            imageUrl={item.thumbnailUrl}
            isFavouriteFeatureAvailable={false}
            buyText={textTranslation('offering.buyNow')}
            isAvailable={false}
            onClick={() =>
              navigate(`${routes.offering.smartCollectibles.root}/${collectionId}/collectibles/${item.id}`)
            }
            children={
              <>
                {item?.status !== NftCommonStatusEnum.AVAILABLE && (
                  <Ribbon
                    status={item?.status as NftCommonStatusEnum}
                    fontSize={'xsm'}
                    lineHeight={'extraSmall'}
                    padding={'0.5rem 0'}
                  />
                )}
              </>
            }
          />
        )}
        footerComponent={
          <CounterButtonWrapper>
            <ItemsCounter
              displayedItems={numberOfDisplayedNfts}
              totalItems={statistics?.total || 0}
              ofText={textTranslation('portfolio.of')}
              itemsText={textTranslation('portfolio.assets')}
              displayedItemsText={formatAmountWithoutDecimals(
                numberOfDisplayedNfts > statistics?.total ? statistics?.total : numberOfDisplayedNfts
              ).replace(/’/g, '.')}
              totalItemsText={formatAmountWithoutDecimals(statistics?.total || 0).replace(/’/g, '.')}
            />
            <Button
              text={`${textTranslation('global.viewAll')} (${formatAmountWithoutDecimals(
                statistics?.total || 0
              ).replace(/’/g, '.')})`}
              fill
              onClick={() => navigate(`${routes.offering.smartCollectibles.root}/${collectionId}/collectibles`)}
              borderRadius={'large'}
            />
          </CounterButtonWrapper>
        }
      />
    </PageStateContainer>
  );
};
