import {
  Dispatch,
  FC,
  PropsWithChildren,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

type User = {
  id: string;
  userType: 'private' | 'corporate';
};

export type Step = {
  title: string;
  subtitle: string;
  children: ReactNode;
  nextStep?: () => void;
  prevStep?: () => void;
  buttonText: string;
  isAdminWizard?: boolean;
  dataAttributes?: string;
};

type Props = {
  steps?: Step[];
};

type AppWizardContext = {
  title: string;
  subtitle: string;
  totalSteps: number;
  currentStep: number;
  onBack: () => void;
  onNext: () => void;
  animationDirection: 'forward' | 'backward';
  buttonText?: string;
  dataAttributes?: string;
  children: ReactNode;
  isButtonLoading: boolean;
  isButtonDisabled: boolean;
  isButtonHidden: boolean;
  setIsButtonHidden: (isHiddenButton: boolean) => void;
  setIsButtonLoading: (isLoading: boolean) => void;
  setIsButtonDisabled: (isDisabled: boolean) => void;
  setWizardSteps: Dispatch<SetStateAction<Step[]>>;
  changeNextStepOnClick: (onNext: () => void) => void;
  changePrevStepOnClick: (onPrev: () => void) => void;
  setIsNextStepDisabled: (isEnabled: boolean) => void;
  activeStep: number;
  setActiveStep: (step: number) => void;
  setIsBackStepDisabled: (isDisabled: boolean) => void;
  isBackStepDisabled: boolean;
  isNextStepDisabled: boolean;
  setUser: (user: User) => void;
  selectedUser: User | null;
  navigateForward: () => void;
};

const AppWizardContext = createContext({} as AppWizardContext);

const useAppWizard = () => useContext(AppWizardContext);

const AppWizardProvider: FC<PropsWithChildren<Props>> = ({ children, steps = [] }) => {
  const [activeStep, setActiveStep] = useState<number>(1);
  const [isButtonHidden, setIsButtonHidden] = useState<boolean>(false);
  const [animationDirection, setAnimationDirection] = useState<'forward' | 'backward'>('forward');
  const [wizardSteps, setWizardSteps] = useState<Step[]>(steps);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const [isNextStepDisabled, setIsNextStepDisabled] = useState<boolean>(false);
  const [isBackStepDisabled, setIsBackStepDisabled] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const step = wizardSteps[activeStep - 1] || {};

  const nextStepHandler = useCallback(() => {
    setAnimationDirection('forward');
    step.nextStep?.();
    if (activeStep !== wizardSteps.length && !isNextStepDisabled) {
      setActiveStep(activeStep + 1);
    }
  }, [activeStep, isNextStepDisabled, step.nextStep, wizardSteps.length]);

  const navigateForward = useCallback(() => {
    setAnimationDirection('forward');
    setActiveStep(activeStep + 1);
  }, []);

  const backStepHandler = useCallback(() => {
    setAnimationDirection('backward');
    step.prevStep?.();

    if (activeStep > 1 && !isBackStepDisabled) {
      setActiveStep(activeStep - 1);
      setIsBackStepDisabled(false);
    }
  }, [activeStep, step.prevStep, isBackStepDisabled]);

  const changeNextStepOnClick = useCallback(
    (onNext: () => void) => {
      setAnimationDirection('forward');
      const currentStepObj = { ...wizardSteps[activeStep - 1] };
      currentStepObj.nextStep = onNext;

      const newWizardSteps = [...wizardSteps];
      newWizardSteps[activeStep - 1] = currentStepObj;
      setWizardSteps(newWizardSteps);
    },
    [activeStep, wizardSteps]
  );

  const setUser = useCallback((user: User) => {
    setSelectedUser(user);
  }, []);

  const changePrevStepOnClick = useCallback(
    (onPrev: () => void) => {
      setAnimationDirection('backward');
      const currentStepObj = { ...wizardSteps[activeStep - 1] };
      currentStepObj.prevStep = onPrev;

      const newWizardSteps = [...wizardSteps];
      newWizardSteps[activeStep - 1] = currentStepObj;
      setWizardSteps(newWizardSteps);
    },
    [activeStep, wizardSteps]
  );

  useEffect(() => {
    if (step.isAdminWizard) {
      setIsButtonDisabled(true);
    }
    return () => {
      setIsButtonDisabled(false);
      setIsButtonLoading(false);
    };
  }, [activeStep]);

  useEffect(() => {
    if (step.isAdminWizard) setWizardSteps(steps);
  }, [steps]);

  return (
    <AppWizardContext.Provider
      value={{
        title: step.title,
        dataAttributes: step.dataAttributes,
        subtitle: step.subtitle,
        totalSteps: wizardSteps.length,
        currentStep: activeStep,
        animationDirection,
        onBack: backStepHandler,
        onNext: nextStepHandler,
        buttonText: step.buttonText,
        children: step.children,
        isButtonLoading,
        isButtonDisabled,
        isButtonHidden,
        setIsButtonLoading,
        setIsButtonDisabled,
        setWizardSteps,
        changeNextStepOnClick,
        changePrevStepOnClick,
        setIsNextStepDisabled,
        setIsButtonHidden,
        setActiveStep,
        activeStep,
        setIsBackStepDisabled,
        isBackStepDisabled,
        isNextStepDisabled,
        setUser,
        selectedUser,
        navigateForward,
      }}
    >
      {children}
    </AppWizardContext.Provider>
  );
};

export { AppWizardContext, AppWizardProvider, useAppWizard };
