import styled from 'styled-components';

export const StyledStep = styled.div<{
  $activeStep: boolean;
}>`
  border-radius: 100%;
  width: ${({ $activeStep }) => ($activeStep ? '0.75rem' : '0.5rem')};
  height: ${({ $activeStep }) => ($activeStep ? '0.75rem' : '0.5rem')};
  transition: all 0.2s ease-in;
  background-color: ${({ theme, $activeStep }) => ($activeStep ? theme.v2.icon.primary : theme.v2.surface.tertiary)};
`;

export const StyledStepperContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0.75rem;
`;

export const StyledStepWrapper = styled.div<{ $hasCursorPointer: boolean }>`
  margin: 0 0.25rem;
  cursor: ${({ $hasCursorPointer }) => $hasCursorPointer && 'pointer'};
`;
