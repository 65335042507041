import {
  CorporateUserFullResponse,
  PrivateUserFullResponse,
  UpdateCorporateUserRequest,
  UpdatePrivateUserRequestV4,
} from '@metaswiss/api';
import { getDirtyValues, ToastType, useToastQueueContext } from '@metaswiss/lib';
import {
  Button,
  ErrorDialog,
  Flex,
  FormInput,
  HeaderCard,
  PageStateContainer,
  Text,
  TextLink,
} from '@metaswiss/ui-kit';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { FieldError, Path, useFormContext } from 'react-hook-form';
import { useTheme } from 'styled-components';

import { api } from '../../../../api/msApi.ts';
import ControlledForm from '../../../../components/controlled-form/ControlledForm.tsx';
import { ApiResource } from '../../../../enums/resource.enum.ts';
import { UserRole } from '../../../../enums/userRole.ts';
import {
  AppState,
  ShellNavigationState,
  useAppState,
  useShellNavigationState,
} from '../../../../global-state/zustand.ts';
import { useTextTranslation } from '../../../../hooks/use-text-translation/useTextTranslation.ts';
import { defaultUser } from '../../../../shared/helpers/defaultUser.ts';
import { getQueryKey } from '../../../../shared/helpers/getQueryKey.helper.ts';

import { ButtonWrapper, FormContainer, HeaderWrapper } from './bankInformation.styles.ts';
import {
  createBankInformationSchema,
  EditBankInformationFormData,
  editBankInformationSchemaNames,
} from './bankInformationSchema.ts';

type BankInformationViewProps = {
  userRole: UserRole;
  userId: string;
};

export const BankInformationView = ({ userRole, userId }: BankInformationViewProps) => {
  const queryClient = useQueryClient();
  const theme = useTheme();
  const { textTranslation } = useTextTranslation();
  const { enqueue } = useToastQueueContext();
  const { setIsChangeRouteAvailable } = useShellNavigationState((state: ShellNavigationState) => state);

  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const {
    control,
    reset,
    handleSubmit,
    formState: { isDirty, errors, isValid, dirtyFields },
  } = useFormContext<EditBankInformationFormData>();

  const { mutate } = useMutation<
    PrivateUserFullResponse | CorporateUserFullResponse,
    unknown,
    EditBankInformationFormData
  >({
    mutationFn: (data: EditBankInformationFormData) => {
      return userRole === UserRole.PRIVATE
        ? api.users.updatePrivateDetails(userId, data as UpdatePrivateUserRequestV4)
        : api.users.updateCorporateDetails(userId, data as UpdateCorporateUserRequest);
    },
    onSuccess: async () => {
      queryClient.invalidateQueries(
        userRole === UserRole.PRIVATE
          ? getQueryKey(ApiResource.PRIVATE_USER, userId)
          : getQueryKey(ApiResource.CORPORATE_USER, userId)
      );
    },
    onError: () => {
      enqueue(ToastType.ERROR, textTranslation('error.default'), textTranslation('error.error'));
    },
  });

  const onCancelHandler = async () => {
    setIsEditMode(false);
    setIsModalOpen(false);
    setIsChangeRouteAvailable(true);
    reset();
  };

  const onSubmit = (data: EditBankInformationFormData) => {
    setIsEditMode(false);
    const updatedData = getDirtyValues(dirtyFields, data);
    mutate(updatedData as EditBankInformationFormData);
    reset(data);
  };

  const onClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {isEditMode && (
        <ErrorDialog
          primaryText={textTranslation('account.discardChanges')}
          secondaryText={textTranslation('account.areYouSureDiscard')}
          isOpen={isModalOpen}
          close={onClose}
          primaryButtonText={textTranslation('account.discard')}
          onPrimaryButtonClick={onCancelHandler}
          secondaryButtonText={textTranslation('account.cancel')}
          onSecondaryButtonClick={onClose}
        />
      )}
      <HeaderCard
        header={
          <HeaderWrapper>
            <Text fontWeight={'bold'} color={theme.v2.text.headingPrimary}>
              {textTranslation('account.bankInformation')}
            </Text>
            <TextLink
              textSize="base"
              color={isEditMode ? 'error' : 'primary'}
              padding={'0.25rem'}
              onClick={() =>
                !isEditMode ? setIsEditMode(true) : isDirty ? setIsModalOpen(true) : setIsEditMode(false)
              }
            >
              {isEditMode ? textTranslation('account.discard') : textTranslation('account.edit')}
            </TextLink>
          </HeaderWrapper>
        }
        removeBackground={false}
      >
        <FormContainer>
          <Flex gap={'1rem'}>
            <FormInput<EditBankInformationFormData>
              name={editBankInformationSchemaNames.bankName as Path<EditBankInformationFormData>}
              control={control}
              error={errors[editBankInformationSchemaNames.bankName] as FieldError}
              label={textTranslation('account.bankName')}
              fill
              isLocked={!isEditMode}
              isOptional={true}
              dataAttributes={{ dataTestid: 'bankinfo-bankname' }}
            />
            <FormInput<EditBankInformationFormData>
              name={editBankInformationSchemaNames.bankAddress as Path<EditBankInformationFormData>}
              error={errors[editBankInformationSchemaNames.bankAddress] as FieldError}
              control={control}
              label={textTranslation('account.bankAddress')}
              fill
              isLocked={!isEditMode}
              isOptional={true}
              dataAttributes={{ dataTestid: 'bankinfo-bankaddress' }}
            />
          </Flex>
          <Flex gap={'1rem'}>
            <FormInput<EditBankInformationFormData>
              name={editBankInformationSchemaNames.iban as Path<EditBankInformationFormData>}
              error={errors[editBankInformationSchemaNames.iban] as FieldError}
              control={control}
              label={textTranslation('account.iban')}
              fill
              isLocked={!isEditMode}
              isOptional={true}
              dataAttributes={{ dataTestid: 'bankinfo-iban' }}
            />
            <FormInput<EditBankInformationFormData>
              name={editBankInformationSchemaNames.depotNumber as Path<EditBankInformationFormData>}
              error={errors[editBankInformationSchemaNames.depotNumber] as FieldError}
              control={control}
              label={textTranslation('account.depoNumber')}
              fill
              isLocked={!isEditMode}
              isOptional={true}
              dataAttributes={{ dataTestid: 'bankinfo-depot' }}
            />
          </Flex>
          {isEditMode && (
            <ButtonWrapper>
              <Button
                text={textTranslation('account.saveChanges')}
                fill
                onClick={handleSubmit(onSubmit)}
                disabled={!isValid || !isDirty}
                dataAttributes={{ dataTestid: 'bankinfo-savechanges-button' }}
              />
            </ButtonWrapper>
          )}
        </FormContainer>
      </HeaderCard>
    </>
  );
};

export const BankInformation = () => {
  const { textTranslation } = useTextTranslation();
  const user = useAppState((state: AppState) => state.user) || defaultUser;

  const { data: privateUser, isLoading: isLoadingPrivateUser } = useQuery({
    queryKey: getQueryKey(ApiResource.PRIVATE_USER, user.id),
    queryFn: () => api.users.getPrivateUser(user.id),
    enabled: user.role === UserRole.PRIVATE,
  });

  const { data: corporateUser, isLoading: isLoadingCorporateUser } = useQuery({
    queryKey: getQueryKey(ApiResource.CORPORATE_USER, user.id),
    queryFn: () => api.users.getCorporateUser(user.id),
    enabled: user.role === UserRole.CORPORATE,
  });

  const userDetails = privateUser || corporateUser;

  return (
    <PageStateContainer
      showLoading={true}
      isLoading={
        (isLoadingPrivateUser && user.role === UserRole.PRIVATE) ||
        (isLoadingCorporateUser && user.role === UserRole.CORPORATE)
      }
      showEmptyState={false}
      showError={false}
      textTranslation={textTranslation}
      maxWidth="44.5rem"
    >
      <ControlledForm
        validationSchema={createBankInformationSchema}
        defaultValues={{
          bankName: userDetails?.bankName || '',
          bankAddress: userDetails?.bankAddress || '',
          iban: userDetails?.iban || '',
          depotNumber: userDetails?.depotNumber || '',
        }}
      >
        <BankInformationView userRole={user.role as UserRole} userId={userDetails?.id || ''} />
      </ControlledForm>
    </PageStateContainer>
  );
};
