import { FC } from 'react';
import { useTheme } from 'styled-components';

import { Button, Text } from '../../../components';

import {
  DetailsContainer,
  DetailsInformationWrapper,
  ImageDownloadContainer,
  ImageHolder,
  NftDetailsContainer,
  RealProductDescriptionWrapper,
  TopDetailsWrapper,
} from './nftDetails.styles';
import { NftDetailsProps } from './types';

export const NftDetails: FC<NftDetailsProps> = ({
  nftImageUrl,
  nftId,
  chain,
  creator,
  productImageUrl,
  productHeading,
  productDescription,
  textTranslation,
  detailsTranslationKey,
  nftIdTranslationKey,
  chainTranslationKey,
  creatorTranslationKey,
  productDetailsTranslationKey,
  withDownloadButton,
  downloadTextTranslationKey,
  downloadButtonTextTranslationKey,
  downloadImgBtn,
}) => {
  const theme = useTheme();
  const { handleDownloadImage, loadingDownloadImage } = downloadImgBtn || {};
  return (
    <NftDetailsContainer>
      <TopDetailsWrapper>
        <Text fontWeight={'bold'} color={theme.v2.text.headingPrimary}>
          {textTranslation(detailsTranslationKey)}
        </Text>
        <DetailsContainer>
          <ImageHolder src={nftImageUrl} />
          <DetailsInformationWrapper>
            <Text color={theme.v2.text.disabled}>{textTranslation(nftIdTranslationKey)}</Text>
            <Text color={theme.v2.text.disabled}>{textTranslation(chainTranslationKey)}</Text>
            <Text color={theme.v2.text.disabled}>{textTranslation(creatorTranslationKey)}</Text>
          </DetailsInformationWrapper>
          <DetailsInformationWrapper>
            <Text
              fontWeight={'semi'}
              color={theme.v2.text.headingPrimary}
              dataAttributes={{ dataTestid: 'collectible-id' }}
            >
              {nftId}
            </Text>
            <Text
              fontWeight={'semi'}
              color={theme.v2.text.headingPrimary}
              dataAttributes={{ dataTestid: 'collectible-chain' }}
            >
              {chain}
            </Text>
            <Text
              fontWeight={'semi'}
              color={theme.v2.text.headingPrimary}
              dataAttributes={{ dataTestid: 'collectible-creator' }}
            >
              {creator}
            </Text>
          </DetailsInformationWrapper>
        </DetailsContainer>
        {withDownloadButton && (
          <ImageDownloadContainer>
            <Text color={theme.v2.text.disabled}>{textTranslation(downloadTextTranslationKey)}</Text>
            <Button
              size={'medium'}
              loading={loadingDownloadImage}
              text={textTranslation(downloadButtonTextTranslationKey)}
              onClick={handleDownloadImage}
            />
          </ImageDownloadContainer>
        )}
      </TopDetailsWrapper>
      <Text fontWeight={'bold'} color={theme.v2.text.headingPrimary}>
        {textTranslation(productDetailsTranslationKey)}
      </Text>
      <DetailsContainer>
        <ImageHolder src={productImageUrl} $withBorder={true} />
        <DetailsInformationWrapper>
          <Text fontWeight={'semi'} color={theme.v2.text.headingPrimary}>
            {productHeading}
          </Text>
          <RealProductDescriptionWrapper>
            <Text color={theme.v2.text.disabled}>{productDescription}</Text>
          </RealProductDescriptionWrapper>
        </DetailsInformationWrapper>
      </DetailsContainer>
    </NftDetailsContainer>
  );
};
