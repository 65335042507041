import { BaseAvatar } from './BaseAvatar';

type Props = {
  onClick?: () => void;
  imagePath?: string;
  size?: 'small' | 'regular' | 'medium' | 'large';
  radius?: string;
  isCursorPointer?: boolean;
  border?: string;
  dataAttribute?: string;
};

export const Avatar: React.FC<Props> = ({
  onClick,
  imagePath,
  border,
  size = 'medium',
  radius = '50%',
  isCursorPointer = true,
  dataAttribute,
}) => {
  return (
    <BaseAvatar
      dataAttribute={dataAttribute}
      onClick={onClick}
      imagePath={imagePath}
      size={size}
      radius={radius}
      isCursorPointer={isCursorPointer}
      border={border}
    />
  );
};
