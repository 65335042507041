import {
  Button,
  Heading,
  ItemProps,
  ItemWithCustomIconProps,
  Popup,
  RemSize,
  Text,
  ThemedIcon,
} from '@metaswiss/ui-kit';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';

import { useTextTranslation } from '../../../hooks/use-text-translation/useTextTranslation';

import {
  ButtonsContainer,
  ItemsContainer,
  ItemsSelectionContainer,
  ItemWrapper,
  StyledElementContainer,
  StyledElementWrap,
  StyledIcon,
  TitleWrapper,
} from './styles';

export type PopupProps = {
  onSelect: (item: ItemProps | ItemWithCustomIconProps) => void;
  itemsList: ItemProps[] | ItemWithCustomIconProps[];
  isOpen: boolean;
  onOutsideClick: () => void;
  height: RemSize | 'fit-content';
  title: string;
  selectedItem: ItemProps | ItemWithCustomIconProps;
  dataAttribute?: {
    primaryButton?: string;
    secondaryButton?: string;
  };
};

export const SelectPopup: FC<PopupProps> = ({
  selectedItem,
  onSelect,
  itemsList,
  onOutsideClick,
  isOpen,
  height,
  title,
  dataAttribute,
}) => {
  const theme = useTheme();
  const { textTranslation } = useTextTranslation();
  const [activeItem, setActiveItem] = useState<ItemProps | ItemWithCustomIconProps | undefined>(selectedItem);

  const isItemSelected = useMemo(
    () => itemsList.some((item) => item.value === activeItem?.value),
    [itemsList, activeItem]
  );

  useEffect(() => {
    setActiveItem(selectedItem as ItemProps | ItemWithCustomIconProps);
  }, [selectedItem]);

  const handleSelectItem = () => {
    onOutsideClick();
    onSelect(activeItem as ItemProps | ItemWithCustomIconProps);
  };

  const onCancelHandler = () => {
    onOutsideClick();
    setActiveItem(selectedItem);
  };

  const convertStringToDataAttribute = useMemo(() => {
    return (dataAttribute: string): string => {
      switch (dataAttribute) {
        case 'en':
          return 'english';
        case 'de':
          return 'german';
        default:
          return '';
      }
    };
  }, []);

  return (
    <Popup isActive={isOpen} onOutsideClick={onCancelHandler} height={height}>
      <ItemsSelectionContainer>
        <TitleWrapper>
          <Heading headingType={'h4'} fontWeight={'bold'} color={theme.v2.text.headingPrimary}>
            {title}
          </Heading>
        </TitleWrapper>
        <ItemsContainer>
          {itemsList.map((item) => (
            <ItemWrapper
              key={item.value}
              data-testid={convertStringToDataAttribute(item.value)}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                setActiveItem(item);
                event.stopPropagation();
              }}
              $isSelected={item.value === activeItem?.value}
            >
              <StyledElementContainer>
                <StyledElementWrap>
                  {item?.iconSrc && typeof item?.iconSrc === 'string' && <StyledIcon src={item.iconSrc} />}
                  {item?.iconSrc && typeof item?.iconSrc !== 'string' && (
                    <ThemedIcon
                      icon={item.iconSrc}
                      customStrokeColor={
                        item.value === activeItem?.value ? theme.v2.icon.primary : theme.v2.text.bodyPrimary
                      }
                    />
                  )}
                  <Text>{item.label}</Text>
                </StyledElementWrap>
              </StyledElementContainer>
            </ItemWrapper>
          ))}
        </ItemsContainer>
        <ButtonsContainer>
          <Button
            variant="outlined"
            color="neutral"
            text={textTranslation('home.cancel')}
            fill
            type="button"
            onClick={onCancelHandler}
            dataAttributes={{ dataTestid: dataAttribute?.secondaryButton }}
          />
          <Button
            text={textTranslation('home.confirm')}
            fill
            type="submit"
            disabled={!isItemSelected}
            onClick={handleSelectItem}
            dataAttributes={{ dataTestid: dataAttribute?.primaryButton }}
          />
        </ButtonsContainer>
      </ItemsSelectionContainer>
    </Popup>
  );
};
