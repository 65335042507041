import { StyledStep, StyledStepperContainer, StyledStepWrapper } from './stepper.styles';

export interface StepperProps {
  currentStep: number;
  numberOfSteps: number;
  onClick?: (index: number) => void;
}

export const Stepper: React.FC<StepperProps> = ({ numberOfSteps, currentStep, onClick }) => {
  const handleStepClick = (index: number) => {
    if (onClick) {
      onClick(index);
    }
  };

  return (
    <StyledStepperContainer>
      {Array.from({ length: numberOfSteps }, (_, stepIndex) => (
        <StyledStepWrapper key={stepIndex} onClick={() => handleStepClick(stepIndex)} $hasCursorPointer={!!onClick}>
          <StyledStep $activeStep={currentStep === stepIndex} />
        </StyledStepWrapper>
      ))}
    </StyledStepperContainer>
  );
};
