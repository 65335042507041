import { useTheme } from 'styled-components';

import { AvatarIcon } from '../../../iconography/AvatarIcon';

import { BadgeContainer } from './styles/badgeContainer';
import { Container } from './styles/container';
import { AvatarImage, ImageContainer } from './styles/imageContainer';

export type Props = {
  onClick?: () => void;
  imagePath?: string;
  badge?: JSX.Element;
  size?: 'small' | 'regular' | 'medium' | 'large';
  radius?: string;
  isCursorPointer: boolean;
  border?: string;
  dataAttribute?: string;
};

export const BaseAvatar: React.FC<Props> = ({
  onClick,
  imagePath,
  badge,
  border,
  size = 'medium',
  radius = '50%',
  isCursorPointer,
  dataAttribute,
}) => {
  const theme = useTheme();

  return (
    <Container
      $size={theme.avatar.size[size]}
      $radius={radius}
      $isCursorPointer={isCursorPointer}
      data-testid={dataAttribute}
    >
      <ImageContainer
        $size={theme.avatar.size[size]}
        $radius={radius}
        $border={border ?? 'none'}
        $active={!!onClick}
        $hasAvatar={!!imagePath}
        onClick={onClick}
      >
        {!imagePath ? <AvatarIcon color={theme.colors.neutrals.hue200} /> : <AvatarImage src={imagePath} />}
      </ImageContainer>
      {!!badge && <BadgeContainer>{badge}</BadgeContainer>}
    </Container>
  );
};
