import { formatAmount, getFirstSixDigitsOfSerialNumber } from '@metaswiss/lib';
import { Button, PageStateContainer, Text } from '@metaswiss/ui-kit';
import { NftDescriptionCard } from '@metaswiss/ui-kit/src/components/molecules/cards/nft-description-card/NftDescriptionCard';
import { NftDetailsCard } from '@metaswiss/ui-kit/src/components/molecules/cards/nft-details-card/NftDetailsCard';
import { ProductDetailsCard } from '@metaswiss/ui-kit/src/components/molecules/cards/product-details-card/ProductDetailsCard';
import { NftHolder } from '@metaswiss/ui-kit/src/components/molecules/nft-holder/NftHolder';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { api } from '../../../../../api/msApi';
import { OfferingPopup } from '../../../../../components/offering-popup/OfferingPopup';
import { Ribbon } from '../../../../../components/ribbon/Ribbon';
import { ProductType } from '../../../../../enums/productType.enum';
import { ApiResource } from '../../../../../enums/resource.enum';
import { UserStatus } from '../../../../../enums/userStatus.enum';
import { AppState, useAppState, useHeaderOptions, useShellNavigationState } from '../../../../../global-state/zustand';
import { useTextTranslation } from '../../../../../hooks/use-text-translation/useTextTranslation';
import { routes } from '../../../../../router/routes';
import { getFormattedLanguageName } from '../../../../../shared/helpers/getFormattedLanguageName.helper';
import { getNftProductDescription } from '../../../../../shared/helpers/getNftProductDescription.helper';
import { getQueryKey } from '../../../../../shared/helpers/getQueryKey.helper';
import { NftCommonResponse, NftCommonStatusEnum } from '../nft.response';

import {
  BottomContainer,
  ButtonWrapper,
  FirstWrapper,
  Image,
  ImageContainer,
  ItemsWrapper,
  RightWrapper,
  SecondWrapper,
  UpperContainer,
  WrapperDetails,
} from './singleView.styles';

export const SingleView = () => {
  const theme = useTheme();
  const { textTranslation, currentLanguage } = useTextTranslation();
  const { nftId: id } = useParams();
  const { setHeaderTitle } = useHeaderOptions();
  const { removeTabs } = useShellNavigationState();

  const currency = useAppState((state: AppState) => state.currency);
  const user = useAppState((state: AppState) => state.user);

  const navigate = useNavigate();

  const [isOpenOfferingPopup, setIsOpenOfferingPopup] = useState<boolean>(false);

  useEffect(() => {
    removeTabs();
    setHeaderTitle(textTranslation('offering.collectibleDetails'));
  }, [removeTabs, setHeaderTitle, textTranslation]);

  const {
    data: nft,
    isLoading: isLoadingNft,
    isError: isErrorNft,
    refetch,
  } = useQuery({
    queryKey: getQueryKey(ApiResource.NFT, id),
    queryFn: () => api.nft.getNftById(id ?? ''),
    enabled: !!id,
  });

  const { data: productAsset } = useQuery({
    queryKey: getQueryKey(ApiResource.NFT_PRODUCT_LOGO, nft?.nftCollection.product?.id),
    queryFn: () => {
      return api.nftProduct.getNftProductAssetById(nft?.nftCollection.product?.id || '');
    },
    enabled: !!nft?.nftCollection.product?.id,
  });

  const { data: productImageUrl } = useQuery({
    queryKey: getQueryKey(ApiResource.NFT_COLLECTION_LOGO, productAsset?.id),
    queryFn: () => {
      return api.assets.getS3SignedAssignedUrl({ assetId: productAsset?.id || '' });
    },
    enabled: !!productAsset?.id,
  });

  const {
    data: nfts,
    isLoading: isLoadingNfts,
    isError: isErrorNfts,
    refetch: refetchNfts,
  } = useQuery({
    queryKey: getQueryKey(ApiResource.NFT, nft?.nftCollection?.id),
    queryFn: () => api.nft.getNftByCollectionId(nft?.nftCollection?.id || ''),
    enabled: !!nft?.nftCollection?.id,
  });

  const randomNftsFromCollection = useMemo(() => {
    const filteredItems = (nfts?.items as unknown as NftCommonResponse[])?.filter((nft) => nft.id !== id);
    return filteredItems?.slice(0, 6) as unknown as NftCommonResponse[];
  }, [nfts?.items, id]);

  const isLoading = useMemo(() => isLoadingNfts || isLoadingNft, [isLoadingNfts, isLoadingNft]);
  const isError = useMemo(() => isErrorNfts || isErrorNft, [isErrorNfts, isErrorNft]);

  const nftDescription = useMemo(() => {
    const formattedLanguage = getFormattedLanguageName(currentLanguage);
    return formattedLanguage === 'english' ? nft?.nftCollection.description : nft?.nftCollection.germanDescription;
  }, [currentLanguage, nft]);

  const onTryAgain = async () => {
    await Promise.all([refetch(), refetchNfts()]);
  };

  const handleInvestButtonClick = () => {
    if (user?.isActive && user?.status !== UserStatus.SUSPENDED) {
      navigate(`${routes.payment.root}`, { state: { item: nft, productType: ProductType.NFT } });
    } else {
      setIsOpenOfferingPopup(true);
    }
  };

  return (
    <PageStateContainer
      showLoading={true}
      showError={true}
      isLoading={isLoading}
      showEmptyState={false}
      isError={isError}
      onTryAgain={onTryAgain}
      textTranslation={textTranslation}
    >
      <Text fontSize={'lg'} lineHeight={'h4'} fontWeight={'bold'} color={theme.v2.text.headingPrimary}>
        {textTranslation('offering.collectibleDetails')}
      </Text>
      <OfferingPopup user={user} isOpen={isOpenOfferingPopup} setIsOpen={setIsOpenOfferingPopup} />
      <UpperContainer>
        <FirstWrapper>
          <ImageContainer>
            <Image src={nft?.imageUrl} />
            {nft?.status && nft?.status !== NftCommonStatusEnum.AVAILABLE && (
              <Ribbon
                status={nft.status as NftCommonStatusEnum}
                fontSize={'lg'}
                lineHeight={'h4'}
                translateX={'0%'}
                translateY={'90%'}
              />
            )}
          </ImageContainer>
          <RightWrapper>
            <NftDescriptionCard
              collectionName={nft?.nftCollection?.name}
              serialNumber={nft?.code ? getFirstSixDigitsOfSerialNumber(nft?.code) : ''}
              description={nftDescription}
              price={formatAmount(
                nft?.nftCollection?.price ?? 0,
                currency?.decimals,
                currency?.rate,
                currency?.currencyCode
              )}
              tooltip={textTranslation('offering.tooltipCollectible')}
            />
            {nft?.status === 'available' && (
              <ButtonWrapper>
                <Button
                  fill
                  onClick={handleInvestButtonClick}
                  text={textTranslation('offering.buyNow')}
                  dataAttributes={{ dataTestid: 'collectible-buy-button' }}
                />
              </ButtonWrapper>
            )}
          </RightWrapper>
        </FirstWrapper>
        <SecondWrapper>
          <WrapperDetails>
            <NftDetailsCard
              detailsTranslation={textTranslation('global.details')}
              nftIdTranslation={textTranslation('global.collectibleId')}
              chainTranslation={textTranslation('global.chain')}
              creatorTranslation={textTranslation('global.creator')}
              nftId={nft?.code.split('-')[1]}
              chain={nft?.nftCollection?.name}
              creator={nft?.nftCollection?.issuer?.name}
            />
          </WrapperDetails>
          <ProductDetailsCard
            imageUrl={productImageUrl?.url}
            productTranslation={textTranslation('global.productDetails')}
            name={nft?.nftCollection?.product?.title}
            description={getNftProductDescription(nft?.nftCollection?.product?.description?.content)}
          />
        </SecondWrapper>
      </UpperContainer>
      <BottomContainer>
        <Text fontWeight={'bold'}>{textTranslation('offering.moreFrom')}</Text>
        <ItemsWrapper>
          {randomNftsFromCollection?.map((item, index) => {
            if (item?.id === nft?.id) {
              return null;
            }
            return (
              <NftHolder
                key={index}
                serialNumber={item?.serialNumber ? getFirstSixDigitsOfSerialNumber(item?.serialNumber) : ''}
                price={formatAmount(item.price, currency?.decimals, currency?.rate, currency?.currencyCode)}
                priceText={textTranslation('global.price')}
                imageUrl={item.thumbnailUrl}
                isFavouriteFeatureAvailable={false}
                buyText={textTranslation('offering.buyNow')}
                isAvailable={false}
                onClick={() =>
                  navigate(
                    generatePath(routes.offering.smartCollectibles.singleView, {
                      nftId: item.id,
                      collectionId: nft?.nftCollection.id || '',
                    })
                  )
                }
                children={
                  <>
                    {item?.status !== NftCommonStatusEnum.AVAILABLE && (
                      <Ribbon
                        status={item?.status as NftCommonStatusEnum}
                        fontSize={'sm'}
                        lineHeight={'medium'}
                        padding={'0.5rem 0'}
                      />
                    )}
                  </>
                }
              />
            );
          })}
        </ItemsWrapper>
      </BottomContainer>
    </PageStateContainer>
  );
};
