import styled from 'styled-components';

import { TextTemplate } from '../../../shared/typography/TextTemplate.styles';
import { ColorPalette, RemSize } from '../../../theme/theme';

export const ComponentContainer = styled.div`
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
`;

export const BreadcrumbsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: nowrap;
  white-space: nowrap;
  width: fit-content;
`;

export const BreadcrumbsItem = styled.div`
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

export const BreadcrumbLabel = styled.button<{ $palette: ColorPalette; onClick: () => void }>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &:active {
    ${TextTemplate} {
      color: ${({ theme }) => theme.v2.text.bodyPrimary};
    }
  }
`;

export const IconContainer = styled.div<{ $size: RemSize }>`
  height: ${({ $size }) => $size};
  width: ${({ $size }) => $size};
`;
