import { Text } from '@metaswiss/ui-kit';
import { FC } from 'react';
import { useTheme } from 'styled-components';

import { CardWrapper, Logo, LogoContainer, TextWrapper } from './resourceWithLogo.styles';

type Props = {
  title: string;
  subTitle: string;
  addressTitle: string;
  logoTitle: string;
  issuerLogoUrl: string;
  dataAttributes?: {
    picture: string;
    name: string;
    address: string;
  };
};
export const ResourceWithLogo: FC<Props> = ({
  title,
  subTitle,
  logoTitle,
  addressTitle,
  issuerLogoUrl,
  dataAttributes,
}) => {
  const theme = useTheme();

  return (
    <CardWrapper>
      <LogoContainer data-testid={dataAttributes?.picture}>
        <Logo src={issuerLogoUrl} alt={logoTitle} />
      </LogoContainer>
      <TextWrapper>
        <Text fontSize={'sm'} lineHeight={'medium'} color={theme.v2.text.disabled}>
          {subTitle}
        </Text>
        <Text
          fontWeight={'bold'}
          color={theme.v2.text.headingPrimary}
          dataAttributes={{ dataTestid: dataAttributes?.name }}
        >
          {title}
        </Text>
        <Text
          fontSize={'sm'}
          lineHeight={'medium'}
          fontWeight={'semi'}
          color={theme.v2.text.headingPrimary}
          dataAttributes={{ dataTestid: dataAttributes?.address }}
        >
          {addressTitle}
        </Text>
      </TextWrapper>
    </CardWrapper>
  );
};
