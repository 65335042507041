import { FC } from 'react';
import { useTheme } from 'styled-components';

import { ThemedIcon } from '../../../../../../components';
import { IconProps } from '../../../../../../iconography';
import { SliderButton } from '../../infiniteCarousel.styles';

type SlideButtonProps = {
  onClick: () => void;
  icon: FC<IconProps>;
};

export const SlideButton = ({ onClick, icon }: SlideButtonProps) => {
  const theme = useTheme();

  return (
    <SliderButton onClick={onClick}>
      <ThemedIcon icon={icon} customStrokeColor={theme.v2.icon.default} />
    </SliderButton>
  );
};
