import {
  CheckContainedIcon,
  ItemProps,
  ItemWithCustomIconProps,
  RemSize,
  Text,
  ThemedIcon,
  XcircleContainedIcon,
} from '@metaswiss/ui-kit';
import { ArrowRightIcon } from '@metaswiss/ui-kit/src/iconography/ArrowRightIcon.tsx';
import { FC, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';

import { DocumentStatus } from '../../enums/documentStatus.enum';

import { SelectPopup } from './popup/Popup';
import {
  ArrowIconWrapper,
  Container,
  DocumentStatusWrapper,
  FooterPopupWrapper,
  FooterSelectionWrapper,
  FooterWrapper,
  HeadingWrapper,
  IconWrapper,
  Wrapper,
} from './styles';

type Props = {
  title: string;
  icon: React.ReactNode;
  selectedOptionText?: string;
  displaySelectedOptionLabel?: string;
  onChange?: (item: ItemProps | ItemWithCustomIconProps) => void;
  onClick?: () => void;
  type?: 'currency' | 'language';
  options?: ItemProps[] | ItemWithCustomIconProps[];
  selectedOption?: ItemProps | ItemWithCustomIconProps;
  documentStatus?: DocumentStatus;
  documentStatusTranslation?: string;
  width?: RemSize;
  isHoverActive?: boolean;
  disabled?: boolean;
  dataAttribute?: string;
};

export const SettingsCard: FC<Props> = ({
  title,
  icon,
  selectedOptionText,
  displaySelectedOptionLabel,
  onChange,
  onClick,
  type,
  options,
  documentStatus,
  width,
  documentStatusTranslation,
  selectedOption,
  isHoverActive = true,
  disabled = false,
  dataAttribute,
}) => {
  const theme = useTheme();
  const [isPopupActive, setIsPopupActive] = useState<boolean>(false);

  const handleCardClick = () => {
    setIsPopupActive(true);
    if (onClick) {
      onClick();
    }
  };

  const documentStatusColor = useMemo(() => {
    if (documentStatus) {
      switch (documentStatus) {
        case DocumentStatus.NOT_VERIFIED:
          return theme.v2.text.error;
        case DocumentStatus.SUBMITTED:
          return theme.v2.text.success;
        case DocumentStatus.VERIFIED:
          return theme.v2.text.information;
      }
    }
  }, [documentStatus]);

  const renderIcon = useMemo(() => {
    if (documentStatus) {
      switch (documentStatus) {
        case DocumentStatus.NOT_VERIFIED:
          return <ThemedIcon icon={XcircleContainedIcon} customStrokeColor={theme.v2.text.error} />;
        case DocumentStatus.SUBMITTED:
          return <ThemedIcon icon={CheckContainedIcon} customStrokeColor={theme.v2.text.success} />;
        case DocumentStatus.VERIFIED:
          return <ThemedIcon icon={CheckContainedIcon} customStrokeColor={theme.v2.text.information} />;
      }
    }
  }, [documentStatus]);

  return (
    <>
      {options && selectedOption && onChange && type && (
        <SelectPopup
          isOpen={isPopupActive}
          onOutsideClick={() => setIsPopupActive(false)}
          height={'fit-content'}
          title={title}
          itemsList={options}
          onSelect={onChange}
          selectedItem={selectedOption}
          dataAttribute={{
            primaryButton: 'settings-lang-confirm-button',
            secondaryButton: 'settings-lang-cancel-button',
          }}
        />
      )}
      <Container
        $width={width}
        $isHoverActive={isHoverActive}
        onClick={handleCardClick}
        $disabled={disabled}
        data-testid={dataAttribute}
      >
        <Wrapper>
          <HeadingWrapper>
            <IconWrapper>{icon}</IconWrapper>
            <Text fontSize={'lg'} lineHeight={'h4'} color={theme.v2.text.bodyPrimary}>
              {title}
            </Text>
          </HeadingWrapper>
          {selectedOptionText && displaySelectedOptionLabel ? (
            <FooterPopupWrapper>
              <FooterSelectionWrapper>
                <Text fontSize={'sm'} lineHeight={'medium'} color={theme.v2.text.disabled}>
                  {selectedOptionText}
                </Text>
                <Text fontWeight={'semi'} lineHeight={'base'} color={theme.v2.text.bodyPrimary}>
                  {displaySelectedOptionLabel}
                </Text>
              </FooterSelectionWrapper>
              {isHoverActive && (
                <ArrowIconWrapper>
                  <ThemedIcon icon={ArrowRightIcon} size={'large'} customStrokeColor={theme.v2.icon.default} />
                </ArrowIconWrapper>
              )}
            </FooterPopupWrapper>
          ) : (
            <FooterWrapper>
              <ArrowIconWrapper>
                <ThemedIcon icon={ArrowRightIcon} size={'large'} customStrokeColor={theme.v2.icon.default} />
              </ArrowIconWrapper>
            </FooterWrapper>
          )}
          {documentStatus && documentStatusTranslation && (
            <DocumentStatusWrapper>
              {renderIcon}
              <Text fontSize={'sm'} color={documentStatusColor} fontWeight={'semi'} lineHeight={'medium'}>
                {documentStatusTranslation}
              </Text>
            </DocumentStatusWrapper>
          )}
        </Wrapper>
      </Container>
    </>
  );
};
