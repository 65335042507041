import { DataAttributes, convertToDataAttributes } from '@metaswiss/lib';
import React, { FC, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import { useTheme } from 'styled-components';

import { IconSize, TypographyLineHeight, TypographySize, TypographyWeight } from '../../../theme';
import { Padding } from '../../../theme/theme';
import { Text } from '../text';

import { ButtonContent } from './styles/buttonContent';
import { SpinnerContainer } from './styles/spinnerContainer';
import { StyledButton } from './styles/styledButton';
import { TextContainer } from './styles/textContainer';

export type ButtonType = 'contained' | 'outlined' | 'text';

export type ButtonColor = 'primary' | 'secondary' | 'neutral' | 'success' | 'error' | 'warning';

export type ButtonSize = 'small' | 'medium' | 'large';

export type BorderRadius = 'small' | 'medium' | 'large';

type ButtonIconProps = {
  iconColor: string;
  iconSize: IconSize;
};

type Props = {
  text?: string;
  color?: ButtonColor;
  variant?: ButtonType;
  size?: ButtonSize;
  disabled?: boolean;
  loading?: boolean;
  fill?: boolean;
  renderStartIcon?: FC<ButtonIconProps>;
  renderEndIcon?: FC<ButtonIconProps>;
  type?: 'button' | 'submit' | 'reset';
  onClick?: () => void;
  borderRadius?: BorderRadius;
  padding?: Padding;
  textColor?: string;
  dataAttributes?: DataAttributes;
};

export const Button: FC<Props> = React.memo(
  ({
    text,
    variant = 'contained',
    size = 'large',
    color = 'primary',
    disabled = false,
    loading = false,
    fill = false,
    type = 'submit',
    renderStartIcon,
    renderEndIcon,
    onClick,
    borderRadius,
    padding,
    textColor = '',
    dataAttributes,
  }) => {
    const theme = useTheme();
    const [isHover, setIsHover] = useState(false);
    const [isActive, setIsActive] = useState(false);

    const contentColor = useGetContentColors({
      variant,
      disabled,
      color,
      isHover,
      isActive,
      textColor,
    });

    const { fontSize, fontWeight, iconSize, lineHeight } = getElementsSizes(size);

    const startIcon = renderStartIcon?.({
      iconColor: contentColor,
      iconSize,
    });
    const endIcon = renderEndIcon?.({
      iconColor: contentColor,
      iconSize,
    });

    return (
      <StyledButton
        $variant={variant}
        $size={size}
        disabled={disabled}
        $loading={loading}
        $color={color}
        $fill={fill}
        $borderRadius={borderRadius}
        type={type}
        onClick={onClick}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onMouseDown={() => setIsActive(true)}
        onMouseUp={() => setIsActive(false)}
        onTouchStart={() => setIsActive(true)}
        onTouchEnd={() => setIsActive(false)}
        {...convertToDataAttributes(dataAttributes)}
      >
        <ButtonContent $loading={loading} $size={size} $padding={padding}>
          {startIcon}
          {text && (
            <TextContainer $size={size}>
              <Text lineHeight={lineHeight} color={contentColor} fontSize={fontSize} fontWeight={fontWeight}>
                {text}
              </Text>
            </TextContainer>
          )}
          {endIcon}
        </ButtonContent>

        <SpinnerContainer $size={size} $loading={loading}>
          <ClipLoader size={theme.icon.sizes[iconSize]} loading={loading} color={contentColor} />
        </SpinnerContainer>
      </StyledButton>
    );
  }
);

const useGetContentColors = ({
  variant,
  disabled,
  color,
  isActive,
  isHover,
  textColor,
}: {
  variant: ButtonType;
  disabled: boolean;
  color: ButtonColor;
  isHover: boolean;
  isActive: boolean;
  textColor: string;
}) => {
  const theme = useTheme();
  switch (variant) {
    case 'contained':
      return disabled ? theme.v2.text.disabled : theme.v2.text.onAction;
    case 'outlined':
      if (disabled) return theme.v2.text.disabled;
      if (color === 'neutral') return theme.v2.text.headingPrimary;
      if (isActive) return theme.v2.colors[`${color}200`];
      return theme.v2.colors[`${color}100`];
    case 'text':
      if (disabled) return theme.v2.text.disabled;
      if (isActive) return theme.v2.colors[`${color}200`];
      if (color === 'neutral' && textColor) return textColor;
      if (isHover) return theme.v2.colors[`${color}50`];
      return theme.v2.colors[`${color}100`];
    default:
      throw new Error('Unsupported variant');
  }
};

function getElementsSizes(size: string) {
  let fontWeight: TypographyWeight;
  let fontSize: TypographySize;
  let iconSize: IconSize = 'small';
  let lineHeight: TypographyLineHeight;

  switch (size) {
    case 'small':
      fontSize = 'xsm';
      fontWeight = 'bold';
      lineHeight = 'extraSmall';
      break;
    case 'medium':
      fontSize = 'sm';
      fontWeight = 'bold';
      lineHeight = 'medium';
      break;
    case 'large':
      fontSize = 'base';
      fontWeight = 'bold';
      iconSize = 'medium';
      lineHeight = 'base';
      break;
    default:
      throw new Error('Unsupported size');
  }

  return { fontSize, fontWeight, iconSize, lineHeight } as const;
}
