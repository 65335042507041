import {
  FormCheckbox,
  FormInput,
  FormSelectionPopup,
  Globe02Icon,
  Text,
  TextLink,
  ThemedIcon,
} from '@metaswiss/ui-kit';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { FieldError, useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { api } from '../../../../../../../api/msApi';
import { setResendToken } from '../../../../../../../api/tokenHelpers';
import ControlledForm from '../../../../../../../components/controlled-form/ControlledForm';
import { PasswordValidationInputs } from '../../../../../../../components/passwords-validation-inputs/PasswordsValidationInputs';
import { useCountries } from '../../../../../../../hooks/use-countries/useCountries';
import { useTenantConfig } from '../../../../../../../hooks/use-tenant-config/useTenantConfig';
import { useTextTranslation } from '../../../../../../../hooks/use-text-translation/useTextTranslation';
import { MainContentContainer } from '../../../../../../../layouts/public-layout/styles/publicLayout.styles';
import { routes } from '../../../../../../../router/routes';
import { useAuthContext } from '../../../../../shared/auth-context/authContext';
import { useSetAuthConfig } from '../../../../../shared/auth-context/useSetAuthConfig';
import { RegisterHeader } from '../../../../shared/register-header/RegisterHeader';
import { RegistrationGridWrapper, TextLinkWrapper } from '../../../../shared/styles/registrationStyles';
import { ValidatedInputField } from '../../../../shared/validated-input-field/ValidatedInputField';

import { Divider, RegistrationFormContainer } from './quickRegistrationPrivateFlow.styles';
import {
  createQuickPrivateSchema,
  QuickPrivateRegisterFormData,
  quickRegistrationPrivateSchema,
} from './quickRegistrationPrivateSchema';

export const QuickRegistrationPrivateFlowView = () => {
  const { setUserDetails, setLoading, setIsValid, userRole, clearRegistrationContext, setUserEmailField } =
    useAuthContext();
  const { countriesAsDropdown } = useCountries();
  const { textTranslation, currentLanguage } = useTextTranslation();
  const tenantConfig = useTenantConfig();
  const [emailExist, setEmailExist] = useState<boolean>(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const [doPasswordsMatch, setDoPasswordsMatch] = useState<boolean>(true);

  const {
    formState: { errors, isValid },
    handleSubmit,
    control,
    setError,
    getValues,
    setValue,
  } = useFormContext<QuickPrivateRegisterFormData>();

  const { mutate, isLoading } = useMutation(
    (data: QuickPrivateRegisterFormData) => {
      return api.auth.quickRegistrationPrivateUser({
        email: data.email,
        password: data.password,
        passwordConfirmation: data.passwordConfirmation,
        firstName: data.firstName,
        lastName: data.lastName,
        termsAndConditions: data.termsAndConditions,
        preferredLanguage: currentLanguage,
        countryId: data.country.value,
      });
    },
    {
      onSuccess: (result) => {
        setResendToken(result.resendToken);
        setUserDetails({});
        navigate(routes.register.registerSuccess);
        clearRegistrationContext();
        setUserEmailField(getValues('email'));
      },
      onError: () => {
        navigate(routes.register.registerFailed);
      },
    }
  );

  const handleBackClick = () => {
    setValue(quickRegistrationPrivateSchema.termsAndConditions, false);
    navigate(`${routes.register.root}/${userRole}`);
  };

  useSetAuthConfig(
    {
      wrapperStyle: 'registrationWizard',
      containerStyle: 'registrationWizard',
      backButton: {
        onClick: () => {
          setUserDetails(getValues());
          handleBackClick();
        },
        text: 'button.back',
        dataAttributes: { dataTestid: ' back-button' },
      },
      nextButton: {
        text: 'registration.createAccount',
        dataAttributes: { dataTestid: ' createaccount-button' },
        onClick: () => handleSubmit((data) => mutate(data))(),
      },
    },
    [handleSubmit, mutate, navigate, userRole]
  );

  useEffect(() => {
    setLoading(isLoading);
    return () => {
      setLoading(false);
    };
  }, [isLoading, setLoading]);

  useEffect(() => {
    setIsValid(isValid && doPasswordsMatch && !emailExist);
    return () => {
      setIsValid(true);
    };
  }, [doPasswordsMatch, emailExist, isValid, setIsValid]);

  const checkEmailMutation = useMutation({
    mutationFn: () => {
      return api.users.checkEmailExists(getValues().email);
    },
    onError: () => {
      setError('email', { message: textTranslation('error.default') });
    },
    onSuccess: (result) => {
      setEmailExist(result);
      if (result) {
        setError('email', { message: textTranslation('error.emailExist') });
      }
    },
  });

  return (
    <>
      <RegisterHeader descriptionText={textTranslation('registration.personalInfoHeader')} />
      <RegistrationFormContainer>
        <Divider>
          <FormInput<QuickPrivateRegisterFormData>
            name={quickRegistrationPrivateSchema.firstName}
            control={control}
            label={textTranslation('registration.private.firstName')}
            error={errors[quickRegistrationPrivateSchema.firstName]}
            fill
            dataAttributes={{ dataTestid: 'first-name' }}
          />
          <FormInput<QuickPrivateRegisterFormData>
            name={quickRegistrationPrivateSchema.lastName}
            control={control}
            label={textTranslation('registration.private.lastName')}
            error={errors[quickRegistrationPrivateSchema.lastName]}
            fill
            dataAttributes={{ dataTestid: 'last-name' }}
          />
        </Divider>
        <ValidatedInputField<QuickPrivateRegisterFormData>
          name={quickRegistrationPrivateSchema.email}
          error={errors[quickRegistrationPrivateSchema.email]}
          control={control}
          fill
          label={textTranslation('registration.email')}
          checkValueExists={checkEmailMutation.mutate}
          isSpinnerIcon={checkEmailMutation?.isLoading}
        />
        <RegistrationGridWrapper>
          <PasswordValidationInputs
            control={control}
            passwordName={quickRegistrationPrivateSchema.password}
            passwordConfirmationName={quickRegistrationPrivateSchema.passwordConfirmation}
            passwordError={errors[quickRegistrationPrivateSchema.password]}
            passwordConfirmationError={errors[quickRegistrationPrivateSchema.passwordConfirmation]}
            passwordLabel={textTranslation('registration.password')}
            passwordConfirmationLabel={textTranslation('registration.passwordConfirmation')}
            doPasswordsMatch={doPasswordsMatch}
            setDoPasswordsMatch={setDoPasswordsMatch}
          />
        </RegistrationGridWrapper>
        <FormSelectionPopup<QuickPrivateRegisterFormData>
          name={quickRegistrationPrivateSchema.country}
          control={control}
          itemsList={countriesAsDropdown}
          label={textTranslation('registration.country')}
          title={textTranslation('registration.country')}
          searchText={textTranslation('registration.search')}
          renderEndIcon={() => <ThemedIcon icon={Globe02Icon} customStrokeColor={theme.v2.icon.primary} />}
          error={errors[quickRegistrationPrivateSchema.country] as FieldError}
          closeButtonText={textTranslation('cancel')}
          selectButtonText={textTranslation('select')}
          containerId={MainContentContainer.styledComponentId}
          dataAttributeValue={{ select: 'country-select' }}
        />
        <Divider $marginTop={'0.5rem'}>
          <FormCheckbox<QuickPrivateRegisterFormData>
            name={quickRegistrationPrivateSchema.termsAndConditions}
            control={control}
            error={errors[quickRegistrationPrivateSchema.termsAndConditions]}
            dataAttributes={{ dataTestid: 'terms-checkbox' }}
          />
          <TextLinkWrapper>
            <Text>{textTranslation('registration.acceptLabel')}</Text>
            <TextLink
              dataTestId="terms-conditions"
              textSize={'sm'}
              lineHeight={'medium'}
              onClick={() => window.open(tenantConfig[`termsAndConditionsLink_${currentLanguage}`], '_blank')}
            >
              {textTranslation('registration.termsAndConditions')}
            </TextLink>
          </TextLinkWrapper>
        </Divider>
      </RegistrationFormContainer>
    </>
  );
};

export const QuickRegistrationPrivateFlow = () => {
  const userDetails = useAuthContext((state) => state.userDetails);

  return (
    <ControlledForm validationSchema={createQuickPrivateSchema} defaultValues={userDetails}>
      <QuickRegistrationPrivateFlowView />
    </ControlledForm>
  );
};
