import { useMemo } from 'react';
import { useTheme } from 'styled-components';

import { ThemedIcon } from '../..';
import { AlertIcon } from '../../../iconography';
import { Text } from '../../atoms/text';
import { Avatar } from '../../molecules/avatar';

import {
  Ellipsis,
  Header,
  MenuItem,
  MenuOption,
  MenuWrapper,
  ProfileDropdownWrapper,
  ProfileInfoWrapper,
  StatusWrapper,
} from './profileDropdown.styles';
import { Props, UserStatus } from './types';

const getColorPalette = (userStatus: UserStatus) => {
  switch (userStatus) {
    case UserStatus.FULLY_REGISTERED:
      return 'success';
    case UserStatus.NOT_FULLY_REGISTERED:
      return 'error';
    case UserStatus.PENDING:
      return 'warning';
  }
};

export const ProfileDropdown = ({ username, avatarSrc, userStatus, userStatusTranslation, items, isOpen }: Props) => {
  const theme = useTheme();
  const statusColor = useMemo(() => getColorPalette(userStatus), [userStatus]);

  return (
    <ProfileDropdownWrapper $isOpen={isOpen}>
      <Header>
        <Avatar imagePath={avatarSrc} size={'small'} />
        <ProfileInfoWrapper>
          <Text fontSize={'base'} fontWeight={'bold'} color={theme.v2.text.headingPrimary}>
            {username}
          </Text>
          <StatusWrapper>
            <Ellipsis $color={statusColor} />
            <Text fontSize={'xsm'} lineHeight={'extraSmall'} color={theme.v2.text[statusColor]}>
              {userStatusTranslation}
            </Text>
          </StatusWrapper>
        </ProfileInfoWrapper>
      </Header>
      <MenuWrapper>
        {items.map((item, index) => (
          <MenuItem
            onClick={item.onClick}
            key={index}
            data-testid={`${item.name.toLocaleLowerCase().replace(' ', '')}-button`}
          >
            <MenuOption>
              <ThemedIcon
                icon={item.icon}
                customStrokeColor={item.logout ? theme.v2.text.error : theme.v2.text.bodySecondary}
              />
              <Text color={item.logout ? theme.v2.text.error : theme.v2.text.bodySecondary}>{item.name}</Text>
            </MenuOption>
            {item.hasError && <ThemedIcon icon={AlertIcon} customStrokeColor={theme.v2.icon.error} />}
          </MenuItem>
        ))}
      </MenuWrapper>
    </ProfileDropdownWrapper>
  );
};
