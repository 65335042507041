import {
  CalendarIcon,
  FormCheckbox,
  FormDatePicker,
  FormInput,
  FormSelectionPopup,
  Globe04,
  InputPhoneNumber,
  Text,
  TextLink,
  ThemedIcon,
} from '@metaswiss/ui-kit';
import { ItemProps } from '@metaswiss/ui-kit/src/components/molecules/selection-popup/types';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { FieldError, useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { api } from '../../../../../../../../api/msApi';
import ControlledForm from '../../../../../../../../components/controlled-form/ControlledForm';
import { PasswordValidationInputs } from '../../../../../../../../components/passwords-validation-inputs/PasswordsValidationInputs';
import { useCountries } from '../../../../../../../../hooks/use-countries/useCountries';
import { useTenantConfig } from '../../../../../../../../hooks/use-tenant-config/useTenantConfig';
import { useTextTranslation } from '../../../../../../../../hooks/use-text-translation/useTextTranslation';
import { MainContentContainer } from '../../../../../../../../layouts/public-layout/styles/publicLayout.styles';
import { routes } from '../../../../../../../../router/routes';
import { swissPhonePrefix } from '../../../../../../../../shared/constants/swissPhonePrefix';
import { useAuthContext } from '../../../../../../shared/auth-context/authContext';
import { useSetAuthConfig } from '../../../../../../shared/auth-context/useSetAuthConfig';
import { RegisterHeader } from '../../../../../shared/register-header/RegisterHeader';
import {
  CheckboxWrapper,
  OneLineElement,
  RegistrationForm,
  RegistrationGridWrapper,
  TextLinkWrapper,
} from '../../../../../shared/styles/registrationStyles';
import { ValidatedInputField } from '../../../../../shared/validated-input-field/ValidatedInputField';
import { FullPrivateRegistrationData, initialFullPrivateRegistrationValues } from '../fullPrivateRegistrationState';

import {
  createFullPersonalInformationSchema,
  FullPrivateRegistrationPersonalInfoFormData,
  fullRegistrationPrivatePersonalInfoSchema,
} from './personalInformationSchema';

export const PersonalInformationFormView = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { countriesNationalityAsDropdown, countriesPhoneNumberPrefixesAsDropdown } = useCountries();
  const { textTranslation, currentLanguage } = useTextTranslation();
  const tenantConfig = useTenantConfig();
  const { setUserDetails, userRole, setIsValid, prevStep, nextStep, setCheckedEmail } = useAuthContext(
    (state) => state
  );
  const [doPasswordsMatch, setDoPasswordsMatch] = useState<boolean>(true);

  const {
    control,
    setValue,
    getValues,
    setError,
    handleSubmit,
    formState: { errors, isValid },
  } = useFormContext<FullPrivateRegistrationPersonalInfoFormData>();

  const setRegistrationContextState = useCallback(() => {
    const userDetails = { ...initialFullPrivateRegistrationValues, ...(getValues() as FullPrivateRegistrationData) };
    setUserDetails(userDetails);
  }, [getValues, setUserDetails]);

  useEffect(() => {
    return () => {
      setRegistrationContextState();
    };
  }, [setRegistrationContextState]);

  useEffect(() => {
    const defaultPhoneNumberPrefix = countriesPhoneNumberPrefixesAsDropdown.find(
      (item: ItemProps) => item.meta === swissPhonePrefix
    );
    const phoneNumberPrefixValue = defaultPhoneNumberPrefix?.meta ?? '';

    if (!getValues(fullRegistrationPrivatePersonalInfoSchema.phoneNumberPrefix)) {
      setValue(fullRegistrationPrivatePersonalInfoSchema.phoneNumberPrefix, phoneNumberPrefixValue);
    }
  }, [countriesPhoneNumberPrefixesAsDropdown, setValue, getValues]);

  const handlePhonePrefixSelection = (item: ItemProps) => {
    setValue(fullRegistrationPrivatePersonalInfoSchema.phoneNumberPrefix, `${item.meta}`, { shouldValidate: true });
  };

  const handleBirthDateSelection = (date: string) => {
    setValue(fullRegistrationPrivatePersonalInfoSchema.birthDate, date, { shouldValidate: true });
  };

  const getSelectedNumberPrefix = useCallback(() => {
    return countriesPhoneNumberPrefixesAsDropdown.find((country) => country.meta === getValues('phoneNumberPrefix'));
  }, [countriesPhoneNumberPrefixesAsDropdown, getValues]);

  const handlePersonalInfoSubmit = useCallback(() => {
    setRegistrationContextState();
    nextStep();
  }, [nextStep, setRegistrationContextState]);

  const checkEmailMutation = useMutation({
    mutationFn: () => {
      return api.users.checkEmailExists(getValues().email);
    },
    onMutate: () => {
      setCheckedEmail(getValues().email);
    },
    onError: () => {
      setError('email', { message: textTranslation('error.default') });
    },
    onSuccess: (result) => {
      if (result) {
        setError('email', { message: textTranslation('error.emailExist') });
      }
    },
  });

  useEffect(() => {
    setIsValid(isValid && doPasswordsMatch && !checkEmailMutation.isLoading && !Object.keys(errors || {}).length);
    return () => {
      setIsValid(true);
    };
  }, [doPasswordsMatch, isValid, setIsValid, checkEmailMutation.isLoading, errors]);

  const handleBackClick = () => {
    setValue(fullRegistrationPrivatePersonalInfoSchema.termsAndConditions, false);
    navigate(`${routes.register.root}/${userRole}`);
  };

  useSetAuthConfig(
    {
      wrapperStyle: 'registrationWizard',
      containerStyle: 'registrationWizard',
      backButton: {
        onClick: () => handleBackClick(),
        dataAttributes: { dataTestid: 'back-button' },
        text: 'button.back',
      },
      nextButton: {
        text: 'button.next',
        dataAttributes: { dataTestid: 'next-button' },
        onClick: () => handleSubmit(handlePersonalInfoSubmit)(),
      },
      showStepper: true,
    },
    [handlePersonalInfoSubmit, prevStep]
  );

  return (
    <>
      <RegisterHeader descriptionText={textTranslation('registration.personalInfoHeader')} />
      <RegistrationForm>
        <RegistrationGridWrapper>
          <FormInput<FullPrivateRegistrationPersonalInfoFormData>
            name={fullRegistrationPrivatePersonalInfoSchema.firstName}
            error={errors[fullRegistrationPrivatePersonalInfoSchema.firstName]}
            control={control}
            fill
            label={textTranslation('registration.private.firstName')}
            dataAttributes={{ dataTestid: 'first-name' }}
          />
          <FormInput<FullPrivateRegistrationPersonalInfoFormData>
            name={fullRegistrationPrivatePersonalInfoSchema.lastName}
            error={errors[fullRegistrationPrivatePersonalInfoSchema.lastName]}
            control={control}
            fill
            label={textTranslation('registration.private.lastName')}
            dataAttributes={{ dataTestid: 'last-name' }}
          />
          <OneLineElement>
            <ValidatedInputField<FullPrivateRegistrationPersonalInfoFormData>
              name={fullRegistrationPrivatePersonalInfoSchema.email}
              error={errors[fullRegistrationPrivatePersonalInfoSchema.email]}
              control={control}
              fill
              label={textTranslation('registration.email')}
              checkValueExists={checkEmailMutation.mutate}
              isSpinnerIcon={checkEmailMutation?.isLoading}
            />
          </OneLineElement>
          <PasswordValidationInputs
            control={control}
            passwordName={fullRegistrationPrivatePersonalInfoSchema.password}
            passwordConfirmationName={fullRegistrationPrivatePersonalInfoSchema.passwordConfirmation}
            passwordError={errors[fullRegistrationPrivatePersonalInfoSchema.password]}
            passwordConfirmationError={errors[fullRegistrationPrivatePersonalInfoSchema.passwordConfirmation]}
            passwordLabel={textTranslation('registration.password')}
            passwordConfirmationLabel={textTranslation('registration.passwordConfirmation')}
            doPasswordsMatch={doPasswordsMatch}
            setDoPasswordsMatch={setDoPasswordsMatch}
          />
          <OneLineElement>
            <FormSelectionPopup
              name={fullRegistrationPrivatePersonalInfoSchema.citizenship}
              error={errors[fullRegistrationPrivatePersonalInfoSchema.citizenship] as FieldError}
              control={control}
              fill
              label={textTranslation('registration.citizenship')}
              title={textTranslation('registration.citizenship')}
              searchText={textTranslation('registration.search')}
              renderEndIcon={() => (
                <ThemedIcon icon={Globe04} palette="primary" customStrokeColor={theme.v2.icon.primary} />
              )}
              itemsList={countriesNationalityAsDropdown}
              closeButtonText={textTranslation('cancel')}
              selectButtonText={textTranslation('select')}
              containerId={MainContentContainer.styledComponentId}
              dataAttributeValue={{ select: 'citizenship' }}
            />
          </OneLineElement>
          <InputPhoneNumber<FullPrivateRegistrationPersonalInfoFormData>
            name={fullRegistrationPrivatePersonalInfoSchema.phoneNumber}
            error={
              errors[fullRegistrationPrivatePersonalInfoSchema.phoneNumberPrefix] ||
              errors[fullRegistrationPrivatePersonalInfoSchema.phoneNumber]
            }
            control={control}
            fill
            label={textTranslation('registration.phoneNumber')}
            itemList={countriesPhoneNumberPrefixesAsDropdown}
            title={textTranslation('registration.numberPrefixTitle')}
            handleSelectedItem={handlePhonePrefixSelection}
            searchText={textTranslation('registration.numberPrefixTitle')}
            selectedItem={getSelectedNumberPrefix()}
            closeButtonText={textTranslation('cancel')}
            selectButtonText={textTranslation('select')}
            defaultItem={countriesPhoneNumberPrefixesAsDropdown.find(
              (item: ItemProps) => item.meta === swissPhonePrefix
            )}
            maxLength={16}
            containerId={MainContentContainer.styledComponentId}
            dataAttributes={{ dataTestid: 'phone-number' }}
          />
          <FormDatePicker<FullPrivateRegistrationPersonalInfoFormData>
            name={fullRegistrationPrivatePersonalInfoSchema.birthDate}
            error={errors[fullRegistrationPrivatePersonalInfoSchema.birthDate]}
            control={control}
            fill
            label={textTranslation('registration.birthDate')}
            dateSelectOptions={[
              textTranslation('global.day'),
              textTranslation('global.month'),
              textTranslation('global.year'),
            ]}
            selectText={textTranslation('selectDate')}
            title={textTranslation('registration.birthDate')}
            renderEndIcon={() => (
              <ThemedIcon icon={CalendarIcon} palette="primary" customStrokeColor={theme.v2.icon.primary} />
            )}
            onSelect={handleBirthDateSelection}
            closeBtnText={textTranslation('cancel')}
            confirmBtnText={textTranslation('confirm')}
            containerId={MainContentContainer.styledComponentId}
            preferredLanguage={currentLanguage}
            dataAtrributeValue="birth-date"
          />
          <OneLineElement>
            <CheckboxWrapper>
              <FormCheckbox<FullPrivateRegistrationPersonalInfoFormData>
                name={fullRegistrationPrivatePersonalInfoSchema.termsAndConditions}
                control={control}
                error={errors[fullRegistrationPrivatePersonalInfoSchema.termsAndConditions]}
                dataAttributes={{ dataTestid: 'terms-checkbox' }}
              />
              <TextLinkWrapper>
                <Text>{textTranslation('registration.accept')}</Text>
                <TextLink
                  textSize="sm"
                  dataTestId="terms-conditions"
                  onClick={() => window.open(tenantConfig[`termsAndConditionsLink_${currentLanguage}`], '_blank')}
                >
                  {textTranslation('registration.termsAndConditions')}
                </TextLink>
              </TextLinkWrapper>
            </CheckboxWrapper>
          </OneLineElement>
        </RegistrationGridWrapper>
      </RegistrationForm>
    </>
  );
};

export const PersonalInformationForm = () => {
  const userDetails = useAuthContext((state) => state.userDetails);

  return (
    <ControlledForm validationSchema={createFullPersonalInformationSchema} defaultValues={userDetails}>
      <PersonalInformationFormView />
    </ControlledForm>
  );
};
