import { ItemProps, ItemWithCustomIconProps, PageStateContainer, Text } from '@metaswiss/ui-kit';
import { FileEditIcon } from '@metaswiss/ui-kit/src/iconography/FileEditIcon';
import { FileLockIcon } from '@metaswiss/ui-kit/src/iconography/FileLockIcon';
import { WaveIcon } from '@metaswiss/ui-kit/src/iconography/WaveIcon';
import { useMutation } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useTheme } from 'styled-components';

import { api } from '../../../../api/msApi';
import { SettingsCard } from '../../../../components/settings-card/SettingsCard';
import { Languages } from '../../../../enums/language';
import { useAppState } from '../../../../global-state/zustand';
import { useTenantConfig } from '../../../../hooks/use-tenant-config/useTenantConfig';
import { useTextTranslation } from '../../../../hooks/use-text-translation/useTextTranslation';
import { getCountryFlagHelper } from '../../../../shared/helpers/getCountryFlag.helper';

import { CardsContainer, CardWrapper, Container } from './settings.styles';

export const Settings = () => {
  const { textTranslation, setTranslationLanguage, currentLanguage } = useTextTranslation();
  const tenantConfig = useTenantConfig();
  const theme = useTheme();
  const { user } = useAppState((state) => state);

  // TODO: Rethink this we have on two place two different arrays
  const languageOptions = useMemo<ItemProps[]>(
    () => [
      { value: Languages.ENGLISH, label: textTranslation('en'), iconSrc: getCountryFlagHelper('gb') },
      { value: Languages.GERMAN, label: textTranslation('de'), iconSrc: getCountryFlagHelper('de') },
    ],
    [textTranslation, getCountryFlagHelper]
  );

  const { mutate } = useMutation({
    mutationFn: (data: string) => {
      return api.users.updatePreferredLanguage(user?.id || '', { preferredLanguage: data });
    },
  });

  const handleLanguageChange = (lang: ItemProps | ItemWithCustomIconProps) => {
    setTranslationLanguage(lang.value as Languages);
    mutate(lang.value);
  };

  return (
    <PageStateContainer showLoading={false} showError={false} showEmptyState={false} textTranslation={textTranslation}>
      <Container>
        <Text
          fontSize={'lg'}
          color={theme.v2.text.headingPrimary}
          fontWeight={'bold'}
          lineHeight={'h4'}
        >{`${textTranslation('home.customize')} ${tenantConfig.appTitle} ${textTranslation('home.app')}`}</Text>
        <CardsContainer>
          <CardWrapper>
            <SettingsCard
              title={textTranslation('home.language')}
              icon={<WaveIcon color={theme.colors.primary.hue0} strokeColor={theme.v2.icon.primary} />}
              selectedOptionText={textTranslation('home.selectedLanguage')}
              displaySelectedOptionLabel={textTranslation(`${currentLanguage}`)}
              type={'language'}
              options={languageOptions}
              onChange={handleLanguageChange}
              selectedOption={languageOptions.find((option) => option.value === currentLanguage || '')}
              dataAttribute="language-button"
            />
          </CardWrapper>
          <CardWrapper>
            <SettingsCard
              title={textTranslation('home.terms')}
              icon={<FileEditIcon strokeColor={theme.v2.icon.primary} color={'transparent'} />}
              onClick={() => window.open(tenantConfig[`termsAndConditionsLink_${currentLanguage}`], '_blank')}
              dataAttribute="terms-button"
            />
          </CardWrapper>
          <CardWrapper>
            <SettingsCard
              title={textTranslation('home.privacyPolicy')}
              icon={<FileLockIcon strokeColor={theme.v2.icon.primary} color={'transparent'} />}
              onClick={() => window.open(tenantConfig[`privacyPolicyLink_${currentLanguage}`], '_blank')}
              dataAttribute="privacy-button"
            />
          </CardWrapper>
        </CardsContainer>
      </Container>
    </PageStateContainer>
  );
};
