import { ThemedIcon, FaceId01, FileCheck2 } from '@metaswiss/ui-kit';
import { FC } from 'react';
import { useTheme } from 'styled-components';

import { SettingsCard } from '../../../../components/settings-card/SettingsCard';
import { DocumentStatus } from '../../../../enums/documentStatus.enum';
import { useTextTranslation } from '../../../../hooks/use-text-translation/useTextTranslation';

import { CardWrapper } from './styles/verification.styles';

type Props = {
  proofOfDomicileStatus: DocumentStatus;
  identityProofStatus: DocumentStatus;
  setIsIdentityModalOpen: (isIdentityModalOpen: boolean) => void;
  setIsDomicileModalOpen: (isDomicileModalOpen: boolean) => void;
};

export const PrivateVerification: FC<Props> = ({
  identityProofStatus,
  proofOfDomicileStatus,
  setIsIdentityModalOpen,
  setIsDomicileModalOpen,
}) => {
  const theme = useTheme();
  const { textTranslation } = useTextTranslation();

  return (
    <CardWrapper>
      <SettingsCard
        title={textTranslation('account.identityVerification')}
        icon={<ThemedIcon icon={FaceId01} customStrokeColor={theme.v2.text.action} palette={'primary'} size={'xl'} />}
        selectedOptionText={textTranslation('account.requiredDocs')}
        displaySelectedOptionLabel={textTranslation(`account.IDorPassport`)}
        onClick={() => setIsIdentityModalOpen(identityProofStatus !== DocumentStatus.VERIFIED)}
        documentStatus={identityProofStatus}
        documentStatusTranslation={textTranslation(`account.${identityProofStatus}`)}
        width={'21.75rem'}
        isHoverActive={identityProofStatus !== DocumentStatus.VERIFIED}
        disabled={identityProofStatus === DocumentStatus.SUBMITTED}
        dataAttribute="verification-identity-button"
      />
      <SettingsCard
        title={textTranslation('account.proofDomicileTitle')}
        icon={<ThemedIcon icon={FileCheck2} customStrokeColor={theme.v2.text.action} palette={'primary'} size={'xl'} />}
        selectedOptionText={textTranslation('account.requiredDocs')}
        displaySelectedOptionLabel={textTranslation(`account.utilityBillOrSimilar`)}
        onClick={() => setIsDomicileModalOpen(proofOfDomicileStatus !== DocumentStatus.VERIFIED)}
        documentStatus={proofOfDomicileStatus}
        documentStatusTranslation={textTranslation(`account.${proofOfDomicileStatus}`)}
        width={'21.75rem'}
        isHoverActive={proofOfDomicileStatus !== DocumentStatus.VERIFIED}
        disabled={proofOfDomicileStatus === DocumentStatus.SUBMITTED}
        dataAttribute="verification-proof-button"
      />
    </CardWrapper>
  );
};
