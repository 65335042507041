import { AssetUploadUnassignedResponse } from '@metaswiss/api';
import { convertDocumentSize } from '@metaswiss/lib';
import { UploadFile, Text } from '@metaswiss/ui-kit';
import { AcceptedFileTypes } from '@metaswiss/ui-kit/src/components/molecules/upload-file/enums';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import ControlledForm from '../../../../../../../../components/controlled-form/ControlledForm.tsx';
import { usePostAndGetDocument } from '../../../../../../../../hooks/use-post-and-get-document/usePostAndGetDocument';
import { useTextTranslation } from '../../../../../../../../hooks/use-text-translation/useTextTranslation';
import { useAuthContext } from '../../../../../../shared/auth-context/authContext.ts';
import { useSetAuthConfig } from '../../../../../../shared/auth-context/useSetAuthConfig.ts';
import { StepTextContainer } from '../../../../../register.styles';
import { RegisterHeader } from '../../../../../shared/register-header/RegisterHeader';
import { DocumentProps } from '../../../../../shared/types/documentUploadType';
import { FullPrivateRegistrationData, initialFullPrivateRegistrationValues } from '../fullPrivateRegistrationState';

import {
  createFullPrivateProofOfDomicileSchema,
  fullRegistrationPrivateProofOfDomicileSchema,
} from './proofOfDomicileSchema';

export const ProofOfDomicileView = () => {
  const { textTranslation } = useTextTranslation();
  const { userDetails, setIsValid, setUserDetails, nextStep, prevStep } = useAuthContext((state) => state);
  const proofOfDomicile = (userDetails as FullPrivateRegistrationData).proofOfDomicile;

  const {
    getValues,
    setValue,
    formState: { isValid },
  } = useFormContext();

  const { mutate, isLoading, isError } = usePostAndGetDocument({
    onMutate: handleProofOfDomicileMutation,
    onRefetch: handleProofOfDomicileRefetch,
    getDocumentInfo: ({ fileName, size }) => {
      const proof = {
        fileName,
        fileSize: convertDocumentSize(size),
      } as DocumentProps;
      setUserDetails({
        proofOfDomicile: proof,
      });
    },
    fileId: proofOfDomicile?.fileId,
  });

  const setRegistrationFlowProofOfDomicile = (documentProps: DocumentProps) => {
    const proof = documentProps as DocumentProps;
    setUserDetails({
      proofOfDomicile: {
        ...proof,
      },
    });
  };

  function handleProofOfDomicileMutation({ fileId, url }: AssetUploadUnassignedResponse) {
    setValue(fullRegistrationPrivateProofOfDomicileSchema.proofOfDomicile, fileId, { shouldValidate: true });
    setRegistrationFlowProofOfDomicile({
      fileId,
      url,
      fileName: proofOfDomicile.fileName,
      fileSize: proofOfDomicile.fileSize,
    });
  }

  function handleProofOfDomicileRefetch(documentUrl: string) {
    setRegistrationFlowProofOfDomicile({
      fileId: getValues('proofOfDomicile'),
      url: documentUrl,
      fileName: proofOfDomicile.fileName,
      fileSize: proofOfDomicile.fileSize,
    });
  }

  useEffect(() => {
    setIsValid(isValid);
    return () => {
      setIsValid(true);
    };
  }, [isValid, setIsValid]);

  const handleProofOfDomicileUpload = (file: File) => {
    mutate(file);
  };

  const handleProofOfDomicileDelete = () => {
    setRegistrationFlowProofOfDomicile({ ...initialFullPrivateRegistrationValues.proofOfDomicile });
    setValue('proofOfDomicile', '', { shouldValidate: true });
  };

  useSetAuthConfig(
    {
      wrapperStyle: 'registrationWizard',
      containerStyle: 'registrationWizard',
      backButton: {
        onClick: () => prevStep(),
        text: 'button.back',
        dataAttributes: { dataTestid: 'back-button' },
      },
      nextButton: {
        text: 'button.next',
        onClick: () => nextStep(),
        dataAttributes: { dataTestid: 'next-button' },
      },
      showStepper: true,
    },
    [nextStep, prevStep]
  );

  return (
    <>
      <RegisterHeader descriptionText={textTranslation('registration.proofOfDomicileHeader')} />
      <StepTextContainer>
        <Text fontWeight="bold">{textTranslation('registration.proofOfDomicilePageTitle')}</Text>
      </StepTextContainer>
      <UploadFile
        uploadFile={handleProofOfDomicileUpload}
        onDelete={handleProofOfDomicileDelete}
        isLoading={isLoading}
        isError={isError}
        initialTitle={textTranslation('registration.uploadDocumentTitle')}
        initialSubtitle={textTranslation('registration.uploadDocumentSubtitle')}
        invalidTitle={textTranslation('registration.invalidPhotoTitle')}
        invalidSubtitle={textTranslation('registration.uploadDocumentSubtitle')}
        failedTitle={textTranslation('registration.failedPhotoUploadTitle')}
        failedSubtitle={textTranslation('registration.failedPhotoUploadSubtitle')}
        viewFileTitle={proofOfDomicile?.fileName}
        viewFileSubtitle={proofOfDomicile?.fileSize}
        acceptedFileTypes={AcceptedFileTypes.ALL}
        $width="37.5rem"
        $height="26rem"
        onViewDocument={() => window.open(proofOfDomicile.url, '_blank')}
        value={proofOfDomicile || initialFullPrivateRegistrationValues.proofOfDomicile}
        dataAttributes={{ dataTestid: 'proof-of-domicile' }}
      />
    </>
  );
};

export const ProofOfDomicile = () => {
  const userDetails = useAuthContext((state) => state.userDetails) as FullPrivateRegistrationData;
  return (
    <ControlledForm
      validationSchema={createFullPrivateProofOfDomicileSchema}
      defaultValues={{
        proofOfDomicile: userDetails?.proofOfDomicile?.fileId || '',
      }}
    >
      <ProofOfDomicileView />
    </ControlledForm>
  );
};
