import { mapIsoStringToDate } from '@metaswiss/lib';
import { BankNoteIcon02, BaseCard, CalendarIcon, Chip, Flex, IconProps, Text, ThemedIcon } from '@metaswiss/ui-kit';
import { CurrencyChfIcon } from '@metaswiss/ui-kit/src/iconography/CurrencyChfIcon';
import { useQuery } from '@tanstack/react-query';
import { FC, useMemo } from 'react';
import { useTheme } from 'styled-components';

import { api } from '../../api/msApi';
import { CouponStatus } from '../../enums/couponStatus.enum';
import { ApiResource } from '../../enums/resource.enum';
import { useTextTranslation } from '../../hooks/use-text-translation/useTextTranslation';
import { getQueryKey } from '../../shared/helpers/getQueryKey.helper';

import {
  CardWrapper,
  HeaderContainer,
  IconWrapper,
  InfoContainer,
  TextWrapper,
  TransactionCardHeader,
} from './couponCard.styles';
import { DownloadPayoutDocument } from './download-payout-document/DownloadPayoutDocument';

type Props = {
  serialNumber: string;
  status: string;
  price: string;
  expiryDate: string;
  paymentDays: string;
  assetId: string;
};

type CouponInfoProps = {
  icon: FC<IconProps>;
  label: string;
  value: string;
};

const CouponInfo: FC<CouponInfoProps> = ({ icon, label, value }) => {
  const theme = useTheme();
  return (
    <CardWrapper>
      <IconWrapper>
        <ThemedIcon icon={icon} customStrokeColor={theme.v2.icon.primary} />
      </IconWrapper>
      <TextWrapper>
        <Text fontSize={'xsm'} lineHeight={'extraSmall'} color={theme.v2.text.disabled}>
          {label}
        </Text>
        <Text fontSize={'sm'} lineHeight={'medium'} fontWeight={'semi'} color={theme.v2.text.headingPrimary}>
          {value}
        </Text>
      </TextWrapper>
    </CardWrapper>
  );
};

export const CouponCard: FC<Props> = ({ serialNumber, status, price, expiryDate, paymentDays, assetId }) => {
  const { textTranslation } = useTextTranslation();
  const theme = useTheme();
  const statusChip = useMemo(() => getCouponChip(status as CouponStatus), [status]);

  const { data } = useQuery({
    queryKey: getQueryKey(ApiResource.ASSET),
    queryFn: () => {
      return api.assets.getS3SignedAssignedUrl({ assetId });
    },
    enabled: !!assetId,
  });

  return (
    <BaseCard
      padding="0rem"
      disableStateEffects
      hasCursorPointer={false}
      removeOverflow
      override={{
        borderRadius: theme.v2.componentsRadius.cards.default,
        backgroundColor: theme.v2.surface.primary,
        borderColor: theme.v2.border.primary,
      }}
    >
      <HeaderContainer>
        <TransactionCardHeader>
          <Text fontSize={'sm'} fontWeight={'semi'} lineHeight={'medium'} color={theme.v2.text.headingPrimary}>
            {serialNumber}
          </Text>
          <Flex gap="1rem" alignItems="center">
            {status === CouponStatus.PAID && (
              <DownloadPayoutDocument
                description={textTranslation('portfolio.payoutDocuments')}
                onClick={() => {
                  window.open(data?.url, '_blank');
                }}
              />
            )}
            <Chip label={textTranslation(`portfolio.${status.toLowerCase()}`)} status={statusChip} />
          </Flex>
        </TransactionCardHeader>
      </HeaderContainer>
      <InfoContainer>
        <CouponInfo icon={CurrencyChfIcon} label={textTranslation('portfolio.price')} value={price} />
        <CouponInfo
          icon={CalendarIcon}
          label={textTranslation('global.expiryDate')}
          value={mapIsoStringToDate({ date: expiryDate })}
        />
        <CouponInfo icon={BankNoteIcon02} label={textTranslation('global.payoutDays')} value={paymentDays} />
      </InfoContainer>
    </BaseCard>
  );
};

const getCouponChip = (status: CouponStatus) => {
  switch (status) {
    case CouponStatus.PAID: {
      return 'primary';
    }
    case CouponStatus.NOT_PAID: {
      return 'error';
    }
    default:
      throw new Error('Unsupported status');
  }
};
