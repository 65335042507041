import { NftPortfolioAssetsResponse } from '@metaswiss/api';
import { formatAmountWithoutDecimals } from '@metaswiss/lib';
import {
  Button,
  DataStatusPage,
  ItemsCounter,
  NftHolder,
  PageStateContainer,
  Text,
  TextLink,
  ThemedIcon,
  TrendUpCollectibleIcon,
} from '@metaswiss/ui-kit';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { api } from '../../../../api/msApi';
import { ProductType } from '../../../../enums/productType.enum';
import { ApiResource } from '../../../../enums/resource.enum';
import { TransactionType } from '../../../../enums/transactionType';
import { useTextTranslation } from '../../../../hooks/use-text-translation/useTextTranslation';
import { routes } from '../../../../router/routes';
import { swissCode } from '../../../../shared/constants/currencyCode';
import { getQueryKey } from '../../../../shared/helpers/getQueryKey.helper';
import { PortfolioBanner } from '../shared/components/portfolio-banner/PortfolioBanner';
import { PortfolioCard } from '../shared/components/portfolio-card/PortfolioCard';

import { CounterButtonWrapper, NftsBuyingHistoryHeader, NftsContainer } from './smartCollectibles.styles';

const numberOfDisplayedNfts = 6;

export const SmartCollectibles = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { textTranslation } = useTextTranslation();

  const {
    data: nftsData,
    isError: isErrorNfts,
    isLoading: isLoadingNfts,
    refetch: refetchNfts,
  } = useQuery({
    queryKey: getQueryKey(ApiResource.PORTFOLIO, ProductType.NFT),
    queryFn: () => api.clientPortfolio.getSmartNftInvestments(0, numberOfDisplayedNfts),
  });

  const {
    data: investedPortfolioData,
    isError: isErrorInvestedPortfolio,
    isLoading: isLoadingInvestedPortfolio,
    refetch: refetchInvestedPortfolio,
  } = useQuery({
    queryKey: getQueryKey(ApiResource.NFT_PORTFOLIO_STATISTICS),
    queryFn: () => api.clientPortfolio.getSmartNftInvestmentStatistics(),
  });

  const totalNftsBought = useMemo(() => investedPortfolioData?.totalNFTsBought || 0, [investedPortfolioData]);
  const totalInvestedAmount = useMemo(
    () => investedPortfolioData?.totalInvestedAmount?.split(' ')[0] || '0',
    [investedPortfolioData]
  );
  const portfolioItems: NftPortfolioAssetsResponse[] = useMemo(
    () => nftsData?.items as unknown as NftPortfolioAssetsResponse[],
    [nftsData]
  );

  return (
    <PageStateContainer
      showLoading={true}
      isLoading={isLoadingNfts || isLoadingInvestedPortfolio}
      showError={true}
      isError={isErrorNfts || isErrorInvestedPortfolio}
      onTryAgain={async () => await Promise.all([refetchNfts(), refetchInvestedPortfolio()])}
      showEmptyState={true}
      isEmptyState={!totalNftsBought || !portfolioItems}
      textTranslation={textTranslation}
      customEmptyStateComponent={
        <DataStatusPage
          icon={
            <ThemedIcon
              icon={TrendUpCollectibleIcon}
              customColor={theme.v2.colors.tertiary25}
              customStrokeColor={theme.v2.icon.tertiary}
              size={'full'}
            />
          }
          title={textTranslation('portfolio.noCollectibles')}
          content={textTranslation('portfolio.noCollectiblesPurchased')}
          buttonText={textTranslation('portfolio.exploreCollectibles')}
          action={() => navigate(routes.offering.smartCollectibles.root)}
          additionalContent={textTranslation('portfolio.noCollectiblesFollow')}
          iconWidth={'8.5rem'}
          iconHeight={'8.5rem'}
        />
      }
    >
      <PortfolioBanner
        type={ProductType.NFT}
        heading={textTranslation('portfolio.investInSmartCollectibles')}
        text={textTranslation('portfolio.fullyDigitalizedContract')}
      />
      <PortfolioCard
        type={TransactionType.NFT}
        price={totalInvestedAmount}
        titleCurrency={swissCode}
        count={formatAmountWithoutDecimals(totalNftsBought)}
        description={
          totalNftsBought === 1 ? textTranslation('portfolio.collectible') : textTranslation('portfolio.collectibles')
        }
        title={textTranslation('portfolio.collectiblesValues')}
      />
      <NftsBuyingHistoryHeader>
        <Text fontSize={'lg'} lineHeight={'h4'} fontWeight={'bold'}>
          {textTranslation('portfolio.smartCollectibleBuyingHistory')}
        </Text>
        {totalNftsBought > numberOfDisplayedNfts && (
          <TextLink
            padding={'0.25rem'}
            onClick={() => navigate(routes.portfolio.smartCollectibles.nftHistory)}
          >{`${textTranslation('global.viewAll')} (${formatAmountWithoutDecimals(totalNftsBought)})`}</TextLink>
        )}
      </NftsBuyingHistoryHeader>
      <NftsContainer>
        {portfolioItems?.map((nftPortfolioAsset) => {
          return (
            <NftHolder
              key={nftPortfolioAsset.id}
              serialNumber={nftPortfolioAsset.serialNumber}
              price={nftPortfolioAsset.nftPrice}
              priceText={textTranslation('global.price')}
              buyText={textTranslation('offering.buyNow')}
              imageUrl={nftPortfolioAsset.nftThumbnailUrl}
              collectionName={nftPortfolioAsset.collectionName}
              onClick={() => navigate(`${routes.portfolio.smartCollectibles.root}/${nftPortfolioAsset.id}`)}
            />
          );
        })}
      </NftsContainer>
      {totalNftsBought > numberOfDisplayedNfts && (
        <CounterButtonWrapper>
          <ItemsCounter
            displayedItems={numberOfDisplayedNfts}
            totalItems={totalNftsBought}
            ofText={textTranslation('portfolio.of')}
            itemsText={textTranslation('portfolio.assets')}
            displayedItemsText={formatAmountWithoutDecimals(numberOfDisplayedNfts)}
            totalItemsText={formatAmountWithoutDecimals(totalNftsBought)}
          />
          <Button
            text={`${textTranslation('portfolio.viewAll')} (${formatAmountWithoutDecimals(totalNftsBought)})`}
            onClick={() => navigate(routes.portfolio.smartCollectibles.nftHistory)}
          />
        </CounterButtonWrapper>
      )}
    </PageStateContainer>
  );
};
