import { CSSProperties } from 'react';
import styled from 'styled-components';

import { breakpoints } from '../../../../../breakpoints';

export const BannerContainer = styled.div<{ $alignItems: CSSProperties['alignItems'] }>`
  display: flex;
  padding: 1rem;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: ${({ $alignItems }) => $alignItems};
  background-color: ${({ theme }) => theme.colors.neutrals.hue0};
  border: 1px solid ${({ theme }) => theme.colors.neutrals.hue100};
  border-radius: ${({ theme }) => theme.v2.componentsRadius.cards.large};
`;

export const RenderElementWrapper = styled.div`
  min-width: 18.75rem;
`;

export const StickyCardWrapper = styled.div<{ $headerOffset: number }>`
  position: absolute;
  top: ${({ $headerOffset }) => `${$headerOffset - 1}px`};
  z-index: 2;
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  background-color: ${({ theme }) => theme.colors.neutrals.hue0};
  border-top: 1px solid ${({ theme }) => theme.colors.neutrals.hue100};
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutrals.hue100};
  display: flex;
  justify-content: center;

  box-shadow:
    0px 0px 16px 0px #00000005,
    0px 1px 0px 0px #00000005,
    0px 10px 24px 0px #00000005;

  @media ${breakpoints.laptopSm} {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
`;

export const RenderElementContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  min-width: 18.75rem;
`;

export const BannerWrapper = styled.div<{ $alignItems: CSSProperties['alignItems'] }>`
  width: 100%;
  display: flex;
  max-width: ${({ theme }) => theme.pageWidth};
  padding: 1rem;
  justify-content: space-between;
  align-items: ${({ $alignItems }) => $alignItems};
  flex-wrap: wrap;
`;

export const BottomElementWrapper = styled.div`
  flex-basis: 100%;
`;
