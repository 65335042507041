import styled from 'styled-components';

export const ClientContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.v2.surface.action1};
  background-color: ${({ theme }) => theme.colors.neutrals.hue0};
  height: 4.75rem; // 2.75rem is height of the button filters and 2 rem is the padding
`;

export const TitleAndBreadcrumb = styled.div`
  max-width: 100%;
  flex: 2;
  width: 100%;
  min-width: 0;
`;

export const HeaderTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-shrink: 1;
`;

export const BreadcrumbContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  flex-shrink: 1;
`;

export const HeaderContent = styled.div`
  flex: 1;
  justify-content: end;
  display: flex;
  gap: 1rem;
  align-items: center;
  max-width: 100%;
  min-width: fit-content;
  display: flex;
  flex-shrink: 0;
`;

export const InputWrapper = styled.div`
  height: 2.5rem;
  width: 21.25rem;
`;

export const AvatarWrapper = styled.div`
  position: relative;
`;
export const IconsWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const Divider = styled.div`
  height: 1.5rem;
  width: 1px;
  background-color: ${({ theme }) => theme.colors.neutrals.hue200};
`;
