import { useMemo } from 'react';
import { useTheme } from 'styled-components';

import { TextTemplate } from '../../../shared/typography/TextTemplate.styles';
import { TypographyProps } from '../../../shared/typography/typographyProps';

export type HeadingTypeProp = 'h1' | 'h2' | 'h3' | 'h4';

type HeadingProps = TypographyProps & {
  children: string;
  headingType: HeadingTypeProp;
  color?: string;
  dataAttributes?: string;
};

export const Heading = ({
  children,
  headingType,
  palette = 'neutrals',
  textColor = 'hue700',
  fontWeight = 'reg',
  lineHeight,
  color,
  dataAttributes,
}: HeadingProps) => {
  const headingLineHeight = useMemo(() => lineHeight ?? headingType, []);
  const theme = useTheme();

  const defaultColor =
    headingType === 'h1' || headingType === 'h2' ? theme.v2.text.headingPrimary : theme.v2.text.bodyPrimary;

  return (
    <TextTemplate
      as={headingType}
      data-testid={dataAttributes}
      $fontSize={headingType}
      $textColor={textColor}
      $palette={palette}
      $fontWeight={fontWeight}
      $lineHeight={headingLineHeight}
      $color={color || defaultColor}
    >
      {children}
    </TextTemplate>
  );
};
