import {
  BondFullResponseDTO,
  CurrencyResponse,
  NftExtendedV5Response,
  PaymentResponse,
  ShareFullResponseDTO,
} from '@metaswiss/api';
import { formatAmount, getFirstSixDigitsOfSerialNumber } from '@metaswiss/lib';
import { ArrowChartIcon, BarChartNoBorderIcon, Button, Text, ThemedIcon } from '@metaswiss/ui-kit';
import { useQuery } from '@tanstack/react-query';
import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { api } from '../../api/msApi';
import { PaymentMethod } from '../../enums/paymentMethod.enum';
import { ProductType } from '../../enums/productType.enum';
import { ApiResource } from '../../enums/resource.enum';
import { TransactionType } from '../../enums/transactionType';
import { useTextTranslation } from '../../hooks/use-text-translation/useTextTranslation';
import { routes } from '../../router/routes';
import { getQueryKey } from '../../shared/helpers/getQueryKey.helper';

import { SuccessfullyPurchasedBondShare } from './successfully-purchased-item-components/SuccessfullyPurchasedBondShare';
import { SuccessfullyPurchasedNft } from './successfully-purchased-item-components/SuccessfullyPurchasedNft';
import {
  ButtonsWrapper,
  ContentWrapper,
  IconSmallerWrapper,
  IconWrapper,
  ImageHolder,
  LowerText,
  TextWrapper,
  UpperContent,
  Wrapper,
} from './successfullyPurchasedSharedComponent.styles';

type Props = {
  paymentResponse: PaymentResponse;
  item: BondFullResponseDTO | ShareFullResponseDTO | NftExtendedV5Response;
  productType: ProductType;
  paymentMethod: PaymentMethod;
  currency: CurrencyResponse;
};

export const SuccessfullyPurchasedSharedComponent: FC<Props> = ({
  paymentResponse,
  item,
  productType,
  paymentMethod,
  currency,
}) => {
  const theme = useTheme();
  const { textTranslation } = useTextTranslation();

  const navigate = useNavigate();

  const { icon, iconColor, iconHolderColor } = useMemo(() => {
    if (productType !== ProductType.NFT) {
      return productType === ProductType.ACTIONS
        ? {
            icon: BarChartNoBorderIcon,
            iconColor: theme.v2.colors.primary100,
            iconHolderColor: theme.v2.surface.informationLight,
          }
        : {
            icon: ArrowChartIcon,
            iconColor: theme.v2.colors.secondary100,
            iconHolderColor: theme.v2.surface.action2Light,
          };
    }
    return { icon: null, iconColor: null };
  }, [productType, theme.v2]);

  const productName = useMemo(() => {
    switch (productType) {
      case ProductType.BOND_PAPERS:
        return `${(item as BondFullResponseDTO)?.issuer?.issuerName} Bond`;
      case ProductType.ACTIONS:
        return `${(item as ShareFullResponseDTO)?.issuer?.issuerName} Share`;
      case ProductType.NFT:
        return `${(item as NftExtendedV5Response)?.nftCollection?.name} #${getFirstSixDigitsOfSerialNumber(
          (item as NftExtendedV5Response)?.code
        )} ${textTranslation('portfolio.collectible')}`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productType, item]);

  const { data: transaction } = useQuery({
    queryKey: getQueryKey(ApiResource.TRANSACTIONS, paymentResponse.transactionId),
    queryFn: () => api.transactions.getTransactionDetails(paymentResponse.transactionId),
    enabled: !!paymentResponse?.transactionId,
  });

  return (
    <Wrapper
      initial={{
        position: 'absolute',
        top: '100%',
      }}
      animate={{
        position: 'relative',
        top: '4rem',
      }}
      transition={{
        type: 'spring',
        mass: 1,
        stiffness: 100,
        damping: 15,
        delay: 0.1,
      }}
    >
      <UpperContent>
        <IconWrapper>
          {icon && (
            <IconSmallerWrapper $backgroundColor={iconHolderColor}>
              <ThemedIcon size={'extraLarge'} icon={icon} customStrokeColor={iconColor} />
            </IconSmallerWrapper>
          )}
          {(item as NftExtendedV5Response)?.thumbnailUrl && (
            <ImageHolder src={(item as NftExtendedV5Response)?.thumbnailUrl} />
          )}
        </IconWrapper>
        <ContentWrapper>
          <TextWrapper>
            <Text
              fontSize={'lg'}
              lineHeight={'h4'}
              fontWeight={'bold'}
              color={theme.v2.text.headingPrimary}
              dataAttributes={{ dataTestid: 'success-success' }}
            >
              {paymentMethod === PaymentMethod.STRIPE
                ? textTranslation('payment.successfullyPurchased')
                : textTranslation('payment.successfullyReserved')}
            </Text>
            <LowerText>
              <div>
                <Text color={theme.v2.text.headingPrimary}>{`${
                  paymentMethod === PaymentMethod.STRIPE
                    ? textTranslation('payment.youSuccessfully')
                    : textTranslation('payment.youSuccessfullyReserved')
                } `}</Text>
                <Text color={theme.v2.text.headingPrimary} fontWeight={'semi'}>
                  {productName}
                </Text>
                <Text color={theme.v2.text.headingPrimary}>{` ${textTranslation('payment.inTotal')} `}</Text>
                <Text color={theme.v2.text.headingPrimary}>{textTranslation('payment.of')}</Text>
                <Text fontWeight={'semi'} color={theme.v2.text.headingPrimary}>
                  {formatAmount(Number(paymentResponse.totalAmount), 2, 1, currency.currencyCode)}
                </Text>
                {paymentMethod === PaymentMethod.BANK && (
                  <Text color={theme.v2.text.headingPrimary}>{`. ${textTranslation('payment.inOrder')}`}</Text>
                )}
              </div>
            </LowerText>
          </TextWrapper>
          <ButtonsWrapper>
            <Button
              text={textTranslation('payment.continue')}
              onClick={() => navigate(getOfferingRouteByTransactionType(productType))}
              dataAttributes={{ dataTestid: 'success-continue-button' }}
            />
            <Button
              variant={'outlined'}
              color={'neutral'}
              text={textTranslation('payment.transactionDetails')}
              onClick={() => navigate(getSingleTransactionRouteByType(productType, transaction?.id || ''))}
              dataAttributes={{ dataTestid: ' success-transaction-details' }}
            />
          </ButtonsWrapper>
        </ContentWrapper>
      </UpperContent>
      {productType === ProductType.NFT && (
        <SuccessfullyPurchasedNft
          paymentResponse={paymentResponse}
          item={item as NftExtendedV5Response}
          productType={productType}
          paymentMethod={paymentMethod}
        />
      )}
      {productType !== ProductType.NFT && (
        <SuccessfullyPurchasedBondShare
          paymentResponse={paymentResponse}
          item={item as ShareFullResponseDTO}
          productType={productType}
          currency={currency}
        />
      )}
    </Wrapper>
  );
};

const getSingleTransactionRouteByType = (transactionType: ProductType, id: string) => {
  switch (transactionType) {
    case ProductType.ACTIONS:
      return `${routes.account.transactions.root}/${TransactionType.SHARE}/${id}`;
    case ProductType.BOND_PAPERS:
      return `${routes.account.transactions.root}/${TransactionType.BOND}/${id}`;
    case ProductType.NFT:
      return `${routes.account.transactions.root}/${TransactionType.NFT}/${id}`;
    default:
      return routes.pageNotFound;
  }
};

const getOfferingRouteByTransactionType = (transactionType: ProductType): string => {
  switch (transactionType) {
    case ProductType.ACTIONS:
      return routes.offering.smartShares.root;
    case ProductType.BOND_PAPERS:
      return routes.offering.smartBonds.root;
    case ProductType.NFT:
      return routes.offering.smartCollectibles.root;
    default:
      return routes.offering.root;
  }
};
