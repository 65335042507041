import { getFormattedTime, mapIsoStringToDate } from '@metaswiss/lib';
import {
  BellIcon,
  NotificationCard,
  NotificationFeedback,
  SpinnerLoaderItemsIcon,
  SquareIconButton,
  Text,
  TextLink,
  ThemedIcon,
  useOutsideClick,
} from '@metaswiss/ui-kit';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import debounce from 'lodash/debounce';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from 'styled-components';

import { api } from '../../api/msApi';
import { ApiResource } from '../../enums/resource.enum';
import { usePagination } from '../../hooks/use-pagination/usePagination';
import { useTextTranslation } from '../../hooks/use-text-translation/useTextTranslation';
import { getQueryKey } from '../../shared/helpers/getQueryKey.helper';

import { NotificationDetails } from './NotificationDetails';
import { NotificationErrorState } from './NotificationErrorState';
import {
  initialSelectedNotificationItemState,
  NotificationItemResponse,
  NotificationPopupProps,
  SelectedNotificationItemProps,
} from './notificationItemTypes';
import {
  NotificationButtonWrapper,
  NotificationHeader,
  NotificationIconDot,
  NotificationItemWrapper,
  NotificationLoaderContainer,
  NotificationPopupHolder,
  NotificationPopupWrapper,
  NotificationWrapper,
} from './notificationPopup.styles';

export const NotificationPopup: FC<NotificationPopupProps> = ({ isOpen, setIsOpen }) => {
  const theme = useTheme();
  const { textTranslation, currentLanguage } = useTextTranslation();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const queryClient = useQueryClient();
  const popupRef = useRef<HTMLDivElement | null>(null);
  const cardRef = useRef<HTMLDivElement | null>(null);
  const notificationPopupWrapperRef = useRef<HTMLDivElement | null>(null);
  const [wrapperWidth, setWrapperWidth] = useState<number>(0);
  const [selectedNotficationItem, setSelectedNotificationItem] = useState<SelectedNotificationItemProps>(
    initialSelectedNotificationItemState
  );

  const notificationTitleRender = (title: string) => (
    <Text fontWeight="bold" color={theme.v2.text.headingPrimary}>
      {title}
    </Text>
  );
  const notificationSubtTitleRender = (subTitle: string) => (
    <Text fontSize="sm" fontWeight="semi" lineHeight="medium" color={theme.v2.text.disabled}>
      {subTitle}
    </Text>
  );

  const { data, lastItemRef, isError, fetchNextPage, limit, isLoading } = usePagination<NotificationItemResponse>({
    queryKey: getQueryKey(ApiResource.NOTIFICATIONS),
    queryFn: (offset: number, limit: number) => {
      return api.notifications.getNotifications(offset, limit);
    },
    enabled: true,
    limit: 6,
  });

  useEffect(() => {
    queryClient.removeQueries({ queryKey: getQueryKey(ApiResource.NOTIFICATIONS) });
  }, [currentLanguage, queryClient]);

  const { mutate: markAllAsRead } = useMutation({
    mutationFn: () => {
      return api.notifications.readNotifications();
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: getQueryKey(ApiResource.NOTIFICATIONS) });
    },
  });

  const { mutate: readNotification } = useMutation({
    mutationFn: () => {
      return api.notifications.readSingleNotification(selectedNotficationItem.id);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: getQueryKey(ApiResource.NOTIFICATIONS, selectedNotficationItem.id),
      });
      await queryClient.invalidateQueries({ queryKey: getQueryKey(ApiResource.NOTIFICATIONS) });
    },
  });

  const items = useMemo(() => {
    return data ? (data as unknown as NotificationItemResponse[]) : [];
  }, [data]);

  const unreadNotifications = useMemo(() => {
    return items.some((item) => !item.isRead);
  }, [items]);

  const { createdAt, content, title } = selectedNotficationItem;
  const shouldShowNotificationDetails = () => {
    return [createdAt, content, title].some((item) => item.length);
  };

  const handleScrollToSelectedItem = () => {
    if (cardRef.current && containerRef.current) {
      const cardElement = cardRef.current?.getBoundingClientRect();
      const wrapperNotification = containerRef.current?.getBoundingClientRect();
      if (cardElement.top >= wrapperNotification.top && cardElement.bottom <= wrapperNotification.bottom) return;
      cardRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  };

  const handleNotificationCardClick = (item: NotificationItemResponse) => {
    setSelectedNotificationItem({
      ...selectedNotficationItem,
      createdAt: item.createdAt,
      content: item.content,
      title: item.title,
      id: item.id,
    });
    if (!item.isRead) {
      readNotification();
    }
  };

  const handleCloseNotifcation = (): void => {
    setIsOpen(false);
    setSelectedNotificationItem(initialSelectedNotificationItemState);
    if (containerRef.current) {
      containerRef.current.scrollTo({ top: 0, behavior: 'instant' });
    }
  };

  useOutsideClick([popupRef], () => handleCloseNotifcation());

  const handleOpenPopup = (): void => {
    setIsOpen(!isOpen);
    setSelectedNotificationItem(initialSelectedNotificationItemState);
    if (containerRef.current) {
      containerRef.current.scrollTo({ top: 0, behavior: 'instant' });
    }
  };

  useEffect(() => {
    const debouncedResizing = debounce((entries: ResizeObserverEntry[]) => {
      //Without 0.1 I cant't get full width of container during animation because there is container who is placed in middle and it doesn't allow to do that
      setWrapperWidth(entries[0].contentRect.width / 16 + 0.1);
    }, 50);

    const observer = new ResizeObserver(debouncedResizing);
    if (notificationPopupWrapperRef.current) {
      observer.observe(notificationPopupWrapperRef?.current);
    }

    return () => {
      notificationPopupWrapperRef.current && observer.unobserve(notificationPopupWrapperRef?.current);
    };
  }, []);

  useEffect(() => {
    handleScrollToSelectedItem();
  }, [selectedNotficationItem]);

  return (
    <NotificationWrapper ref={popupRef}>
      <NotificationButtonWrapper>
        {!!items.length && unreadNotifications && <NotificationIconDot />}

        <SquareIconButton
          icon={<ThemedIcon icon={BellIcon} size="medium" />}
          onClick={handleOpenPopup}
          padding="0.5rem"
          selectedButton={!!isOpen}
          dataAttribute="notifications"
        />
      </NotificationButtonWrapper>
      <NotificationPopupHolder $isOpen={isOpen} $showNotification={shouldShowNotificationDetails()}>
        <NotificationPopupWrapper $showNotification={shouldShowNotificationDetails()} ref={notificationPopupWrapperRef}>
          <NotificationHeader>
            <Text fontWeight="bold" color={theme.v2.text.headingPrimary}>
              {textTranslation('home.notifications')}
            </Text>
            {!!items.length && (
              <TextLink padding="0.25rem" onClick={() => markAllAsRead()}>
                {textTranslation('home.markAsRead')}
              </TextLink>
            )}
          </NotificationHeader>
          <NotificationItemWrapper ref={containerRef} $isScrollable={!!items.length}>
            {items.length ? (
              isOpen &&
              items.map((item, index) => {
                const isLastItem = items.length === index + 3;
                return (
                  <div key={item.id} style={{ width: '100%' }} ref={isLastItem ? lastItemRef : null}>
                    <NotificationCard
                      {...item}
                      onClick={() => {
                        if (isError) return;
                        handleNotificationCardClick(item);
                      }}
                      createdAt={mapIsoStringToDate({ date: item.createdAt })}
                      dateSize={'sm'}
                      titleWeight={'bold'}
                      titleSize={'sm'}
                      descriptionSize={'xsm'}
                      selectedItem={selectedNotficationItem.id === item.id}
                      isRead={item.isRead}
                      selectedItemRef={cardRef}
                    />
                  </div>
                );
              })
            ) : (
              <NotificationFeedback
                fill
                titleRender={notificationTitleRender}
                subTitleRender={notificationSubtTitleRender}
                title={textTranslation('home.notificationMsgTitle')}
                subTitle={textTranslation('home.notificationMsgSubTitle')}
              />
            )}
            {isLoading && items.length >= limit && (
              <NotificationLoaderContainer>
                <ThemedIcon icon={SpinnerLoaderItemsIcon} size="medium" strokeColor="hue400" palette="primary" />
              </NotificationLoaderContainer>
            )}
          </NotificationItemWrapper>
          {isError && items.length !== 0 && <NotificationErrorState action={fetchNextPage} />}
        </NotificationPopupWrapper>
        {!isError && items.length !== 0 && (
          <NotificationDetails
            slideDetails={shouldShowNotificationDetails()}
            title={title}
            content={content}
            date={getFormattedTime(createdAt)}
            wrapperWidth={+wrapperWidth.toFixed(1)}
          />
        )}
      </NotificationPopupHolder>
    </NotificationWrapper>
  );
};
