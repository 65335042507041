import { StyledChipContainer, StyledChipText, Wrapper } from './chip.styles.ts';

export type ChipProps = {
  label: string;
  status: 'primary' | 'secondary' | 'success' | 'warning' | 'error' | 'neutral' | 'disabled';
  dataAttributes?: string;
};

export const Chip = ({ label = '', status, dataAttributes }: ChipProps) => {
  return (
    <StyledChipContainer $status={status} data-testid={dataAttributes}>
      <Wrapper>
        <StyledChipText $status={status}>{label}</StyledChipText>
      </Wrapper>
    </StyledChipContainer>
  );
};
