import { regex } from 'client/src/shared/regex/regex.ts';
import { debounce } from 'lodash';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useTheme } from 'styled-components';

import {
  Button,
  FormInputNumber,
  Heading,
  Input,
  ItemWithCustomIconProps,
  Popup,
  Text,
  ThemedIcon,
} from '../../../components';
import { SearchIcon } from '../../../iconography';

import { ButtonsContainer } from './styles/buttonsContainer';
import { InputItemsContainer, InputWrapper, RegularInputContainer } from './styles/inputItemsContainer';
import { ItemLabelWrapper, ItemWrapper } from './styles/itemWrapper';
import { ItemsContainer, StyledElementContainer, StyledElementWrap, StyledIcon } from './styles/itemsContainer';
import { ItemsSelectionContainer } from './styles/itemsSelectionContainer';
import { TitleWrapper } from './styles/titleWrapper';
import { ItemProps, SelectionPopupProps } from './types';

const trimmingLength = 20;
const triggerIndex = 10;

export const SelectionPopup = ({
  title,
  error,
  selectedItem,
  onSelect,
  itemsList,
  renderInputNumber = false,
  disabled = false,
  label,
  searchText,
  closeButtonText,
  selectButtonText,
  isLocked,
  defaultItem,
  isSearchEnabled = true,
  isFullHeight = true,
  containerId,
  renderSingleItem,
  singleItemPadding,
  lastItemRef,
  isLocalSearch = true,
  onSearchChange,
  dataAttributeValue,
  ...props
}: SelectionPopupProps) => {
  const theme = useTheme();
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [activeItem, setActiveItem] = useState<ItemProps | ItemWithCustomIconProps | undefined>(undefined);
  const [trimmedItemsList, setTrimmedItemsList] = useState<(ItemProps | ItemWithCustomIconProps)[]>(
    itemsList.slice(0, trimmingLength)
  );
  const [ref, inView] = useInView({
    threshold: 0,
  });

  useEffect(() => {
    setActiveItem(selectedItem as ItemProps | ItemWithCustomIconProps);
  }, [selectedItem]);

  useEffect(() => {
    // always set trimmedItemsList to trimmingLength items when popup is closed
    // to avoid opening popup with all items, which can cause performance issues
    if (!isPopupOpen || search === '') return setTrimmedItemsList(itemsList.slice(0, trimmingLength));

    // if we are not using local search, we don't need to filter items
    if (!isLocalSearch) {
      setTrimmedItemsList(itemsList.slice(0, trimmingLength));
      return;
    }

    let updatedSearch = search;
    if (regex.startsWithPlus.test(updatedSearch) || regex.startsWithPlusAndOnlyNumbers.test(updatedSearch)) {
      updatedSearch = updatedSearch.slice(1);
    }
    const filteredItems = itemsList.filter(
      (item) =>
        (item.meta && item.meta.toLowerCase().includes(updatedSearch.toLowerCase())) ||
        item.label.toLowerCase().includes(updatedSearch.toLowerCase())
    );
    if (search !== '') return setTrimmedItemsList(filteredItems);
  }, [search, itemsList, isPopupOpen, isLocalSearch]);

  useEffect(() => {
    if (inView) {
      const newItemsList = itemsList.slice(0, trimmedItemsList.length + trimmingLength);
      setTrimmedItemsList(newItemsList);
    }
  }, [inView]);

  const isItemSelected = useMemo(
    () => itemsList.some((item) => item.value === activeItem?.value),
    [itemsList, activeItem]
  );

  const handleSelectItem = () => {
    setIsPopupOpen(false);
    onSelect(activeItem as ItemProps);
  };

  const handleInputFocus = () => {
    setIsPopupOpen(true);
    setSearch('');
    setActiveItem(selectedItem);
    onSearchChange?.('');
  };

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
    setSearch('');
    onSearchChange?.('');
  };

  const handlePopupClose = () => {
    setActiveItem(selectedItem);
    setIsPopupOpen(false);
  };

  const debounceSearch = useMemo(() => {
    return debounce((term: string) => {
      onSearchChange?.(term);
    }, 300);
  }, []);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    debounceSearch.cancel();
    setSearch(event.currentTarget.value);
    debounceSearch(event.currentTarget.value);
  };

  return (
    <>
      {!renderInputNumber ? (
        <RegularInputContainer>
          <Input
            onFocus={handleInputFocus}
            dataAttributes={{ dataTestid: dataAttributeValue?.select }}
            disabled={disabled || isPopupOpen}
            disableFocus={isPopupOpen}
            onEndIconClicked={!disabled ? handleOpenPopup : undefined}
            label={label}
            value={selectedItem?.label}
            error={error}
            isLocked={isLocked}
            {...props}
          />
        </RegularInputContainer>
      ) : (
        <FormInputNumber
          disabled={disabled}
          disableFocus={!disabled}
          openPopup={!disabled ? handleOpenPopup : undefined}
          activePrefixItem={activeItem}
          label={label}
          isLocked={isLocked}
          defaultItem={defaultItem}
          selectedItem={selectedItem}
          error={error}
          dataAttributes={{ dataTestid: dataAttributeValue?.phoneNumber }}
          {...props}
        />
      )}

      <Popup
        isActive={isPopupOpen}
        onOutsideClick={handlePopupClose}
        height={isFullHeight ? '42.5rem' : 'auto'}
        fill
        containerId={containerId}
      >
        <ItemsSelectionContainer>
          {title && (
            <TitleWrapper>
              <Heading headingType="h4" fontWeight="bold">
                {title}
              </Heading>
            </TitleWrapper>
          )}
          <InputItemsContainer>
            {isSearchEnabled && (
              <InputWrapper>
                <Input
                  value={search}
                  onChange={handleChange}
                  label={searchText}
                  disabled={disabled}
                  disableFocus={!disabled}
                  type="input"
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') event.preventDefault();
                  }}
                  renderStartIcon={() => (
                    <ThemedIcon icon={SearchIcon} customStrokeColor={theme.v2.text.headingPrimary} />
                  )}
                />
              </InputWrapper>
            )}
            <ItemsContainer>
              {trimmedItemsList.length > 0 &&
                trimmedItemsList.map((item, index) => (
                  <ItemWrapper
                    key={item.value}
                    ref={(el) => {
                      if (el && index === trimmedItemsList.length - triggerIndex && !lastItemRef) {
                        ref(el);
                      }
                      if (lastItemRef && index === trimmedItemsList.length - 1) {
                        ref(el);
                        lastItemRef(el);
                      }
                    }}
                    $isSelected={item.value === activeItem?.value}
                    type="button"
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      setActiveItem(item);
                      event.stopPropagation();
                    }}
                    $padding={singleItemPadding}
                  >
                    {renderSingleItem ? (
                      renderSingleItem(item)
                    ) : (
                      <StyledElementContainer>
                        <StyledElementWrap>
                          {item?.iconSrc && typeof item?.iconSrc === 'string' && <StyledIcon src={item.iconSrc} />}
                          {item?.iconSrc && typeof item?.iconSrc !== 'string' && (
                            <ThemedIcon icon={item?.iconSrc} customStrokeColor={theme.v2.text.headingPrimary} />
                          )}
                          <ItemLabelWrapper>
                            <Text>{item.label}</Text>
                          </ItemLabelWrapper>
                        </StyledElementWrap>
                        {renderInputNumber && item.meta && <Text>{`+${item.meta}`}</Text>}
                      </StyledElementContainer>
                    )}
                  </ItemWrapper>
                ))}
            </ItemsContainer>
          </InputItemsContainer>
          <ButtonsContainer $addPadding={true}>
            <Button
              text={closeButtonText}
              variant="outlined"
              color="neutral"
              fill
              type="button"
              onClick={handlePopupClose}
              dataAttributes={{ dataTestid: 'cancel-button' }}
            />
            <Button
              text={selectButtonText}
              fill
              type="button"
              disabled={!isItemSelected}
              onClick={handleSelectItem}
              dataAttributes={{ dataTestid: 'select-button' }}
            />
          </ButtonsContainer>
        </ItemsSelectionContainer>
      </Popup>
    </>
  );
};
