import { FormInput, ThemedIcon, Globe02Icon, FormSelectionPopup } from '@metaswiss/ui-kit';
import { useCallback, useEffect } from 'react';
import { FieldError, useFormContext } from 'react-hook-form';
import { useTheme } from 'styled-components';

import ControlledForm from '../../../../../../../../components/controlled-form/ControlledForm';
import { useCountries } from '../../../../../../../../hooks/use-countries/useCountries';
import { useTextTranslation } from '../../../../../../../../hooks/use-text-translation/useTextTranslation';
import { MainContentContainer } from '../../../../../../../../layouts/public-layout/styles/publicLayout.styles';
import { useAuthContext } from '../../../../../../shared/auth-context/authContext';
import { useSetAuthConfig } from '../../../../../../shared/auth-context/useSetAuthConfig';
import { RegisterHeader } from '../../../../../shared/register-header/RegisterHeader';
import {
  RegistrationForm,
  RegistrationGridWrapper,
  OneLineElement,
} from '../../../../../shared/styles/registrationStyles';
import { FullPrivateRegistrationData } from '../fullPrivateRegistrationState';

import {
  createFullPrivateAddressSchema,
  FullPrivateRegistrationAddressFormData,
  fullRegistrationPrivateAddressSchema,
} from './addressSchema';

export const AddressView = () => {
  const theme = useTheme();
  const { countriesAsDropdown } = useCountries();
  const { textTranslation } = useTextTranslation();
  const { setUserDetails, setIsValid, prevStep, nextStep } = useAuthContext((state) => state);

  const {
    control,
    getValues,
    formState: { errors, isValid },
    handleSubmit,
  } = useFormContext<FullPrivateRegistrationAddressFormData>();

  const setRegistrationContextState = useCallback(() => {
    const userDetails = getValues() as FullPrivateRegistrationData;
    setUserDetails(userDetails);
  }, [getValues, setUserDetails]);

  useEffect(() => {
    return () => {
      setRegistrationContextState();
    };
  }, [setRegistrationContextState]);

  useEffect(() => {
    setIsValid(isValid);
    return () => {
      setIsValid(true);
    };
  }, [isValid, setIsValid]);

  const onAddressSubmit = useCallback(() => {
    setRegistrationContextState();
    nextStep();
  }, [nextStep, setRegistrationContextState]);

  useSetAuthConfig(
    {
      wrapperStyle: 'registrationWizard',
      containerStyle: 'registrationWizard',
      backButton: {
        onClick: () => prevStep(),
        text: 'button.back',
        dataAttributes: { dataTestid: 'back-button' },
      },
      nextButton: {
        text: 'button.next',
        onClick: () => handleSubmit(onAddressSubmit)(),
        dataAttributes: { dataTestid: 'next-button' },
      },
      showStepper: true,
    },
    [handleSubmit, onAddressSubmit, prevStep]
  );

  return (
    <>
      <RegisterHeader descriptionText={textTranslation('registration.addressHeader')} />
      <RegistrationForm>
        <RegistrationGridWrapper>
          <OneLineElement>
            <FormSelectionPopup
              name={fullRegistrationPrivateAddressSchema.country}
              error={errors[fullRegistrationPrivateAddressSchema.country] as FieldError}
              control={control}
              fill
              label={textTranslation('registration.country')}
              title={textTranslation('registration.country')}
              searchText={textTranslation('registration.search')}
              renderEndIcon={() => (
                <ThemedIcon icon={Globe02Icon} palette="primary" customStrokeColor={theme.v2.icon.primary} />
              )}
              itemsList={countriesAsDropdown}
              closeButtonText={textTranslation('cancel')}
              selectButtonText={textTranslation('select')}
              containerId={MainContentContainer.styledComponentId}
              dataAttributeValue={{ select: 'country' }}
            />
          </OneLineElement>
          <OneLineElement>
            <FormInput<FullPrivateRegistrationAddressFormData>
              name={fullRegistrationPrivateAddressSchema.city}
              error={errors[fullRegistrationPrivateAddressSchema.city]}
              control={control}
              fill
              label={textTranslation('registration.city')}
              dataAttributes={{ dataTestid: 'city' }}
            />
          </OneLineElement>
          <OneLineElement>
            <FormInput<FullPrivateRegistrationAddressFormData>
              name={fullRegistrationPrivateAddressSchema.address}
              error={errors[fullRegistrationPrivateAddressSchema.address]}
              control={control}
              fill
              label={textTranslation('global.address')}
              dataAttributes={{ dataTestid: 'address' }}
            />
          </OneLineElement>
          <OneLineElement>
            <FormInput<FullPrivateRegistrationAddressFormData>
              name={fullRegistrationPrivateAddressSchema.zipCode}
              error={errors[fullRegistrationPrivateAddressSchema.zipCode]}
              control={control}
              fill
              label={textTranslation('registration.zipCode')}
              dataAttributes={{ dataTestid: 'zip-code' }}
            />
          </OneLineElement>
        </RegistrationGridWrapper>
      </RegistrationForm>
    </>
  );
};

export const Address = () => {
  const userDetails = useAuthContext((state) => state.userDetails);

  return (
    <ControlledForm validationSchema={createFullPrivateAddressSchema} defaultValues={userDetails}>
      <AddressView />
    </ControlledForm>
  );
};
