import { getFirstSixDigitsOfSerialNumber, mapDate } from '@metaswiss/lib';
import { BankIcon, CollectibleIcon, PageStateContainer, TagIcon, ThemedIcon } from '@metaswiss/ui-kit';
import { ProductType } from '@metaswiss/ui-kit/src/components/molecules/cards/transaction-card/enums';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { api } from '../../../../../../api/msApi';
import { AssetDetails } from '../../../../../../components/asset-details/AssetDetails';
import { ItemProps } from '../../../../../../components/asset-details/types';
import { AssetType } from '../../../../../../enums/assetType';
import { ApiResource } from '../../../../../../enums/resource.enum';
import { useTextTranslation } from '../../../../../../hooks/use-text-translation/useTextTranslation';
import { routes } from '../../../../../../router/routes';
import { getNftIdCode } from '../../../../../../shared/helpers/getNftIdCode';
import { getQueryKey } from '../../../../../../shared/helpers/getQueryKey.helper';
import { LoadingError } from '../components/loading-error/LoadingError';

export const SingleNft = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { textTranslation } = useTextTranslation();
  const { id: nftTransactionId } = useParams();

  const {
    data: nftTransactionData,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: getQueryKey(ApiResource.SINGLE_TRANSACTION, nftTransactionId),
    queryFn: () => {
      return api.clientTransaction.getClientTransactionById(nftTransactionId as string);
    },
    onError: (error) => {
      const response = error as Response;

      //TODO - test this condition once the backend is updated with 404 status code
      if (response.status === 404) navigate(routes.pageNotFound);
    },
    enabled: !!nftTransactionId,
  });

  const nftItems: ItemProps[] = useMemo(
    () => [
      {
        icon: <ThemedIcon icon={CollectibleIcon} customStrokeColor={theme.v2.icon.primary} />,
        subText: textTranslation('global.collectibleId'),
        text: getNftIdCode(nftTransactionData?.orderItem?.code ?? ''),
      },
      {
        icon: <ThemedIcon icon={TagIcon} customStrokeColor={theme.v2.icon.primary} />,
        subText: textTranslation('account.serialNumber'),
        text: nftTransactionData?.orderItem?.nftSerialNumber
          ? getFirstSixDigitsOfSerialNumber(nftTransactionData?.orderItem?.nftSerialNumber)
          : '',
      },
      {
        icon: <ThemedIcon icon={BankIcon} customStrokeColor={theme.v2.icon.primary} />,
        subText: textTranslation('global.paymentMethod'),
        text: textTranslation(`global.${nftTransactionData?.paymentType?.toLowerCase()}`),
      },
    ],
    [nftTransactionData, theme]
  );

  if (isError || isLoading || !nftTransactionData) {
    return <LoadingError isLoading={isLoading} isError={isError} data={nftTransactionData} refetch={refetch} />;
  }

  return (
    <PageStateContainer
      showLoading={true}
      isLoading={isLoading}
      showError={true}
      isError={isError || !nftTransactionData}
      onTryAgain={refetch}
      textTranslation={textTranslation}
      showEmptyState={false}
    >
      <AssetDetails
        heading={textTranslation('account.smartCollectibleHeading')}
        amount={nftTransactionData?.totalConvertedAmount}
        date={mapDate(nftTransactionData?.createdAt)}
        items={nftItems}
        documents={nftTransactionData?.invoices}
        assetType={AssetType.TRANSACTIONS}
        transactionStatus={nftTransactionData.status}
        productName={ProductType.NFT}
      />
    </PageStateContainer>
  );
};
