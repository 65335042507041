import { Text, ThemedIcon, IconProps } from '@metaswiss/ui-kit';
import { FC, useMemo } from 'react';
import { useTheme } from 'styled-components';

import { Card, ContactWrapper } from '../supportModal.styles';

type Props = {
  icon: FC<IconProps>;
  contactInfo: string[];
  onClick: () => void;
  dataAttribute?: string;
};

export const ContactCard: FC<Props> = ({ icon, contactInfo, onClick, dataAttribute }) => {
  const theme = useTheme();
  const renderIcon = useMemo(
    () => <ThemedIcon icon={icon} size={'large'} customStrokeColor={theme.v2.icon.primary} />,
    [icon, theme.v2.icon.primary]
  );

  return (
    <Card onClick={onClick} disableStateEffects={true} width={'15.5rem'} height={'15.5rem'}>
      <ContactWrapper data-testid={dataAttribute}>
        {renderIcon}
        {contactInfo.map((info) => (
          <Text key={info} fontWeight={'semi'} color={theme.v2.text.headingPrimary}>
            {info}
          </Text>
        ))}
      </ContactWrapper>
    </Card>
  );
};
