import { BarChartNoBorderIcon, BaseCard, Button, ResourceDetails, TrendUpIcon } from '@metaswiss/ui-kit';
import { TabsWrapper } from '@metaswiss/ui-kit/src/components/molecules/tabs-container/tabsContainer.styles';
import { ShellContainer } from '@metaswiss/ui-kit/src/components/organism/page-shell/pageShell.styles';
import { PageStateContainerWrapper } from '@metaswiss/ui-kit/src/components/organism/page-state-container/pageStateContainer.styles';
import { FC, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTheme } from 'styled-components';

import { useTextTranslation } from '../../hooks/use-text-translation/useTextTranslation';
import { InfoItem } from '../product-info/ProductInfo';

import {
  ButtonDiv,
  ContentWrapper,
  MainCardWrapper,
  PriceIconsWrapper,
  PriceWrapper,
  StickyPriceCardWrapper,
  StyledResourceCard,
} from './productPrice.styles';

type Props = {
  formatValue: string;
  type: string;
  items: InfoItem[];
  handleInvestButtonClick: () => void;
  isShares: boolean;
  disableSticky?: boolean;
};
export const ProductPrice: FC<Props> = ({
  formatValue,
  type,
  handleInvestButtonClick,
  items,
  isShares,
  disableSticky,
}) => {
  const theme = useTheme();
  const [isSticky, setIsSticky] = useState(false);
  const [headerOffset, setHeaderOffset] = useState(0);
  const { textTranslation } = useTextTranslation();

  const selectedColor = isShares ? 'primary' : 'secondary';
  const tabsWrapper = document.querySelector(`.${TabsWrapper.styledComponentId}`);
  const pageShellWrapper = document.querySelector(`.${ShellContainer.styledComponentId}`);
  const headerWrapper = tabsWrapper ?? pageShellWrapper;

  useEffect(() => {
    if (disableSticky) {
      return;
    }
    const scrollContainer = document.querySelector(`.${PageStateContainerWrapper.styledComponentId}`);
    let offset = 0;

    if (scrollContainer && scrollContainer.children.length > 0) {
      const styles = getComputedStyle(scrollContainer.children[0]);
      offset = parseInt(styles.marginTop, 10);
    }

    const handleScrollEvent = () => {
      const scrollTop = scrollContainer?.scrollTop ?? 0;
      setIsSticky(scrollTop >= offset);
    };

    scrollContainer?.addEventListener('scroll', handleScrollEvent);

    if (tabsWrapper) {
      setHeaderOffset(tabsWrapper.clientHeight);
    }

    return () => {
      scrollContainer?.removeEventListener('scroll', handleScrollEvent);
    };
  }, [headerWrapper, tabsWrapper]);

  const priceCardContent = () => {
    return (
      <PriceWrapper>
        <MainCardWrapper>
          <ResourceDetails
            size="lg"
            icon={isShares ? BarChartNoBorderIcon : TrendUpIcon}
            iconColor={isShares ? theme.v2.icon.primary : theme.v2.icon.secondary}
            iconWrapperColor={isShares ? theme.v2.surface.informationLight : theme.v2.surface.action2Light}
            subTitle={textTranslation(type)}
            title={formatValue}
            sizeWrapper="3.5rem"
            gap="1.5rem"
            hasWrapper={true}
          />
        </MainCardWrapper>

        <ButtonDiv>
          <Button
            color={selectedColor}
            text={textTranslation('offering.investNow')}
            onClick={handleInvestButtonClick}
            fill
            dataAttributes={{
              dataTestid: `${isShares ? 'share' : 'bond'}-buy-button`,
            }}
          />
        </ButtonDiv>
      </PriceWrapper>
    );
  };

  return (
    <>
      <BaseCard
        padding="1.5rem"
        height="fit-content"
        disableStateEffects
        override={{
          borderRadius: theme.v2.componentsRadius.cards.default,
          backgroundColor: theme.v2.surface.primary,
          borderColor: theme.v2.border.primary,
        }}
      >
        {priceCardContent()}
      </BaseCard>

      {isSticky &&
        headerWrapper &&
        createPortal(
          <StickyPriceCardWrapper $headerOffset={headerOffset}>
            <ContentWrapper>
              <BaseCard
                padding="1.5rem"
                height="fit-content"
                disableStateEffects
                removeBorder
                override={{
                  borderRadius: theme.v2.componentsRadius.cards.default,
                  backgroundColor: theme.v2.surface.primary,
                }}
              >
                {priceCardContent()}
              </BaseCard>
            </ContentWrapper>
          </StickyPriceCardWrapper>,
          headerWrapper
        )}

      <PriceIconsWrapper>
        {items?.map((item) => {
          return (
            <StyledResourceCard
              key={item.text}
              padding="1.25rem 1.50rem"
              height="fit-content"
              disableStateEffects
              dataAttributes={{
                dataTestid: `${isShares ? 'share' : 'bond'}-${textTranslation(item.text)
                  .toLocaleLowerCase()
                  .replace(/\(months\)|\breturn\b/gi, '')
                  .replace(/\s+/g, ' ')
                  .trim()}`,
              }}
              override={{
                borderRadius: theme.v2.componentsRadius.cards.default,
                backgroundColor: theme.v2.surface.primary,
                borderColor: theme.v2.border.primary,
              }}
            >
              <ResourceDetails
                icon={item.icon}
                subTitle={textTranslation(item.text)}
                title={item.value}
                subPropertyName={item.subText}
                subPropertyColor={item.subColor}
                iconColor={item.iconColor}
                gap="1.25rem"
                size="md"
              />
            </StyledResourceCard>
          );
        })}
      </PriceIconsWrapper>
    </>
  );
};
