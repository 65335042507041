import { convertToDataAttributes } from '@metaswiss/lib';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';

import { Heading, RadioButton, Text, ThemedIcon } from '../../../components';
import { LabelOption } from '../../atoms/radio-button/RadioButton';

import { BodyContainer } from './styles/bodyContainer';
import { BodyContextWrapper } from './styles/bodyContextWrapper';
import { CardContainer, TooltipContent, TooltipHolder } from './styles/cardContainer';
import { CardHeader } from './styles/cardHeader';
import { ContentWrapper, IconWrapper, TextWrapper } from './styles/paymentCard';
import { CardSizeProp, SelectableCardProps } from './types';

const label: LabelOption = { id: '1', value: '' };

export const BaseSelectableCard = ({
  title,
  size = 'base',
  children,
  isSelected = true,
  isExpandedDefault = true,
  onSelect,
  isPaymentCard = false,
  paymentInfo,
  padding,
  preventClosedCard,
  radioButtonSize,
  renderTitleComponent,
  isDisabled,
  tooltipText,
  dataAttributes,
}: SelectableCardProps) => {
  const theme = useTheme();
  const cardWidth = useMemo(() => getCardWidth(size), [size]);

  const [value, setValue] = useState<LabelOption | undefined>(undefined);

  const toggleRadioButton = useCallback(() => {
    setValue(() => (isSelected ? label : undefined));
  }, [isSelected]);

  useEffect(() => {
    toggleRadioButton();
  }, [isSelected, toggleRadioButton]);

  return (
    <CardContainer
      $size={cardWidth}
      $isSelected={isSelected}
      onClick={onSelect}
      $padding={padding}
      $isPaymentCard={isPaymentCard}
      $isDisabled={isDisabled}
      {...convertToDataAttributes(dataAttributes)}
    >
      <CardHeader>
        {!isPaymentCard
          ? renderTitleComponent || (
              <Heading
                headingType="h4"
                color={isSelected ? theme.v2.text.action : theme.v2.text.headingPrimary}
                fontWeight="bold"
              >
                {title ?? ''}
              </Heading>
            )
          : paymentInfo && (
              <ContentWrapper>
                <IconWrapper $isSelected={isSelected}>
                  <ThemedIcon icon={paymentInfo.icon} customStrokeColor={theme.v2.icon.default} />
                </IconWrapper>
                <TextWrapper>
                  <Text fontWeight="semi" color={theme.v2.text.headingPrimary}>
                    {paymentInfo?.paymentTypeText}
                  </Text>
                  <Text fontSize="xsm" color={theme.v2.text.headingPrimary} lineHeight="extraSmall">
                    {paymentInfo.description}
                  </Text>
                </TextWrapper>
              </ContentWrapper>
            )}
        <RadioButton
          size={radioButtonSize}
          selected={value}
          onSelect={() => event?.preventDefault()}
          label={label}
          disabled={isDisabled}
        />
      </CardHeader>
      <BodyContainer $isSelected={isSelected} $isExpandedDefault={isExpandedDefault} onClick={preventClosedCard}>
        <BodyContextWrapper $size={size}>
          <div>{children}</div>
        </BodyContextWrapper>
      </BodyContainer>
      {tooltipText && (
        <TooltipHolder>
          <TooltipContent>
            <Text fontSize={'xsm'} lineHeight={'extraSmall'} color={theme.v2.text.headingPrimary}>
              {tooltipText}
            </Text>
          </TooltipContent>
        </TooltipHolder>
      )}
    </CardContainer>
  );
};

function getCardWidth(size: CardSizeProp) {
  let cardWidth: string;

  switch (size) {
    case 'small':
      cardWidth = '18.25rem';
      break;
    case 'full':
      cardWidth = '100%';
      break;
    case 'base':
      cardWidth = '37.5rem';
      break;
    default:
      throw new Error('Unsupported card size');
  }

  return cardWidth;
}
