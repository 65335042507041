import { BaseSelectableCard } from '../../selectable-card/BaseSelectableCard';

import { ExpandablePaymentCardProps } from './types';

export const ExpandablePaymentCard = ({
  size = 'base',
  isSelected,
  onSelect,
  children,
  description,
  paymentTypeText,
  height,
  icon,
  dataAttributes = 'payment-newaddress-button',
}: ExpandablePaymentCardProps) => {
  return (
    <BaseSelectableCard
      height={height}
      size={size}
      padding="1.25rem"
      isSelected={isSelected}
      onSelect={onSelect}
      isExpandedDefault={false}
      isPaymentCard={true}
      dataAttributes={{ dataTestid: dataAttributes }}
      preventClosedCard={(e) => e.stopPropagation()}
      paymentInfo={{
        paymentTypeText: paymentTypeText,
        description: description,
        icon: icon,
      }}
    >
      {children}
    </BaseSelectableCard>
  );
};
