import { FC, useState } from 'react';
import { useTheme } from 'styled-components';

import { CloseIcon } from '../../../iconography';
import { CartIcon } from '../../../iconography/CartIcon';
import { HearthIcon } from '../../../iconography/HearthIcon';
import { Button } from '../../atoms/button';
import { LocalSpinner } from '../../atoms/local-spinner';
import { Text } from '../../atoms/text';
import { ThemedIcon } from '../theme-icon';

import {
  BuyButtonContainer,
  BuyContainer,
  BuyIconWrapper,
  IconWrapper,
  ImageWrapper,
  NftErrorWrapper,
  NftHolderBaseCard,
  NftHolderContent,
  NftImage,
  PriceWrapper,
  SerialNumberWrapper,
  StyledTextContainer,
  VectorDiv,
  VectorIconWrapper,
  WrapperHoverButton,
} from './styles/nftHolder.styles';
import { ErrorStatus, NftHolderProps } from './types/nftHolder.type';

export const NftHolder: FC<NftHolderProps> = ({
  serialNumber,
  price,
  priceText,
  imageUrl,
  isFavouriteFeatureAvailable = false,
  isFavouriteSelected = false,
  onFavouriteIconClick,
  buyText = '',
  onBuyClick,
  onClick,
  collectionName,
  children,
  isLoading,
  isError,
  onDelete,
  showError = false,
  deleteTitle,
  displayRemoveButtonHover,
  errorStatus,
}) => {
  const theme = useTheme();
  //It should be used in V3 design version
  const [isShownBuyButton] = useState<boolean>(false);

  const getErrorStatusStyles = (status: ErrorStatus = 'none') => {
    const styles = {
      duplicate: theme.v2.text.warning,
      invalid: theme.v2.text.error,
      none: theme.v2.border.primary,
    };

    return styles[status] || styles.none;
  };

  const renderContent = () => {
    if (isLoading) {
      return <LocalSpinner isActive />;
    }

    if (showError && isError && errorStatus === 'duplicate') {
      return (
        <>
          <NftImage src={imageUrl} />
          <NftErrorWrapper $isAbsolute={true}>
            <Button
              variant="contained"
              borderRadius={'small'}
              size={'small'}
              text={deleteTitle}
              color="warning"
              renderEndIcon={() => <ThemedIcon icon={CloseIcon} customStrokeColor={theme.v2.text.onAction} />}
              onClick={onDelete}
            />
          </NftErrorWrapper>
        </>
      );
    }

    if (showError && isError) {
      return (
        <NftErrorWrapper>
          <Button
            variant="contained"
            borderRadius={'small'}
            size={'small'}
            text={deleteTitle}
            color={'error'}
            renderEndIcon={() => <ThemedIcon icon={CloseIcon} customStrokeColor={theme.v2.text.onAction} />}
            onClick={onDelete}
          />
        </NftErrorWrapper>
      );
    }

    return <NftImage src={imageUrl} />;
  };

  return (
    <NftHolderBaseCard
      padding={`0.5rem`}
      disableStateEffects
      onClick={onClick}
      $displayRemoveButton={displayRemoveButtonHover}
      dataAttributes={{ dataTestid: `collection-singlecollectible` }}
      override={{
        borderRadius: theme.v2.componentsRadius.cards.large,
        backgroundColor: theme.v2.surface.primary,
        borderColor: getErrorStatusStyles(errorStatus),
      }}
    >
      <NftHolderContent>
        {children}
        {isFavouriteFeatureAvailable && (
          <IconWrapper onClick={onFavouriteIconClick}>
            <HearthIcon color={isFavouriteSelected ? 'white' : 'none'} />
          </IconWrapper>
        )}
        <ImageWrapper>
          {!isError && (
            <WrapperHoverButton>
              <Button
                variant="contained"
                borderRadius={'small'}
                size={'small'}
                text={deleteTitle}
                color="error"
                renderEndIcon={() => <ThemedIcon icon={CloseIcon} customStrokeColor={theme.v2.text.onAction} />}
                onClick={onDelete}
              />
            </WrapperHoverButton>
          )}

          {renderContent()}
        </ImageWrapper>
        {!isShownBuyButton && (
          <StyledTextContainer $isCollectionNameIncluded={!!collectionName}>
            {collectionName && (
              <Text color={theme.v2.text.action} fontSize={'xsm'} lineHeight={'extraSmall'} fontWeight={'bold'}>
                {collectionName}
              </Text>
            )}
            <SerialNumberWrapper>
              <Text
                fontWeight={'semi'}
                fontSize={'base'}
                lineHeight={'base'}
                color={theme.v2.text.headingPrimary}
              >{`#${serialNumber}`}</Text>
              <Text fontWeight={'semi'} fontSize={'sm'} lineHeight={'medium'} color={theme.v2.text.disabled}>
                {priceText}
              </Text>
            </SerialNumberWrapper>
            <PriceWrapper>
              <Text fontWeight={'bold'} fontSize={'base'} lineHeight={'base'} color={theme.v2.text.headingPrimary}>
                {price}
              </Text>
            </PriceWrapper>
          </StyledTextContainer>
        )}
        {isShownBuyButton && (
          <BuyContainer onClick={onBuyClick}>
            <BuyButtonContainer>
              <Text fontWeight={'bold'} color={theme.v2.text.onAction}>
                {buyText}
              </Text>
              <VectorIconWrapper>
                <VectorDiv />
                <BuyIconWrapper>
                  <CartIcon />
                </BuyIconWrapper>
              </VectorIconWrapper>
            </BuyButtonContainer>
          </BuyContainer>
        )}
      </NftHolderContent>
    </NftHolderBaseCard>
  );
};
