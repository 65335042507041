/* eslint-disable @typescript-eslint/no-empty-function */
import {
  Button,
  ErrorFeedback,
  FormInput,
  HeaderCard,
  Heading,
  PageStateContainer,
  Popup,
  SuccessFeedback,
  Text,
  ToggleButton,
} from '@metaswiss/ui-kit';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { FieldError, Path, useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { api } from '../../../../../api/msApi.ts';
import ControlledForm from '../../../../../components/controlled-form/ControlledForm.tsx';
import { PasswordValidationInputs } from '../../../../../components/passwords-validation-inputs/PasswordsValidationInputs.tsx';
import { AppState, useAppState } from '../../../../../global-state/zustand.ts';
import { useTextTranslation } from '../../../../../hooks/use-text-translation/useTextTranslation.ts';
import { routes } from '../../../../../router/routes.ts';
import { SuccessSubTitleContainer } from '../../../../public/register/register.styles.ts';

import {
  AccManagementInfo,
  AccManagementWrapper,
  AccountInfoWrapper,
  ButtonsWrapper,
  EmailInfo,
  InputsWrapper,
  TitleWrapper,
} from './accountManagement.styles.ts';
import {
  ChangePasswordFormData,
  changePasswordSchemaNames,
  createChangePasswordSchema,
} from './accountManagementSchema.ts';

export const AccountManagementOverviewView = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const user = useAppState((state: AppState) => state.user);

  const { textTranslation } = useTextTranslation();

  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState<boolean>(false);
  const [doPasswordsMatch, setDoPasswordsMatch] = useState<boolean>(true);
  const [feedbackModalState, setFeedbackModalState] = useState<boolean>(false);
  const [openedModal, setOpenedModal] = useState<boolean>(true);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty },
  } = useFormContext<ChangePasswordFormData>();

  const { mutate, isLoading } = useMutation({
    mutationFn: (data: ChangePasswordFormData) => {
      return api.auth.changePassword({
        currentPassword: data.currentPassword,
        newPassword: data.newPassword,
        passwordConfirmation: data.passwordConfirmation,
      });
    },
    onError: () => {
      setFeedbackModalState(false);
      setOpenedModal(false);
    },
    onSuccess: () => {
      setFeedbackModalState(true);
      setOpenedModal(false);
      reset();
    },
  });

  const onSubmit = (data: ChangePasswordFormData) => {
    mutate(data);
    setIsChangePasswordModalOpen(true);
  };

  const onCancelHandler = () => {
    setIsChangePasswordModalOpen(false);
    reset({ currentPassword: '', newPassword: '', passwordConfirmation: '' });
  };

  const onClickTryAgainHandler = () => {
    setOpenedModal(true);
    setIsChangePasswordModalOpen(true);
    reset();
  };

  const onClickChangePasswordHandler = () => {
    setIsChangePasswordModalOpen(true);
    setOpenedModal(true);
    setFeedbackModalState(false);
  };

  const onClickContinueHandler = () => {
    setOpenedModal(false);
    setIsChangePasswordModalOpen(false);
  };

  const onClickConfirmHandler = () => {
    setIsChangePasswordModalOpen(true);
    setOpenedModal(true);
  };

  const handleDeleteAccountClick = () => {
    navigate(routes.account.accountManagement.deleteAccount);
  };

  return (
    <>
      <PageStateContainer
        maxWidth="44.5rem"
        showLoading={true}
        isLoading={!user}
        showEmptyState={false}
        showError={false}
        textTranslation={textTranslation}
      >
        <Popup
          onOutsideClick={isDirty ? () => {} : onCancelHandler}
          isActive={isChangePasswordModalOpen}
          height={'27.25rem'}
          width={openedModal ? '28rem' : '35rem'}
          fill={openedModal}
        >
          {openedModal ? (
            <>
              <TitleWrapper>
                <Heading fontWeight={'bold'} headingType={'h4'} color={theme.v2.text.headingPrimary}>
                  {textTranslation('account.changePassword')}
                </Heading>
              </TitleWrapper>
              <form onSubmit={handleSubmit(onSubmit)}>
                <InputsWrapper>
                  <FormInput<ChangePasswordFormData>
                    name={changePasswordSchemaNames.currentPassword as Path<ChangePasswordFormData>}
                    control={control}
                    fill
                    error={errors[changePasswordSchemaNames.currentPassword] as FieldError}
                    type={'password'}
                    label={textTranslation('account.currentPassword')}
                    disabled={isLoading}
                    dataAttributes={{ dataTestid: 'accmngmnt-current-password' }}
                  />
                  <PasswordValidationInputs
                    control={control}
                    passwordName={changePasswordSchemaNames.newPassword as Path<ChangePasswordFormData>}
                    passwordConfirmationName={
                      changePasswordSchemaNames.passwordConfirmation as Path<ChangePasswordFormData>
                    }
                    passwordError={errors[changePasswordSchemaNames.newPassword]}
                    passwordConfirmationError={errors[changePasswordSchemaNames.passwordConfirmation]}
                    passwordLabel={textTranslation('account.newPassword')}
                    passwordConfirmationLabel={textTranslation('account.confirmPassword')}
                    doPasswordsMatch={doPasswordsMatch}
                    setDoPasswordsMatch={setDoPasswordsMatch}
                    disabled={isLoading}
                    dataAttributes={{
                      password: 'accmngmnt-newpass-input',
                      confirmPassword: 'accmngmnt-confpass-input',
                    }}
                  />
                </InputsWrapper>
                <ButtonsWrapper>
                  <Button
                    type="button"
                    color="neutral"
                    variant="outlined"
                    onClick={onCancelHandler}
                    text={textTranslation('account.cancel')}
                    fill
                    dataAttributes={{ dataTestid: 'accmngmnt-cancel-button' }}
                  />
                  <Button
                    onClick={onClickConfirmHandler}
                    text={textTranslation('account.confirm')}
                    fill
                    disabled={!isValid || !doPasswordsMatch}
                    loading={isLoading}
                    dataAttributes={{ dataTestid: 'accmngmnt-confirm-button' }}
                  />
                </ButtonsWrapper>
              </form>
            </>
          ) : (
            <AccManagementWrapper>
              {feedbackModalState && (
                <>
                  <SuccessFeedback
                    textWidth={'25rem'}
                    imageWidth={'16.5rem'}
                    fill
                    title={textTranslation('changePasswordModal.success')}
                    subTitleRender={(subtitle) => (
                      <SuccessSubTitleContainer>
                        <Text palette="primary" fontWeight="reg">
                          {subtitle}
                        </Text>
                      </SuccessSubTitleContainer>
                    )}
                    subTitle={textTranslation('changePasswordModal.successSubtitle')}
                  />
                  <Button
                    onClick={onClickContinueHandler}
                    text={textTranslation('changePasswordModal.continue')}
                    fill
                  />
                </>
              )}
              {!feedbackModalState && (
                <>
                  <ErrorFeedback
                    textWidth={'25rem'}
                    imageWidth={'15rem'}
                    fill
                    title={textTranslation('changePasswordModal.error')}
                    subTitleRender={(subtitle) => (
                      <SuccessSubTitleContainer>
                        <Text palette="primary" fontWeight="reg">
                          {subtitle}
                        </Text>
                      </SuccessSubTitleContainer>
                    )}
                    subTitle={textTranslation('changePasswordModal.errorSubtitle')}
                  />
                  <Button
                    onClick={onClickTryAgainHandler}
                    text={textTranslation('changePasswordModal.tryAgain')}
                    fill
                  />
                </>
              )}
            </AccManagementWrapper>
          )}
        </Popup>

        <HeaderCard
          header={
            <Text fontWeight="bold" color={theme.v2.text.headingPrimary}>
              {textTranslation('account.accountManagement')}
            </Text>
          }
          removeBackground={false}
        >
          <AccManagementInfo>
            <AccountInfoWrapper>
              <EmailInfo>
                <Text fontSize="xsm" lineHeight="extraSmall" color={theme.v2.text.bodyPrimary}>
                  {textTranslation('account.email')}
                </Text>
                <Text
                  fontWeight={'bold'}
                  color={theme.v2.text.headingPrimary}
                  dataAttributes={{ dataTestid: 'accmngmnt-email' }}
                >
                  {user?.email || ''}
                </Text>
              </EmailInfo>
              <ToggleButton text={textTranslation('account.additionalOptions')}>
                <Button
                  variant={'outlined'}
                  size={'small'}
                  color={'neutral'}
                  text={textTranslation('account.deleteAccount')}
                  onClick={handleDeleteAccountClick}
                />
              </ToggleButton>
            </AccountInfoWrapper>
            <Button
              onClick={onClickChangePasswordHandler}
              text={textTranslation('account.changePassword')}
              dataAttributes={{ dataTestid: 'accmngmnt-changepass-button' }}
              fill
            />
          </AccManagementInfo>
        </HeaderCard>
      </PageStateContainer>
    </>
  );
};

export const AccountManagementOveriew = () => {
  return (
    <ControlledForm validationSchema={createChangePasswordSchema}>
      <AccountManagementOverviewView />
    </ControlledForm>
  );
};
