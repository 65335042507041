import { routes } from 'client/src/router/routes';
import React from 'react';

import { SidebarItem } from '../../..';
import { SidebarItemProps } from '../../atoms/sidebar-item/SidebarItem';

import {
  SidebarFooter,
  SidebarHeader,
  StyledIconDesktop,
  StyledIconLink,
  StyledIconMobile,
  StyledItem,
  StyledMenu,
  StyledSidebar,
  StyledWrapper,
} from './sideBar.styles';

type SidebarItems = Omit<SidebarItemProps, 'isSelected'>[];

export type PositionsBar = 'absolute' | 'fixed';

export type SideBarProps = {
  items: SidebarItems;
  selectedItem: string | undefined;
  desktopIcon?: string;
  mobileIcon?: string;
  position: PositionsBar;
};

export const SideBar = ({ items, desktopIcon, mobileIcon, selectedItem, position }: SideBarProps) => {
  return (
    <StyledSidebar $position={position}>
      <StyledWrapper>
        <SidebarHeader>
          <StyledIconLink to={routes.home.root}>
            <StyledIconDesktop src={desktopIcon} />
            <StyledIconMobile src={mobileIcon} />
          </StyledIconLink>
        </SidebarHeader>
        <StyledMenu>
          <SideBarItems items={items} selectedItem={selectedItem} />
        </StyledMenu>
      </StyledWrapper>
      <SidebarFooter></SidebarFooter>
    </StyledSidebar>
  );
};

export const SideBarItems: React.FC<{ items: SidebarItems; selectedItem: string | undefined }> = ({
  items,
  selectedItem,
}) => {
  return (
    <>
      {items.map(({ ...props }) => (
        <StyledItem key={props.label} data-testid={`${props.label.toLowerCase()}-tab`}>
          <SidebarItem {...props} isSelected={selectedItem === props.value} />
        </StyledItem>
      ))}
    </>
  );
};
