import { SelectableCard, Text, ThemedIcon } from '@metaswiss/ui-kit';
import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { RegistrationLength } from '../../../../../enums/registrationLength';
import { UserRole } from '../../../../../enums/userRole';
import { useTextTranslation } from '../../../../../hooks/use-text-translation/useTextTranslation';
import { routes } from '../../../../../router/routes';
import { AnimationContainer } from '../../../shared/animations/AnimationContainer';
import { useAuthContext } from '../../../shared/auth-context/authContext';
import { useSetAuthConfig } from '../../../shared/auth-context/useSetAuthConfig';
import { getWizardStepsHelper } from '../../shared/helpers/getWizardSteps.helper';
import { RegisterHeader } from '../../shared/register-header/RegisterHeader';
import {
  registrationSecondStepCorporate,
  registrationSecondStepPrivate,
} from '../../shared/registration-steps-data/registrationTypeData';

import {
  SelectableCardContainer,
  SelectableCardRow,
  SelectableCardText,
  SelectableCardWrapper,
} from './setRegistrationType.styles';

export const SetRegistrationType = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {
    setWizardSteps,
    setMaxRegistrationStep,
    setRegistrationLength,
    registrationLength,
    userRole,
    clearRegistrationContext,
    previouslySelectedRegistrationFlow,
    setPreviouslySelectedRegistrationFlow,
  } = useAuthContext((state) => state);
  const { textTranslation } = useTextTranslation();
  const { userType } = useParams();
  const { pathname: locationPath } = useLocation();

  const registrationSecondStepData = useMemo(() => {
    return userType === UserRole.CORPORATE ? registrationSecondStepCorporate : registrationSecondStepPrivate;
  }, [userType]);

  const updateRegistrationFlow = (newRegistrationLength: RegistrationLength) => {
    setRegistrationLength(newRegistrationLength);
  };

  useEffect(() => {
    const steps = getWizardStepsHelper(`${locationPath}/${registrationLength}`, () => navigate(routes.pageNotFound));
    setWizardSteps(steps);
    setMaxRegistrationStep(steps.length);
  }, [locationPath, registrationLength, setMaxRegistrationStep, setWizardSteps, navigate]);

  useSetAuthConfig(
    {
      wrapperStyle: 'registration',
      containerStyle: 'registration',
      backButton: {
        text: 'button.back',
        dataAttributes: {
          dataTestid: 'back-button',
        },
        onClick: () => navigate(routes.register.root, { replace: true }),
      },
      nextButton: {
        text: 'button.next',
        dataAttributes: {
          dataTestid: 'next-button',
        },
        type: 'button',
        onClick: () => {
          setPreviouslySelectedRegistrationFlow(userRole, registrationLength);
          if (
            userRole !== previouslySelectedRegistrationFlow.userRole ||
            registrationLength !== previouslySelectedRegistrationFlow.registrationLength
          ) {
            clearRegistrationContext();
          }
          navigate(`${routes.register.root}/${userRole}/${registrationLength}`);
        },
      },
    },
    [navigate, registrationLength, userRole]
  );

  return (
    <AnimationContainer>
      <RegisterHeader descriptionText={textTranslation('registration.secondSubtitle')} />
      <SelectableCardWrapper>
        {registrationSecondStepData.map(({ title, info }) => (
          <SelectableCard
            key={textTranslation(`registration.${title}`)}
            title={textTranslation(`registration.${title}`)}
            dataAttributes={{ dataTestid: `${title.toLowerCase()}-button` }}
            children={info.map(({ option, label, icon, hasClose }, index) => (
              <SelectableCardContainer key={index}>
                <SelectableCardRow>
                  <SelectableCardText>
                    <Text fontWeight="bold" fontSize="sm" lineHeight={'medium'}>
                      {textTranslation(option)}
                    </Text>
                    <Text fontSize="sm" lineHeight={'medium'}>
                      {textTranslation(label)}
                    </Text>
                  </SelectableCardText>
                  <ThemedIcon
                    icon={icon}
                    size={'large'}
                    customStrokeColor={!hasClose ? theme.v2.icon.primary : theme.v2.icon.error}
                  />
                </SelectableCardRow>
              </SelectableCardContainer>
            ))}
            isSelected={registrationLength === title}
            onSelect={() => updateRegistrationFlow(title)}
            radioButtonSize={'large'}
            size={'small'}
          />
        ))}
      </SelectableCardWrapper>
    </AnimationContainer>
  );
};
