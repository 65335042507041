import { BaseModal, EmailBaseIcon, HelpIcon, PhoneIcon, Text, ThemedIcon } from '@metaswiss/ui-kit';
import { FC, useMemo } from 'react';
import { useTheme } from 'styled-components';

import { useTenantConfig } from '../../hooks/use-tenant-config/useTenantConfig';
import { useTextTranslation } from '../../hooks/use-text-translation/useTextTranslation';

import { ContactCard } from './shared/ContactCard';
import { Container, IconWrapper, LowerWrapper, TextWrapper, UpperWrapper } from './supportModal.styles';

type Props = {
  isOpen: boolean;
  close: () => void;
};

export const SupportModal: FC<Props> = ({ isOpen, close }) => {
  const theme = useTheme();
  const { textTranslation } = useTextTranslation();
  const tenantConfig = useTenantConfig();

  const renderIcon = useMemo(
    () => (
      <ThemedIcon icon={HelpIcon} size={'midLarge'} customStrokeColor={theme.v2.icon.onAction} hasBorderRadius={true} />
    ),
    []
  );
  return (
    <BaseModal
      isActive={isOpen}
      closeModal={close}
      disabledOutsideClick={false}
      position={'absolute'}
      width={'35.5rem'}
      height={'26.5rem'}
      padding={'1.5rem'}
    >
      <Container>
        <UpperWrapper>
          <IconWrapper>{renderIcon}</IconWrapper>
          <TextWrapper>
            <Text fontSize={'lg'} lineHeight={'h4'} fontWeight={'bold'} color={theme.v2.text.headingPrimary}>
              {textTranslation('clientProfileDropdown.support')}
            </Text>
            <Text fontWeight={'semi'} color={theme.v2.text.disabled}>
              {textTranslation('clientProfileDropdown.ifYouNeedHelp')}
            </Text>
          </TextWrapper>
        </UpperWrapper>
        <LowerWrapper>
          {/** TODO: ADD ON CLICK FUNCTIONS **/}
          <ContactCard
            icon={EmailBaseIcon}
            contactInfo={tenantConfig.contactEmails}
            onClick={() => console.log('Send email')}
            dataAttribute="info-mail"
          />
          <ContactCard
            icon={PhoneIcon}
            contactInfo={tenantConfig.contactPhoneNumbers}
            onClick={() => console.log('Call')}
            dataAttribute="phone-number"
          />
        </LowerWrapper>
      </Container>
    </BaseModal>
  );
};
