import { ThemedIcon, Text, FileDown02 } from '@metaswiss/ui-kit';
import { FC, useState } from 'react';
import { useTheme } from 'styled-components';

import {
  DocumentDownloadWrapper,
  HoverIconWrapper,
  TooltipContent,
  TooltipHolder,
} from './downloadPayoutDocument.styles';

type Props = {
  backgroundColor?: string;
  contentColor?: string;
  description?: string;
  onClick: () => void;
};

export const DownloadPayoutDocument: FC<Props> = ({ description, backgroundColor, contentColor, onClick }) => {
  const theme = useTheme();
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const onMouseEnter = () => {
    setIsVisible(true);
  };

  const onMouseLeave = () => {
    setIsVisible(false);
  };

  return (
    <DocumentDownloadWrapper onClick={onClick}>
      <HoverIconWrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        {<ThemedIcon icon={FileDown02} customStrokeColor={theme.v2.icon.primary} size="full" />}
      </HoverIconWrapper>
      {isVisible && (
        <TooltipHolder $backgroundColor={backgroundColor}>
          <TooltipContent $backgroundColor={backgroundColor}>
            <Text fontSize={'xsm'} lineHeight={'extraSmall'} color={contentColor}>
              {description}
            </Text>
          </TooltipContent>
        </TooltipHolder>
      )}
    </DocumentDownloadWrapper>
  );
};
