import { DataAttributes } from '@metaswiss/lib';
import { produce } from 'immer';
import { MouseEventHandler } from 'react';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { RegistrationLength } from '../../../../enums/registrationLength';
import { UserRole } from '../../../../enums/userRole';
import { Step } from '../../register/shared/helpers/getWizardSteps.helper';
import { FullCorporateRegistrationData } from '../../register/steps/wizard-step/register-form-steps/full-corporate-registration/fullCorporateRegistrationState';
import { FullPrivateRegistrationData } from '../../register/steps/wizard-step/register-form-steps/full-private-registration/fullPrivateRegistrationState';
import { QuickCorporateRegistrationFormData } from '../../register/steps/wizard-step/register-form-steps/quick-registration-corporate/quickRegistrationCorporateSchema';
import { QuickPrivateRegisterFormData } from '../../register/steps/wizard-step/register-form-steps/quick-registration-private/quickRegistrationPrivateSchema';

type UserDetails =
  | FullPrivateRegistrationData
  | FullCorporateRegistrationData
  | QuickPrivateRegisterFormData
  | QuickCorporateRegistrationFormData;

export type AuthConfig = {
  wrapperStyle?: string;
  containerStyle?: string;
  formId?: string;
  removeLogo?: boolean;
  showStepper?: boolean;
  nextButton?: {
    animationDirection?: 'forward' | 'backward' | 'login-in' | 'login-out';
    text: string;
    type?: 'submit' | 'button' | 'reset' | undefined;
    onClick?: () => void;
    dataAttributes?: DataAttributes;
  };
  actionText?: {
    text: string;
    linkText: string | (() => string);
    disabled?: boolean;
    animationDirection?: 'forward' | 'backward' | 'login-in' | 'login-out';
    onClick: MouseEventHandler<HTMLButtonElement>;
    dataAttributes?: DataAttributes;
  };
  backButton?: {
    animationDirection?: 'forward' | 'backward' | 'login-in' | 'login-out';
    text: string;
    onClick: () => void;
    dataAttributes?: DataAttributes;
  };
};

type AuthContext = {
  loading: boolean;
  isValid: boolean;
  forgotPasswordStep: number;
  userDetails: UserDetails;
  userEmailRegistrationField: string;
  registrationLength: RegistrationLength;
  userRole: UserRole;
  previouslySelectedRegistrationFlow: {
    userRole: UserRole;
    registrationLength: RegistrationLength;
  };
  wizardSteps: Step[];
  maxRegistrationStep: number;
  activeRegistrationStep: number;
  config: AuthConfig;
  animationDirection: 'forward' | 'backward' | 'login-in' | 'login-out';
  setAnimationDirection: (direction: 'forward' | 'backward' | 'login-in' | 'login-out') => void;
  setLoading: (loading: boolean) => void;
  setIsValid: (isValid: boolean) => void;
  setForgotPasswordStep: (step: number) => void;
  setActiveRegistrationStep: (step: number) => void;
  nextStep: () => void;
  prevStep: () => void;
  setMaxRegistrationStep: (step: number) => void;
  setWizardSteps: (steps: Step[]) => void;
  setUserRole: (userRole: UserRole) => void;
  setRegistrationLength: (registrationLength: RegistrationLength) => void;
  setPreviouslySelectedRegistrationFlow: (userRole: UserRole, registrationLength: RegistrationLength) => void;
  setUserDetails: (update: Partial<UserDetails>) => void;
  setAuthConfig: (config: AuthConfig) => void;
  clearAuthContext: () => void;
  setUserEmailField: (userEmailRegistrationField: string) => void;
  clearRegistrationContext: () => void;
  checkedEmail?: string;
  setCheckedEmail: (checkedEmail: string) => void;
};

export const useAuthContext = create<AuthContext>()(
  devtools(
    immer((set) => ({
      forgotPasswordStep: 1,
      loading: false,
      isValid: true,
      userDetails: {} as UserDetails,
      userEmailRegistrationField: '',
      registrationLength: RegistrationLength.QUICK,
      userRole: UserRole.PRIVATE,
      previouslySelectedRegistrationFlow: {
        userRole: UserRole.PRIVATE,
        registrationLength: RegistrationLength.QUICK,
      },
      wizardSteps: [],
      maxRegistrationStep: 0,
      activeRegistrationStep: 0,
      config: {},
      animationDirection: 'forward',
      checkedEmail: undefined,
      setAnimationDirection: (direction: 'forward' | 'backward' | 'login-in' | 'login-out') =>
        set(() => ({ animationDirection: direction })),
      setLoading: (loading: boolean) => set(() => ({ loading })),
      setIsValid: (isValid: boolean) => set(() => ({ isValid })),
      setCheckedEmail: (checkedEmail: string) => set(() => ({ checkedEmail })),
      setForgotPasswordStep: (step: number) => set(() => ({ forgotPasswordStep: step })),
      setActiveRegistrationStep: (step: number) => set(() => ({ activeRegistrationStep: step })),
      nextStep: () => set((state) => ({ activeRegistrationStep: state.activeRegistrationStep + 1 })),
      prevStep: () => set((state) => ({ activeRegistrationStep: state.activeRegistrationStep - 1 })),
      setMaxRegistrationStep: (step: number) => set(() => ({ maxRegistrationStep: step })),
      setWizardSteps: (steps: Step[]) => set(() => ({ wizardSteps: steps })),
      setUserRole: (userRole: UserRole) => set(() => ({ userRole })),
      setRegistrationLength: (registrationLength: RegistrationLength) => set(() => ({ registrationLength })),
      setPreviouslySelectedRegistrationFlow: (userRole: UserRole, registrationLength: RegistrationLength) =>
        set(() => ({
          previouslySelectedRegistrationFlow: {
            userRole,
            registrationLength,
          },
        })),
      setUserDetails: (update: Partial<UserDetails>) =>
        set((state) => {
          return produce(state, (draft) => {
            Object.assign(draft.userDetails, update);
          });
        }),
      setUserEmailField: (userEmailRegistrationField: string) =>
        set(() => ({
          userEmailRegistrationField,
        })),
      setAuthConfig: (config: AuthConfig) => set(() => ({ config })),
      clearRegistrationContext: () => {
        set(() => ({
          userDetails: {} as UserDetails,
          activeRegistrationStep: 0,
        }));
      },
      clearAuthContext: () => {
        set(() => ({
          forgotPasswordStep: 1,
          loading: false,
          isValid: true,
          userDetails: {} as UserDetails,
          registrationLength: RegistrationLength.QUICK,
          userRole: UserRole.PRIVATE,
          wizardSteps: [],
          maxRegistrationStep: 0,
          activeRegistrationStep: 0,
          config: {},
        }));
      },
    }))
  )
);
