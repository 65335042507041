import styled from 'styled-components';

import { breakpoints } from '../../../../breakpoints';

export const Section = styled.div`
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  height: fit-content;
  width: 100%;
`;

export const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1rem;
`;
export const CheckboxGroup = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  align-items: flex-start;
  @media ${breakpoints.tabletSmHeight} {
    max-height: 26rem;
  }
  overflow-y: auto;
  max-height: 20rem;
`;
