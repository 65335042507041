import { extractNumericDigits } from '@metaswiss/lib';
import { BaseCard, ProgressBar, ResourceDetails, ResourceWithLogo, Text, UsersProfileIcon } from '@metaswiss/ui-kit';
import CircleBarChart from '@metaswiss/ui-kit/src/components/atoms/bar-chart/BarChart';
import { CurrencyChfIcon } from '@metaswiss/ui-kit/src/iconography/CurrencyChfIcon.tsx';
import { useMemo } from 'react';
import { useTheme } from 'styled-components';

import { ProductType } from '../../enums/productType.enum.ts';
import { useTextTranslation } from '../../hooks/use-text-translation/useTextTranslation';
import { CardOfferingDetailsHolder } from '../../pages/protected/offering/shared/components/card-offering-details-holder/CardOfferingDetailsHolder';
import { getChartPalette } from '../../shared/helpers/getChartColor.helper.ts';

import {
  ChartWrapper,
  DetailsInfoWrapper,
  DetailsWrapper,
  LeftColumnContainer,
  RightColumnContainer,
  RowWrapper,
  StatisticContainerWrapper,
  StyledContainer,
} from './productDetails.styles.ts';

type Props = {
  companyInfo: string;
  address: string;
  productOffered: string;
  productSold: string;
  totalCollected: string;
  investors: number;
  productType: string;
  remainings: string;
  total: number;
  value: number;
  product: ProductType;
  progressBarFilledColor: string;
  progressBarEmptyColor: string;
  issuerLogoUrl: string;
};
export const ProductDetails = ({
  companyInfo,
  address,
  productOffered,
  productSold,
  totalCollected,
  investors,
  productType,
  remainings,
  total,
  value,
  product,
  progressBarFilledColor,
  progressBarEmptyColor,
  issuerLogoUrl,
}: Props) => {
  const theme = useTheme();
  const { textTranslation } = useTextTranslation();
  const selectedType = useMemo(() => product === ProductType.ACTIONS, [product]);
  const dataAttributes = {
    picture: `${selectedType ? 'share' : 'bond'}-issuer-pic`,
    name: `${selectedType ? 'share' : 'bond'}-issuer-name`,
    address: `${selectedType ? 'share' : 'bond'}-address`,
  };

  return (
    <DetailsWrapper>
      <CardOfferingDetailsHolder heading={textTranslation('account.overview')}>
        <BaseCard
          padding="1.5rem"
          disableStateEffects
          override={{
            borderRadius: theme.v2.componentsRadius.cards.default,
            backgroundColor: theme.v2.surface.primary,
            borderColor: theme.v2.border.primary,
          }}
        >
          <StyledContainer>
            <DetailsInfoWrapper>
              <ResourceWithLogo
                issuerLogoUrl={issuerLogoUrl}
                addressTitle={address}
                subTitle={textTranslation('offering.companyInfo')}
                title={companyInfo}
                logoTitle={companyInfo}
                dataAttributes={dataAttributes}
              />
            </DetailsInfoWrapper>
            <RowWrapper>
              <StatisticContainerWrapper>
                <RowWrapper>
                  <ProgressBar
                    displayedItems={extractNumericDigits(productSold)}
                    totalItems={selectedType ? total : extractNumericDigits(productOffered)}
                    progressStartLabel={textTranslation('offering.productSold', { product: productType })}
                    progressStartText={productSold}
                    progressEndLabel={textTranslation('offering.productOffered', { product: productType })}
                    progressEndText={productOffered}
                    filledColor={progressBarFilledColor}
                    emptyColor={progressBarEmptyColor}
                  />
                </RowWrapper>
                <RowWrapper>
                  <LeftColumnContainer $isShares={selectedType}>
                    {selectedType && (
                      <ResourceDetails
                        size="sm"
                        icon={CurrencyChfIcon}
                        subTitle={textTranslation('offering.totalCollected')}
                        title={totalCollected.toString()}
                        gap="1rem"
                        iconColor={theme.v2.icon.primary}
                      />
                    )}
                    <ResourceDetails
                      size="sm"
                      icon={UsersProfileIcon}
                      subTitle={textTranslation('offering.investors')}
                      title={investors.toString()}
                      iconColor={theme.v2.colors.secondary100}
                      gap="1rem"
                    />
                  </LeftColumnContainer>
                  <RightColumnContainer $isShares={selectedType}>
                    <BaseCard
                      disableStateEffects
                      padding={selectedType ? '1rem 1.25rem' : '1rem'}
                      override={{
                        backgroundColor: theme.v2.surface.primary,
                        borderRadius: theme.v2.radius.small,
                        borderColor: theme.v2.border.primary,
                      }}
                    >
                      <ChartWrapper $isShares={selectedType}>
                        <CircleBarChart
                          total={total}
                          size={selectedType ? '4rem' : '2.5rem'}
                          fontSize={selectedType ? 'sm' : 'xxsm'}
                          value={value}
                          lineHeight={'h4'}
                          color={getChartPalette(total, value)}
                        />
                        <Text fontSize={'sm'} lineHeight={'medium'} fontWeight={'reg'} color={theme.v2.text.disabled}>
                          {textTranslation(remainings)}
                        </Text>
                      </ChartWrapper>
                    </BaseCard>
                  </RightColumnContainer>
                </RowWrapper>
              </StatisticContainerWrapper>
            </RowWrapper>
          </StyledContainer>
        </BaseCard>
      </CardOfferingDetailsHolder>
    </DetailsWrapper>
  );
};
