import { ResourceDetails, IconProps } from '@metaswiss/ui-kit';
import { FC } from 'react';
import { useTheme } from 'styled-components';

import { ProductType } from '../../enums/productType.enum';
import { useTextTranslation } from '../../hooks/use-text-translation/useTextTranslation';
import { CardOfferingDetailsHolder } from '../../pages/protected/offering/shared/components/card-offering-details-holder/CardOfferingDetailsHolder';

import { DetailsWrapper, MainCard, ResourceWrapper, Wrapper } from './productInfo.styles';

export type InfoItem = {
  icon: FC<IconProps>;
  text: string;
  value: string;
  subText?: string;
  subColor?: string;
  iconColor: string;
};

type Props = {
  items: InfoItem[];
  headingCardTitle: string;
  product: ProductType;
};

export const ProductInfo: FC<Props> = ({ items, headingCardTitle }) => {
  const theme = useTheme();
  const { textTranslation } = useTextTranslation();
  return (
    <CardOfferingDetailsHolder heading={textTranslation(headingCardTitle)}>
      <Wrapper>
        <MainCard
          disableStateEffects={true}
          padding={'0.25rem 1rem'}
          allowOverflow={true}
          override={{
            backgroundColor: theme.v2.surface.primary,
            borderColor: theme.v2.border.primary,
            borderRadius: theme.v2.componentsRadius.cards.default,
          }}
        >
          <DetailsWrapper>
            {items.map((item) => (
              <ResourceWrapper key={`${item.value}`}>
                <ResourceDetails
                  gap="1.25rem"
                  key={item.text}
                  icon={item.icon}
                  subTitle={textTranslation(item.text)}
                  title={item.value}
                  size="xs"
                  iconColor={item.iconColor}
                  dataAttributes={`bond-${item.value}`}
                />
              </ResourceWrapper>
            ))}
          </DetailsWrapper>
        </MainCard>
      </Wrapper>
    </CardOfferingDetailsHolder>
  );
};
