import {
  CorporateUserFullResponse,
  PrivateUserFullResponse,
  UpdateCorporateUserRequest,
  UpdatePrivateUserRequestV4,
} from '@metaswiss/api';
import { ToastType, useToastQueueContext } from '@metaswiss/lib';
import {
  Button,
  ErrorDialog,
  FormInput,
  FormSelectionPopup,
  Globe04,
  HeaderCard,
  PageStateContainer,
  Text,
  TextLink,
  ThemedIcon,
} from '@metaswiss/ui-kit';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { FieldError, Path, useFormContext } from 'react-hook-form';
import { useTheme } from 'styled-components';

import { api } from '../../../../api/msApi';
import { getRefreshToken, setAccessToken } from '../../../../api/tokenHelpers';
import ControlledForm from '../../../../components/controlled-form/ControlledForm';
import { ApiResource } from '../../../../enums/resource.enum';
import { UserRole } from '../../../../enums/userRole';
import { AppState, ShellNavigationState, useAppState, useShellNavigationState } from '../../../../global-state/zustand';
import { useCountries } from '../../../../hooks/use-countries/useCountries';
import { useTextTranslation } from '../../../../hooks/use-text-translation/useTextTranslation';
import { defaultUser } from '../../../../shared/helpers/defaultUser';
import { getQueryKey } from '../../../../shared/helpers/getQueryKey.helper';
import { setFieldValues } from '../../../../shared/helpers/setFieldValues.helper';

import { parseFormObjectUser } from './mappers/userAddress.mapper';
import { createUserAddressSchema, FormData, userAddressSchema } from './schemas/userAddress.schema';
import { AddressInfoContainer, ButtonWrapper, HeaderContainer } from './styles/address.styles';

export const AddressView = () => {
  const theme = useTheme();
  const { textTranslation } = useTextTranslation();
  const { selectedCountry, countriesAsDropdown } = useCountries();
  const queryClient = useQueryClient();

  const user = useAppState((state: AppState) => state.user) || defaultUser;

  const { setIsChangeRouteAvailable } = useShellNavigationState((state: ShellNavigationState) => state);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { enqueue } = useToastQueueContext();

  const {
    setValue,
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty },
  } = useFormContext<FormData>();

  const { data: privateUser, isSuccess: isSuccessPrivateUser } = useQuery({
    queryKey: getQueryKey(ApiResource.PRIVATE_USER, user?.id),
    queryFn: () => api.users.getPrivateUser(user.id),
    enabled: !!user?.id && user.role === UserRole.PRIVATE,
  });

  const { data: corporateUser, isSuccess: isSuccessCorporateUser } = useQuery({
    queryKey: getQueryKey(ApiResource.CORPORATE_USER, user?.id),
    queryFn: () => api.users.getCorporateUser(user.id),
    enabled: !!user?.id && user.role === UserRole.CORPORATE,
  });

  useEffect(() => {
    if (isSuccessPrivateUser || isSuccessCorporateUser) {
      reset(
        parseFormObjectUser(
          (user?.role === UserRole.PRIVATE ? privateUser : corporateUser) as
            | PrivateUserFullResponse
            | CorporateUserFullResponse,
          selectedCountry(user?.role === UserRole.PRIVATE ? privateUser?.country?.id : corporateUser?.country?.id)
        )
      );
    }
  }, [isSuccessPrivateUser, isSuccessCorporateUser]);

  const mutation = useMutation<PrivateUserFullResponse | CorporateUserFullResponse, unknown, FormData>({
    mutationFn: (data: FormData) => {
      const updateData: Partial<UpdatePrivateUserRequestV4 | UpdateCorporateUserRequest> = {
        countryId: data.country.value,
        city: data.city,
        address: data.address,
        zipCode: data.zipCode,
      };
      return user?.role === UserRole.PRIVATE
        ? api.users.updatePrivateDetails(user.id, updateData as UpdatePrivateUserRequestV4)
        : api.users.updateCorporateDetails(user.id, updateData as UpdateCorporateUserRequest);
    },
    onSuccess: async (response) => {
      setIsEditMode(false);
      await queryClient.invalidateQueries({
        queryKey: getQueryKey(
          user?.role === UserRole.PRIVATE ? ApiResource.PRIVATE_USER : ApiResource.CORPORATE_USER,
          user.id
        ),
      });
      reset(
        parseFormObjectUser(
          response as PrivateUserFullResponse | CorporateUserFullResponse,
          selectedCountry(response?.country?.id)
        )
      );
      const refreshToken = getRefreshToken() || '';
      const refreshResponse = await api.auth.updateAccessToken({ refreshToken });
      setAccessToken(refreshResponse.accessToken);
      await queryClient.removeQueries(getQueryKey(ApiResource.NFT_COLLECTION));
      await queryClient.removeQueries(getQueryKey(ApiResource.SHARE_FULL));
      await queryClient.removeQueries(getQueryKey(ApiResource.SHARE_LISTING));
      await queryClient.invalidateQueries();
    },
    onError: () => {
      enqueue(ToastType.ERROR, textTranslation('error.wrongCredentials'), textTranslation('error.error'));
    },
  });

  useEffect(() => {
    setFieldValues(
      parseFormObjectUser(
        (user?.role === UserRole.PRIVATE ? privateUser : corporateUser) as
          | PrivateUserFullResponse
          | CorporateUserFullResponse,
        selectedCountry(user?.role === UserRole.PRIVATE ? privateUser?.country?.id : corporateUser?.country?.id)
      ),
      setValue
    );
  }, [privateUser, corporateUser, setValue, user?.role]);

  const onSubmit = (data: FormData) => {
    mutation.mutate(data);
  };

  const onCancelHandler = () => {
    setIsModalOpen(false);
    setIsEditMode(false);
    setIsChangeRouteAvailable(true);
    setFieldValues(
      parseFormObjectUser(
        (user?.role === UserRole.PRIVATE ? privateUser : corporateUser) as
          | PrivateUserFullResponse
          | CorporateUserFullResponse,
        selectedCountry(user?.country?.id ?? '')
      ),
      setValue
    );
    reset(
      parseFormObjectUser(
        (user?.role === UserRole.PRIVATE ? privateUser : corporateUser) as
          | PrivateUserFullResponse
          | CorporateUserFullResponse,
        selectedCountry(user?.role === UserRole.PRIVATE ? privateUser?.country?.id : corporateUser?.country?.id)
      )
    );
  };

  const onClose = () => {
    setIsModalOpen(false);
  };

  return (
    <PageStateContainer
      showLoading={true}
      isLoading={!user}
      showEmptyState={false}
      showError={false}
      textTranslation={textTranslation}
      maxWidth="44.5rem"
    >
      <ErrorDialog
        primaryText={textTranslation('account.discardChanges')}
        secondaryText={textTranslation('account.areYouSureDiscard')}
        isOpen={isModalOpen}
        close={onClose}
        primaryButtonText={textTranslation('account.discard')}
        onPrimaryButtonClick={onCancelHandler}
        secondaryButtonText={textTranslation('account.cancel')}
        onSecondaryButtonClick={onClose}
      />
      <HeaderCard
        header={
          <HeaderContainer>
            <Text fontWeight="bold" fontSize="base" color={theme.v2.text.headingPrimary}>
              {textTranslation('global.address')}
            </Text>
            <TextLink
              textSize="base"
              color={isEditMode ? 'error' : 'primary'}
              padding={'0.25rem'}
              dataTestId="address-edit-button"
              onClick={() => {
                !isEditMode ? setIsEditMode(true) : isDirty ? setIsModalOpen(true) : setIsEditMode(false);
              }}
            >
              {isEditMode ? textTranslation('account.discard') : textTranslation('account.edit')}
            </TextLink>
          </HeaderContainer>
        }
        removeBackground={false}
        height={'fit-content'}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <AddressInfoContainer>
            <FormSelectionPopup<FormData>
              name={userAddressSchema.country as Path<FormData>}
              control={control}
              error={errors[userAddressSchema.country] as FieldError}
              itemsList={countriesAsDropdown}
              label={textTranslation('account.country')}
              title={textTranslation('account.country')}
              searchText={textTranslation('account.search')}
              renderEndIcon={() => (
                <ThemedIcon icon={Globe04} palette="primary" customStrokeColor={theme.v2.icon.primary} />
              )}
              isLocked={!isEditMode}
              fill
              closeButtonText={textTranslation('cancel')}
              selectButtonText={textTranslation('select')}
              dataAttributeValue={{ select: 'address-country' }}
            />
            <FormInput<FormData>
              name={userAddressSchema.city as Path<FormData>}
              control={control}
              label={textTranslation('account.city')}
              error={errors[userAddressSchema.city]}
              fill
              isLocked={!isEditMode}
              alertIconShown={true}
              dataAttributes={{ dataTestid: 'address-city' }}
            />
            <FormInput<FormData>
              name={userAddressSchema.address as Path<FormData>}
              control={control}
              label={textTranslation('global.address')}
              error={errors[userAddressSchema.address]}
              fill
              isLocked={!isEditMode}
              alertIconShown={true}
              dataAttributes={{ dataTestid: 'address-address' }}
            />
            <FormInput<FormData>
              name={userAddressSchema.zipCode as Path<FormData>}
              control={control}
              label={textTranslation('account.zipCode')}
              error={errors[userAddressSchema.zipCode]}
              fill
              isLocked={!isEditMode}
              alertIconShown={true}
              dataAttributes={{ dataTestid: 'address-zipcode' }}
            />
          </AddressInfoContainer>
          {isEditMode && (
            <ButtonWrapper>
              <Button
                text={textTranslation('account.saveChanges')}
                color="primary"
                type="submit"
                fill
                disabled={!isDirty || !isValid}
                loading={mutation.isLoading}
                dataAttributes={{ dataTestid: 'address-savechanges-button' }}
              />
            </ButtonWrapper>
          )}
        </form>
      </HeaderCard>
    </PageStateContainer>
  );
};

export const Address = () => {
  return (
    <ControlledForm validationSchema={createUserAddressSchema}>
      <AddressView />
    </ControlledForm>
  );
};
