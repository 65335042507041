import { Text, ThemedIcon } from '@metaswiss/ui-kit';
import { FC } from 'react';
import { useTheme } from 'styled-components';

import { CardWrapper, IconWrapper, TextContent, TextWrapper } from './resourceDetails.styles';
import { getSize } from './resourceDetailsHelper';
import { Props } from './types';

export const ResourceDetails: FC<Props> = ({
  icon,
  iconColor,
  iconWrapperColor,
  size,
  gap = '0.75rem',
  title,
  subTitle,
  subPropertyName = '',
  subPropertyColor,
  hasWrapper = false,
  sizeWrapper = '3rem',
  dataAttributes = 'share-pricepershare',
}) => {
  const theme = useTheme();
  const { subTitleProps, titleProps, iconStyles } = getSize(size);

  return (
    <CardWrapper $gap={gap}>
      {hasWrapper ? (
        <IconWrapper $size={sizeWrapper} $backgroundColor={iconWrapperColor}>
          <ThemedIcon icon={icon} {...iconStyles} customStrokeColor={iconColor} />
        </IconWrapper>
      ) : (
        <ThemedIcon icon={icon} {...iconStyles} customStrokeColor={iconColor} />
      )}

      <TextWrapper data-tesid={dataAttributes}>
        <TextContent>
          <Text {...subTitleProps} color={theme.v2.text.disabled}>
            {subTitle}
          </Text>
          {subPropertyName && (
            <Text {...subTitleProps} fontWeight="bold" color={subPropertyColor}>
              {subPropertyName}
            </Text>
          )}
        </TextContent>
        <Text {...titleProps} color={theme.v2.text.headingPrimary}>
          {title}
        </Text>
      </TextWrapper>
    </CardWrapper>
  );
};
