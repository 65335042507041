import { FC } from 'react';

import { IconProps } from './iconProps';

export const FileDown02: FC<IconProps> = ({ color = 'none', strokeColor = '#65C549' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.7996 21.6003H5.9996C4.67411 21.6003 3.5996 20.5258 3.59961 19.2003L3.5997 4.80038C3.59971 3.4749 4.67422 2.40039 5.9997 2.40039H16.8C18.1255 2.40039 19.2 3.47491 19.2 4.80039V11.4004M20.4 19.1578L17.9457 21.6004M17.9457 21.6004L15.6 19.2682M17.9457 21.6004V15.6004M7.79998 7.20039H15M7.79998 10.8004H15M7.79998 14.4004H11.4"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
