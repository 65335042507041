import { ToastType, useToastQueueContext } from '@metaswiss/lib';
import { EmailIcon, FormInput, Heading, Text, ThemedIcon } from '@metaswiss/ui-kit';
import { useMutation } from '@tanstack/react-query';
import { FC, useEffect } from 'react';
import { Path, useFormContext } from 'react-hook-form';
import { useTheme } from 'styled-components';

import { api } from '../../../../../api/msApi';
import ControlledForm from '../../../../../components/controlled-form/ControlledForm.tsx';
import { useTextTranslation } from '../../../../../hooks/use-text-translation/useTextTranslation';
import { useAuthContext } from '../../../shared/auth-context/authContext.ts';
import { useSetAuthConfig } from '../../../shared/auth-context/useSetAuthConfig.ts';
import { useForgotPasswordState } from '../../shared/context';
import { FormWrapper, HeadingContainer, HeadingWrapper } from '../../shared/styles';

import { createForgotPassFirstStepSchema, firstStepFormSchema, FormData } from './firstStepSchema';

export const FirstStepView: FC = () => {
  const theme = useTheme();
  const { textTranslation } = useTextTranslation();
  const setEmail = useForgotPasswordState((state) => state.setEmail);
  const { enqueue } = useToastQueueContext();

  const { setLoading, setIsValid, setForgotPasswordStep } = useAuthContext((state) => state);

  const {
    control,
    formState: { errors, isValid },
    getValues,
    setError,
    handleSubmit,
  } = useFormContext<FormData>();

  const forgotPasswordMutation = useMutation({
    mutationFn: (data: FormData) => {
      return api.auth.forgotPassword({ email: data.email });
    },
    onMutate: () => {
      setLoading(true);
    },
    onError: () => {
      setLoading(false);
      setError(firstStepFormSchema.email, { message: ' ' });
      enqueue(ToastType.ERROR, textTranslation('error.emailNotExist'), textTranslation('error.error'));
    },
    onSuccess: () => {
      setLoading(false);
      setEmail(getValues().email);
      setForgotPasswordStep(2);
    },
  });

  useSetAuthConfig(
    {
      nextButton: {
        text: 'forgotPassword.sendCode',
        dataAttributes: { dataTestid: 'sendCode-button' },
        onClick: () => {
          handleSubmit((data) => forgotPasswordMutation.mutate(data))();
        },
      },
    },
    [handleSubmit]
  );

  useEffect(() => {
    setIsValid(isValid);
    return () => {
      setIsValid(true);
    };
  }, [isValid, setIsValid]);

  return (
    <>
      <HeadingWrapper>
        <HeadingContainer>
          <Heading headingType="h4" fontWeight="bold" color={theme.v2.text.headingPrimary}>
            {textTranslation('forgotPassword.forgotPassword')}
          </Heading>
        </HeadingContainer>
        <Text color={theme.v2.text.bodyPrimary}>{textTranslation('forgotPassword.enterEmail')}</Text>
      </HeadingWrapper>
      <FormWrapper>
        <FormInput<FormData>
          name={firstStepFormSchema.email as Path<FormData>}
          control={control}
          fill
          label={textTranslation('forgotPassword.email')}
          error={errors[firstStepFormSchema.email]}
          type="text"
          dataAttributes={{ dataTestid: ' forgotPass-input' }}
          renderStartIcon={() => (
            <ThemedIcon icon={EmailIcon} palette="neutrals" strokeColor="hue700" color={'hue700'} />
          )}
        />
      </FormWrapper>
    </>
  );
};

export const FirstStep = () => {
  return (
    <ControlledForm validationSchema={createForgotPassFirstStepSchema}>
      <FirstStepView />
    </ControlledForm>
  );
};
