import { Text } from '../text';

import { DotIndicator, TabButton } from './tab.styles';

type TabProps = {
  children: string;
  onClick: () => void;
  isSelected: boolean;
  selectedTabRef?: React.MutableRefObject<HTMLButtonElement | null>;
  addIndicator?: boolean;
  dataAttribute?: string;
};

export const Tab = ({
  children,
  onClick,
  isSelected,
  selectedTabRef,
  addIndicator = false,
  dataAttribute,
}: TabProps) => {
  return (
    <TabButton
      onClick={onClick}
      ref={isSelected ? selectedTabRef : null}
      $isSelected={isSelected}
      $content={children}
      $isIndicatorActive={addIndicator}
      data-testid={dataAttribute}
    >
      <Text>{children}</Text>
      {addIndicator && <DotIndicator />}
    </TabButton>
  );
};
