import { AnimatePresence, motion } from 'framer-motion';
import { FC } from 'react';
import { useTheme } from 'styled-components';

import { Text, ThemedIcon } from '../../../components';

import {
  DetailsListContainer,
  IconContainer,
  ItemContainer,
  ItemsWrapper,
  ItemTextsWrapper,
} from './detailsList.styles';
import { DetailsItem, DetailsListProps } from './types';

export const DetailsList: FC<DetailsListProps> = ({
  isVisibleTitleStep = 1,
  title,
  items,
  step,
  isNftDetails = false,
}) => {
  const theme = useTheme();
  return (
    <DetailsListContainer $isNftDetails={isNftDetails}>
      <AnimatePresence mode="popLayout">
        {step >= isVisibleTitleStep && (
          <motion.div animate={{ opacity: 1 }} initial={{ opacity: 0 }} exit={{ opacity: 0 }} key="title">
            <Text color={theme.v2.text.headingPrimary} fontWeight={'bold'}>
              {title}
            </Text>
          </motion.div>
        )}

        <ItemsWrapper key={step}>
          {items.map(
            (item: DetailsItem, i) =>
              step >= item.isVisibleStep && (
                <ItemContainer
                  key={`${item.text} ${i}`}
                  animate={{ opacity: 1 }}
                  initial={{ opacity: 0 }}
                  exit={{ opacity: 0 }}
                  data-testid={`payment-${item.subText.toLocaleLowerCase().replace(/\s+/g, '-')}`}
                >
                  <IconContainer>
                    <ThemedIcon icon={item.icon} customStrokeColor={theme.v2.icon.primary} />
                  </IconContainer>
                  <ItemTextsWrapper>
                    <Text color={theme.v2.text.disabled} fontSize={'sm'} lineHeight={'medium'}>
                      {item.subText}
                    </Text>
                    <Text color={theme.v2.text.headingPrimary} fontWeight={'semi'}>
                      {item.text}
                    </Text>
                  </ItemTextsWrapper>
                </ItemContainer>
              )
          )}
        </ItemsWrapper>
      </AnimatePresence>
    </DetailsListContainer>
  );
};
