import { AssetUploadUnassignedResponse } from '@metaswiss/api';
import { convertDocumentSize } from '@metaswiss/lib';
import { UploadFile, Text } from '@metaswiss/ui-kit';
import { AcceptedFileTypes } from '@metaswiss/ui-kit/src/components/molecules/upload-file/enums';

import { usePostAndGetDocument } from '../../../../../../../../hooks/use-post-and-get-document/usePostAndGetDocument';
import { useTextTranslation } from '../../../../../../../../hooks/use-text-translation/useTextTranslation';
import { initialFullCorporateRegistrationValues } from '../fullCorporateRegistrationState';
import { fullCorporateRegistrationUploadDataFilesSchema } from '../upload-documents/uploadDocumentsSchema';

import { SingleUploadDocumentsType } from './types';
import { StepTextContainer } from './uploadDocuments.styles';

export const SingleUploadDocuments = ({
  property,
  userDetails,
  setRegistrationFlowDocument,
  setValue,
  getValue,
  title,
}: SingleUploadDocumentsType) => {
  const { textTranslation } = useTextTranslation();

  const { mutate, isLoading, isError } = usePostAndGetDocument({
    onMutate: handleDocumentMutation,
    onRefetch: handleDocumentRefetch,
    getDocumentInfo: ({ fileName, size }) => {
      setRegistrationFlowDocument(
        {
          ...userDetails?.[property],
          fileName,
          fileSize: convertDocumentSize(size),
        },
        property
      );
    },
    fileId: userDetails?.[property]?.fileId ?? '',
  });

  function handleDocumentMutation({ fileId, url }: AssetUploadUnassignedResponse) {
    setValue(fullCorporateRegistrationUploadDataFilesSchema[property], fileId, { shouldValidate: true });
    setRegistrationFlowDocument({ ...userDetails[property], fileId, url }, property);
  }

  function handleDocumentRefetch(documentUrl: string) {
    setRegistrationFlowDocument(
      {
        ...userDetails[property],
        fileId: getValue(property),
        url: documentUrl,
      },
      property
    );
  }

  const handleDocumentUpload = (file: File) => {
    mutate(file);
  };

  const handleDocumentDelete = () => {
    setRegistrationFlowDocument({ ...initialFullCorporateRegistrationValues[property] }, property);
    setValue(property, '', { shouldValidate: true });
  };

  return (
    <>
      <StepTextContainer>
        <Text fontWeight="bold">{textTranslation(title)}</Text>
      </StepTextContainer>
      <UploadFile
        uploadFile={handleDocumentUpload}
        onDelete={handleDocumentDelete}
        isLoading={isLoading}
        isError={isError}
        initialTitle={textTranslation('registration.corporateRegistration.uploadLogoTitle')}
        initialSubtitle={textTranslation('registration.corporateRegistration.uploadLogoSubtitle')}
        invalidTitle={textTranslation('registration.corporateRegistration.invalidLogoTitle')}
        invalidSubtitle={textTranslation('registration.corporateRegistration.uploadLogoSubtitle')}
        failedTitle={textTranslation('registration.corporateRegistration.failedLogoTitle')}
        failedSubtitle={textTranslation('registration.corporateRegistration.failedLogoUploadSubtitle')}
        acceptedFileTypes={AcceptedFileTypes.ALL}
        $width="37.5rem"
        $height="10.75rem"
        onViewDocument={() => window.open(userDetails[property]?.url, '_blank')}
        value={userDetails?.[property] || initialFullCorporateRegistrationValues[property]}
        viewFileTitle={userDetails?.[property]?.fileName}
        viewFileSubtitle={userDetails?.[property]?.fileSize}
        dataAttributes={{
          dataTestid: textTranslation(title).replace(/\s+/g, '-').toLowerCase(),
        }}
      />
    </>
  );
};
