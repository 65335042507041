import { Button, RemSize, Text } from '@metaswiss/ui-kit';
import { FC } from 'react';
import { useTheme } from 'styled-components';

import {
  ButtonWrapper,
  DateWrapper,
  DescriptionTextContainer,
  DescriptionWrapper,
  HeaderWrapper,
  NewsletterItemContent,
  StyledImage,
  StyledNewsletterItemCard,
  TitleWrapper,
  WrapperImage,
} from './newsletterItem.styles';

interface NewsletterItemProps {
  title: string;
  date: string;
  description: string;
  buttonText: string;
  onClick: () => void;
  image: string;
  width?: string;
  height?: string;
  imgHeight?: RemSize;
  contentHeight?: RemSize;
}

export const NewsletterItem: FC<NewsletterItemProps> = ({
  image,
  title,
  description,
  date,
  buttonText,
  onClick,
  width,
  height,
  imgHeight,
  contentHeight,
}) => {
  const theme = useTheme();

  return (
    <StyledNewsletterItemCard
      disableStateEffects
      $width={width}
      $height={height}
      override={{
        borderRadius: theme.v2.radius.small,
        backgroundColor: theme.v2.surface.primary,
        borderColor: theme.v2.border.primary,
      }}
      onClick={onClick}
    >
      <WrapperImage $imgHeight={imgHeight}>
        <StyledImage src={image} alt={title} />
      </WrapperImage>
      <NewsletterItemContent $contentHeight={contentHeight}>
        <HeaderWrapper>
          <TitleWrapper>
            <Text fontWeight={'bold'} fontSize={'sm'} lineHeight={'medium'}>
              {title}
            </Text>
          </TitleWrapper>
          <DateWrapper>
            <Text fontSize={'sm'} lineHeight={'medium'}>
              {date}
            </Text>
          </DateWrapper>
        </HeaderWrapper>
        <DescriptionTextContainer>
          <DescriptionWrapper>
            <Text fontSize={'xsm'} lineHeight={'extraSmall'} color={theme.v2.text.disabled}>
              {description}
            </Text>
          </DescriptionWrapper>
        </DescriptionTextContainer>
        <ButtonWrapper>
          <Button
            variant={'outlined'}
            color={'neutral'}
            text={buttonText}
            onClick={onClick}
            fill
            size={'medium'}
            borderRadius={'medium'}
            dataAttributes={{ dataTestid: 'newsletter-read-more' }}
          />
        </ButtonWrapper>
      </NewsletterItemContent>
    </StyledNewsletterItemCard>
  );
};
