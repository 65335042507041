import { PortfolioBondAssetsDTO } from '@metaswiss/api';
import { formatAmountWithoutDecimals } from '@metaswiss/lib';
import {
  ArrowChartIcon,
  Button,
  DataStatusPage,
  ItemsCounter,
  PageStateContainer,
  PortfolioTransactionCard,
  Text,
  TextLink,
  ThemedIcon,
  TrendUpWithBackgroundIcon,
} from '@metaswiss/ui-kit';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { api } from '../../../../api/msApi';
import { ProductType } from '../../../../enums/productType.enum';
import { ApiResource } from '../../../../enums/resource.enum';
import { TransactionType } from '../../../../enums/transactionType';
import { useTextTranslation } from '../../../../hooks/use-text-translation/useTextTranslation';
import { routes } from '../../../../router/routes';
import { swissCode } from '../../../../shared/constants/currencyCode';
import { getQueryKey } from '../../../../shared/helpers/getQueryKey.helper';
import { PortfolioBanner } from '../shared/components/portfolio-banner/PortfolioBanner';
import { PortfolioCard } from '../shared/components/portfolio-card/PortfolioCard';

import { HistoryWrapper, IndicatorGroup, TransactionCardWrapper, TransactionsContainer } from './smartBonds.styles';

const numberOfDisplayedBonds = 4;

export const SmartBonds = () => {
  const { textTranslation } = useTextTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  const {
    data: portfolio,
    isLoading: isLoadingPortfolio,
    isError: isErrorPortfolio,
    refetch: refetchPortfolio,
  } = useQuery({
    queryKey: getQueryKey(ApiResource.PORTFOLIO, ProductType.BOND_PAPERS),
    queryFn: () => api.clientPortfolio.getSmartBondInvestments(0, numberOfDisplayedBonds, 0),
  });

  const {
    data: statistics,
    isLoading: isLoadingStatistics,
    isError: isErrorStatistics,
    refetch: refetchStatistics,
  } = useQuery({
    queryKey: getQueryKey(ApiResource.BOND_PORTFOLIO_STATISTICS),
    queryFn: () => api.clientPortfolio.getSmartBondInvestmentStatistics(),
  });

  const portfolioItems: PortfolioBondAssetsDTO[] = useMemo(
    () => portfolio?.items as unknown as PortfolioBondAssetsDTO[],
    [portfolio]
  );

  const portfolioTotalItems = useMemo(() => portfolio?.totalItems || 0, [portfolio]);

  return (
    <PageStateContainer
      showLoading={true}
      isLoading={isLoadingPortfolio || isLoadingStatistics}
      showError={true}
      isError={isErrorPortfolio || isErrorStatistics}
      onTryAgain={async () => await Promise.all([refetchPortfolio(), refetchStatistics()])}
      showEmptyState={true}
      isEmptyState={!isLoadingPortfolio && !portfolioItems?.length}
      textTranslation={textTranslation}
      customEmptyStateComponent={
        <DataStatusPage
          icon={
            <ThemedIcon
              icon={TrendUpWithBackgroundIcon}
              customStrokeColor={theme.v2.text.action2}
              customColor={theme.v2.surface.action2Light}
              size={'full'}
            />
          }
          title={textTranslation('portfolio.noBonds')}
          content={textTranslation('portfolio.noBondsPurchased')}
          buttonText={textTranslation('portfolio.startInvesting')}
          action={() => navigate(routes.offering.smartBonds.root)}
          additionalContent={textTranslation('portfolio.noBondsFollow')}
          iconWidth={'8.5rem'}
          iconHeight={'8.5rem'}
        />
      }
    >
      <PortfolioBanner
        type={ProductType.BOND_PAPERS}
        heading={textTranslation('portfolio.investInSmartBonds')}
        text={textTranslation('portfolio.fullyDigitalizedIncome')}
      />
      <PortfolioCard
        title={textTranslation('portfolio.bondBalance')}
        count={statistics?.totalBondsBought || 0}
        bond={{
          priceReturn: statistics?.totalReturnOfInvestment || '',
          priceCoupon: statistics?.totalAnnualCoupons || '',
        }}
        description={textTranslation('portfolio.bonds')}
        titleCurrency={swissCode}
        price={statistics?.totalInvestedAmount?.split(' ')[0] || '0'}
        type={TransactionType.BOND}
      />
      <HistoryWrapper>
        <Text fontSize={'lg'} lineHeight={'h4'} fontWeight={'bold'} color={theme.v2.text.headingPrimary}>
          {textTranslation('portfolio.smartBondBuyingHistory')}
        </Text>
        {portfolioTotalItems > numberOfDisplayedBonds && (
          <TextLink
            padding={'0.25rem'}
            onClick={() => navigate(routes.portfolio.smartBonds.bondHistory)}
          >{`${textTranslation('portfolio.viewAll')} (${formatAmountWithoutDecimals(portfolioTotalItems)})`}</TextLink>
        )}
      </HistoryWrapper>
      <TransactionsContainer>
        {portfolioItems?.map((portfolioBondAsset) => {
          return (
            <TransactionCardWrapper key={portfolioBondAsset.id}>
              <PortfolioTransactionCard
                headerLabel={textTranslation('portfolio.annualInterest')}
                headerValue={`${portfolioBondAsset.annualInterest}%`}
                quantityLabel={textTranslation('portfolio.quantity')}
                quantity={formatAmountWithoutDecimals(portfolioBondAsset.bondsBought)}
                amountLabel={textTranslation('portfolio.amount')}
                amount={portfolioBondAsset.investedAmount}
                issuerInfo={{
                  name: portfolioBondAsset.issuerName,
                  logoUrl: portfolioBondAsset.issuerLogoUrl,
                }}
                icon={ArrowChartIcon}
                onClick={() => navigate(`${routes.portfolio.smartBonds.root}/${portfolioBondAsset.id}`)}
              />
            </TransactionCardWrapper>
          );
        })}
      </TransactionsContainer>
      {portfolioTotalItems > numberOfDisplayedBonds && (
        <IndicatorGroup>
          <ItemsCounter
            displayedItems={numberOfDisplayedBonds}
            totalItems={portfolioTotalItems}
            ofText={textTranslation('portfolio.of')}
            itemsText={textTranslation('portfolio.assets')}
            displayedItemsText={formatAmountWithoutDecimals(numberOfDisplayedBonds)}
            totalItemsText={formatAmountWithoutDecimals(portfolioTotalItems)}
          />

          <Button
            onClick={() => navigate(routes.portfolio.smartBonds.bondHistory)}
            text={`${textTranslation('portfolio.viewAll')} (${formatAmountWithoutDecimals(portfolioTotalItems)})`}
            fill
          />
        </IndicatorGroup>
      )}
    </PageStateContainer>
  );
};
