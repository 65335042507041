import styled from 'styled-components';

import { TypographySize } from '../../../../../theme';

export const StyledLabelSpan = styled.span<{
  $fontSize: TypographySize;
  $textAlign?: 'start' | 'center' | 'end' | 'left' | 'right' | 'justify';
  $checked?: boolean;
}>`
  font-size: ${({ theme, $fontSize }) => theme.typography.sizes[$fontSize]};
  font-weight: ${({ theme, $checked }) => ($checked ? theme.typography.weights.bold : theme.typography.weights.reg)};
  line-height: ${({ theme }) => theme.typography.lineHeight.base};
  margin-left: 0.75rem;
  display: inline-block;
  text-align: ${({ $textAlign }) => $textAlign};
  color: ${({ theme, $checked }) => ($checked ? theme.v2.text.information : theme.v2.text.bodyPrimary)};
`;
