import { ExpendableCard, Text } from '@metaswiss/ui-kit';
import { useCallback, useState } from 'react';

import { IdentityVerification } from '../../../../../../../../enums/identityVerification';
import { useTextTranslation } from '../../../../../../../../hooks/use-text-translation/useTextTranslation';
import { useAuthContext } from '../../../../../../shared/auth-context/authContext';
import { useSetAuthConfig } from '../../../../../../shared/auth-context/useSetAuthConfig';
import { RegisterHeader } from '../../../../../shared/register-header/RegisterHeader';
import { ExpendableCardWrapper } from '../../../../set-user-type-registration/setUserTypeRegistration.styles';
import { FullPrivateRegistrationData, initialFullPrivateRegistrationValues } from '../fullPrivateRegistrationState';

import { registrationSelectIdenityVerificationData } from './selectIdentityVerificationTypeData';

export const SelectIdentityVerification = () => {
  const { textTranslation } = useTextTranslation();
  const { setUserDetails, prevStep, nextStep } = useAuthContext((state) => state);
  const userDetails = useAuthContext((state) => state.userDetails) as FullPrivateRegistrationData;
  const [selectedIdentityVerification, setSelectedIdentityVerification] = useState<IdentityVerification>(
    userDetails.identityVerification
  );

  const handleSetIdentityVerification = useCallback(
    (identityVerification: IdentityVerification) => {
      setSelectedIdentityVerification(identityVerification);
      setUserDetails({
        identityVerification: identityVerification,
        identityDocument:
          identityVerification !== userDetails.identityVerification
            ? initialFullPrivateRegistrationValues.identityDocument
            : { ...userDetails.identityDocument },
      });
    },
    [setUserDetails, userDetails]
  );

  useSetAuthConfig(
    {
      wrapperStyle: 'registrationWizard',
      containerStyle: 'registrationWizard',
      backButton: {
        onClick: () => prevStep(),
        text: 'button.back',
        dataAttributes: { dataTestid: 'back-button' },
      },
      nextButton: {
        text: 'button.next',
        onClick: () => nextStep(),
        dataAttributes: { dataTestid: 'next-button' },
      },
      showStepper: true,
    },
    [nextStep, prevStep]
  );

  return (
    <>
      <RegisterHeader descriptionText={textTranslation('registration.identityVerificationHeader')} />
      <ExpendableCardWrapper>
        {registrationSelectIdenityVerificationData.map(({ value, title, description }) => (
          <ExpendableCard
            key={value}
            dataAttributes={{ dataTestid: `${value.toLowerCase()}-button` }}
            title={textTranslation(title)}
            isSelected={selectedIdentityVerification === value}
            onSelect={() => handleSetIdentityVerification(value)}
            radioButtonSize={'large'}
          >
            <Text>{textTranslation(description)}</Text>
          </ExpendableCard>
        ))}
      </ExpendableCardWrapper>
    </>
  );
};
