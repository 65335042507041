import styled from 'styled-components';

export const TooltipHolder = styled.div<{
  $backgroundColor?: string;
}>`
  position: absolute;
  top: -0.5rem;
  right: -0.8rem;
  transform: translateY(-50%);
  z-index: 3;
  max-width: fit-content;
`;

export const TooltipContent = styled.div<{ $backgroundColor?: string }>`
  border-radius: 0.5rem;
  padding: 0.5rem 0.75rem;
  display: flex;
  align-items: center;
  background-color: ${({ theme, $backgroundColor }) => $backgroundColor || theme.v2.surface.tertiary};
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const HoverIconWrapper = styled.div`
  width: 1.5rem;
  height: 1.5rem;
`;

export const DocumentDownloadWrapper = styled.div`
  display: block;
  :hover {
    cursor: pointer;
  }
`;
