import { DataAttributes, convertToDataAttributes } from '@metaswiss/lib';
import { ChangeEvent, useMemo } from 'react';
import { useTheme } from 'styled-components';

import { TypographyTextSize, TypographyWeight } from '../../../theme/theme';
import { Text } from '../text';

import { HiddenRadio } from './styles/hiddenRadio';
import { RadioContainer } from './styles/radioContainer';
import { RadioLabel } from './styles/radioLabel';

export type LabelOption = {
  id: string;
  value: string | JSX.Element;
};

export type RadioButtonProps = {
  onSelect: (e: ChangeEvent<HTMLInputElement>) => void;
  selected: LabelOption | undefined;
  error?: boolean;
  size?: 'medium' | 'large';
  label: LabelOption;
  disabled?: boolean;
  zIndex?: boolean;
  textSize?: TypographyTextSize;
  textWeight?: TypographyWeight;
  dataAttributes?: DataAttributes;
};

export const RadioButton: React.FC<RadioButtonProps> = ({
  onSelect,
  selected,
  size = 'medium',
  label,
  error,
  disabled = false,
  zIndex = false,
  textSize = 'base',
  textWeight = 'reg',
  dataAttributes,
}) => {
  const theme = useTheme();
  const isActive = useMemo(() => selected?.id === label.id, [selected, label]);
  return (
    <RadioContainer $disabled={disabled}>
      <RadioLabel
        $zIndex={zIndex}
        $size={theme.icon.sizes[size]}
        $checked={isActive}
        $disabled={disabled}
        $isError={error}
      >
        <HiddenRadio
          $size={theme.icon.sizes[size]}
          onChange={(e: ChangeEvent<HTMLInputElement>) => onSelect(e)}
          {...convertToDataAttributes(dataAttributes)}
        />
      </RadioLabel>
      {label.value && (
        <Text fontSize={textSize} fontWeight={textWeight} color={theme.v2.text.bodyPrimary}>
          {label.value}
        </Text>
      )}
    </RadioContainer>
  );
};
