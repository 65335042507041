import styled, { css } from 'styled-components';

const sliderButtonStyles = css`
  z-index: 1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

export const CarouselWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const SliderContainer = styled.div`
  overflow: hidden;
`;

export const SlidesWrapper = styled.div<{ $hasSteps?: boolean }>`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const SingleSlideWrapper = styled.div<{ $hasSteps?: boolean; $showAdjacentSlides: boolean }>`
  width: ${({ $hasSteps, $showAdjacentSlides }) =>
    $hasSteps && $showAdjacentSlides ? 'calc(100% - 5.25rem)' : '100%'};
  transform: translateX(${({ $hasSteps, $showAdjacentSlides }) => $hasSteps && $showAdjacentSlides && '2.625rem'});
  flex-shrink: 0;
`;

export const LeftSliderButtonWrapper = styled.div`
  ${sliderButtonStyles}
  left: 0.375rem;
`;

export const RightSliderButtonWrapper = styled.div`
  ${sliderButtonStyles}
  right: 0.375rem;
`;

export const SliderButton = styled.div`
  cursor: pointer;
  width: fit-content;
  padding: 0.25rem;
  background-color: ${({ theme }) => theme.v2.surface.primary};
  border: 1px solid ${({ theme }) => theme.v2.border.primary};
  border-radius: ${({ theme }) => theme.v2.componentsRadius.cards.medium};
  box-shadow: ${({ theme }) => `0px 0px 16px 0px ${theme.v2.colors.black}0A,
      0px 1px 0px 0px ${theme.v2.colors.black}0A,
      0px 10px 24px 0px ${theme.v2.colors.black}0A;`};

  &:hover {
    box-shadow: none;
  }
`;

export const StepperWrapper = styled.div<{ $placeSlideButtonsOutside: boolean }>`
  position: absolute;
  left: 50%;
  transform: translate(-50%, ${({ $placeSlideButtonsOutside }) => ($placeSlideButtonsOutside ? '0.5rem' : '-1.5rem')});
`;
