import { DataAttributes, convertToDataAttributes } from '@metaswiss/lib';
import { ReactNode } from 'react';
import { useTheme } from 'styled-components';

import { TextTemplate } from '../../../shared/typography/TextTemplate.styles';
import { TextSizeProps } from '../../../shared/typography/textSizeProps';

export type TextProps = TextSizeProps & {
  children: ReactNode;
  textAlign?: 'start' | 'end' | 'center' | 'justify';
  dataAttributes?: DataAttributes;
};

export const Text = ({
  children,
  palette = 'neutrals',
  textColor = 'hue700',
  fontSize = 'base',
  fontWeight = 'reg',
  lineHeight = 'base',
  color,
  textAlign,
  dataAttributes,
}: TextProps) => {
  const theme = useTheme();

  return (
    <TextTemplate
      as="span"
      $textColor={textColor}
      $palette={palette}
      $fontSize={fontSize}
      {...convertToDataAttributes(dataAttributes)}
      $fontWeight={fontWeight}
      $lineHeight={lineHeight}
      $color={color || theme.v2.text.bodyPrimary}
      $textAlign={textAlign}
    >
      {children}
    </TextTemplate>
  );
};
