/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { convertToDataAttributes } from '@metaswiss/lib';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import { useTheme } from 'styled-components';

import { DeleteIcon } from '../../../iconography';
import { DraggingPlusIcon } from '../../../iconography/DraggingPlusIcon';
import { ThemedIcon } from '../theme-icon';

import { Dropzone } from './Dropzone';
import { ViewFile } from './ViewFile';
import { AcceptedFileTypes, DropzoneStatus } from './enums';
import { DeleteContainer, UploadFileStyled, UploadFileZone } from './styles/uploadFile';
import { csvFileTypes, documentFileTypes, imageFileTypes, UploadFileProps } from './types';

export const UploadFile: FC<UploadFileProps> = ({
  $width = '600px',
  $height = '400px',
  id,
  isLoading,
  uploadFile,
  value,
  isError,
  onDelete,
  onViewDocument,
  className,
  disabled = false,
  viewFileTitle,
  viewFileSubtitle,
  initialTitle,
  initialSubtitle,
  invalidTitle,
  invalidSubtitle,
  failedTitle,
  failedSubtitle,
  acceptedFileTypes = AcceptedFileTypes.ALL,
  imgContainerPadding,
  dataAttributes,
}) => {
  const theme = useTheme();
  const [isUploadError, setIsUploadError] = useState<boolean>(false);
  const [isDropRejected, setIsDropRejected] = useState<boolean>(false);
  const [dropzoneStatus, setDropzoneStatus] = useState<DropzoneStatus | undefined>(DropzoneStatus.INITIAL);
  const dropzoneRef = useRef<HTMLDivElement | null>(null);

  const onDropAccepted = useCallback((acceptedFiles: File[]) => {
    if (!acceptedFiles[0]) {
      setIsUploadError(true);
      return;
    }

    uploadFile!(acceptedFiles[0]);
    setIsUploadError(false);
    setIsDropRejected(false);
  }, []);

  const onDropRejected = useCallback(() => {
    setIsDropRejected(true);
  }, [isDropRejected]);

  useEffect(() => {
    setIsUploadError(!!isError);
  }, [isError]);

  useEffect(() => {
    determineError();
  }, [isUploadError, isDropRejected, isLoading]);

  const acceptFileTypes: Accept = useMemo(() => {
    switch (acceptedFileTypes) {
      case AcceptedFileTypes.IMAGES:
        return imageFileTypes(acceptedFileTypes);
      case AcceptedFileTypes.DOCUMENTS:
        return documentFileTypes(acceptedFileTypes);
      case AcceptedFileTypes.CSV:
        return csvFileTypes(acceptedFileTypes);
      case AcceptedFileTypes.ALL:
      default:
        return {
          ...csvFileTypes(acceptedFileTypes),
          ...documentFileTypes(acceptedFileTypes),
          ...imageFileTypes(acceptedFileTypes),
        };
    }
  }, [acceptedFileTypes]);

  const { getRootProps, getInputProps, isDragAccept, isDragReject, isDragActive } = useDropzone({
    accept: acceptFileTypes,
    maxFiles: 1,
    maxSize: 10 * 1000000,
    onDropAccepted,
    onDropRejected,
    disabled,
  });

  const determineError = () => {
    const newStatus = isUploadError
      ? DropzoneStatus.FAILED
      : isDropRejected
        ? DropzoneStatus.INVALID
        : isLoading
          ? DropzoneStatus.LOADING
          : DropzoneStatus.INITIAL;
    setDropzoneStatus(newStatus);
  };

  const isImage = useMemo(() => {
    if (!value?.url) return undefined;

    const fileType = value?.url?.split('.').pop()?.split('?')[0];
    return fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png' || fileType === 'gif' || fileType === 'svg';
  }, [value]);

  return (
    <UploadFileStyled
      className={className}
      $height={$height}
      $width={$width}
      $isLoading={isLoading}
      $isError={isUploadError || isDropRejected}
      $isFileUploaded={!!value?.fileId}
      $isDisabled={disabled}
      key={id}
      {...getRootProps({ isDragAccept, isDragReject, isDragActive, ref: dropzoneRef })}
    >
      {!isDragActive && !value?.fileId && (
        <>
          <input {...getInputProps()} {...convertToDataAttributes(dataAttributes)} />
          <UploadFileZone $isDragActive={isDragActive}>
            <Dropzone
              dropzoneStatus={dropzoneStatus}
              initialTitle={initialTitle}
              initialSubtitle={initialSubtitle}
              invalidTitle={invalidTitle}
              invalidSubtitle={invalidSubtitle}
              failedTitle={failedTitle}
              failedSubtitle={failedSubtitle}
            />
            {value?.fileId ||
              (dropzoneStatus === DropzoneStatus.FAILED && (
                <DeleteContainer
                  onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                    e.stopPropagation();
                    if (!disabled) {
                      onDelete?.(value?.fileId || '');
                    }
                  }}
                  $isDisabled={disabled}
                >
                  <ThemedIcon icon={DeleteIcon} customColor={theme.v2.icon.neutral} />
                </DeleteContainer>
              ))}
          </UploadFileZone>
        </>
      )}

      {isDragActive && (
        <UploadFileZone $isDragActive={isDragActive}>
          <DraggingPlusIcon color={theme.v2.icon.primary} />
        </UploadFileZone>
      )}
      {!isDragActive && value?.fileId && (
        <ViewFile
          onClick={() => !disabled && onViewDocument()}
          title={viewFileTitle}
          subtitle={viewFileSubtitle}
          isImage={isImage}
          url={value.url ?? ''}
          imgContainerPadding={imgContainerPadding}
          isDisabled={disabled}
        />
      )}
      {(value?.fileId || isUploadError || isDropRejected) && (
        <DeleteContainer
          onClick={(e: React.MouseEvent<HTMLDivElement>) => {
            if (!disabled) {
              onDelete?.(value!.fileId ?? '');
              setDropzoneStatus(DropzoneStatus.INITIAL);
              setIsUploadError(false);
              setIsDropRejected(false);
              dropzoneRef?.current?.blur();
            }
            e.stopPropagation();
          }}
          $isDisabled={disabled}
        >
          <ThemedIcon icon={DeleteIcon} customColor={theme.v2.icon.neutral} />
        </DeleteContainer>
      )}
    </UploadFileStyled>
  );
};
