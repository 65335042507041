import styled from 'styled-components';

import { breakpoints } from '../../../breakpoints';
import { RemSize } from '../../../theme';

import { ModalPosition } from './BaseModal';

export const StyledBackdrop = styled.div<{ $isOpen: boolean; $position: ModalPosition }>`
  position: ${({ $position }) => $position};
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 2vw;
  visibility: ${({ $isOpen }) => ($isOpen ? 'visible' : 'hidden')};
  opacity: ${({ $isOpen }) => ($isOpen ? 1 : 0)};
  transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  z-index: 999;
`;

export const StyledModal = styled.div<{
  $width?: RemSize | '100%' | 'fit-content';
  $height?: RemSize | '100%' | 'fit-content';
  $padding?: RemSize | `${RemSize} ${RemSize}` | `${RemSize} ${RemSize} ${RemSize} ${RemSize}`;
  $smallDevicesWidth?: string;
  $borderRadius: string;
  overflow?: 'auto' | 'hidden';
}>`
  position: absolute;
  top: 50%;
  left: 50%;
  overflow-y: auto;
  width: ${({ $smallDevicesWidth }) => $smallDevicesWidth};
  height: ${({ $height }) => $height};
  transform: translate(-50%, -50%);
  background-color: ${({ theme }) => theme.v2.surface.primary};
  border-radius: ${({ $borderRadius }) => $borderRadius};
  border: 1px solid ${({ theme }) => theme.v2.border.primary};
  padding: ${({ $padding }) => $padding ?? '1.5rem'};
  margin: 0 auto;

  @media ${breakpoints.laptopSm} {
    width: ${({ $width }) => $width};
  }
`;
