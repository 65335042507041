import { useMemo } from 'react';
import { useTheme } from 'styled-components';

import { BaseCard, Chip, Text } from '../../../../components';

import { DateContainer, DescriptionContainer, TitleContainer } from './caseCard.styles';
import { CaseCardProps } from './types';

export const CaseCard = ({
  status = 'neutrals',
  title,
  titleSize = 'base',
  titleWeight = 'bold',
  titleLineHeight = 'base',
  date,
  dateSize = 'sm',
  dateWeight = 'reg',
  dateLineHeight = 'medium',
  description,
  descriptionSize = 'sm',
  descriptionWeight = 'reg',
  descriptionLineHeight = 'medium',
  chipText,
  ...props
}: CaseCardProps) => {
  const theme = useTheme();
  const statusChip = useMemo(() => getCardChip(status), [chipText]);

  return (
    <BaseCard
      removeBackgroundHover={true}
      hasCaseCardShadow={true}
      height={'9.25rem'}
      {...props}
      dataAttributes={{ dataTestid: ' cases-singlecase-button' }}
      override={{
        borderRadius: theme.v2.componentsRadius.cards.default,
        backgroundColor: theme.v2.surface.primary,
        borderColor: theme.v2.border.primary,
        activeBackgroundColor: theme.v2.surface.primary,
        activeBorderColor: theme.v2.border.primary,
        hoverBorderColor: theme.v2.border.primary,
      }}
    >
      <TitleContainer>
        <Text fontSize={titleSize} fontWeight={titleWeight} lineHeight={titleLineHeight}>
          {title}
        </Text>
        <Chip label={chipText} status={statusChip} />
      </TitleContainer>
      <DateContainer>
        <Text fontSize={dateSize} fontWeight={dateWeight} lineHeight={dateLineHeight}>
          {date}
        </Text>
      </DateContainer>
      <DescriptionContainer>
        <Text fontSize={descriptionSize} fontWeight={descriptionWeight} lineHeight={descriptionLineHeight}>
          {description}
        </Text>
      </DescriptionContainer>
    </BaseCard>
  );
};

const getCardChip = (status: string) => {
  switch (status) {
    case 'neutrals': {
      return 'neutral';
    }
    case 'error': {
      return 'error';
    }
    case 'warning': {
      return 'warning';
    }
    case 'success': {
      return 'success';
    }
    case 'primary': {
      return 'primary';
    }
    case 'secondary': {
      return 'secondary';
    }
    default:
      throw new Error('Unsupported status');
  }
};
