import { Dropdown } from '@metaswiss/ui-kit';
import { DropdownItem } from '@metaswiss/ui-kit/src/components/atoms/dropdown/Dropdown.tsx';
import { getCountryFlagHelper } from 'admin/src/shared/helpers/getCountryFlag.helper.ts';
import styled from 'styled-components';

import { Languages } from '../../enums/language.ts';
import { useTextTranslation } from '../../hooks/use-text-translation/useTextTranslation.ts';

const optionsLang: DropdownItem[] = [
  { id: '1', label: 'EN', icon: getCountryFlagHelper('gb') },
  { id: '2', label: 'DE', icon: getCountryFlagHelper('de') },
];

const LanguagePickerContainer = styled.div`
  position: absolute;
  top: 3rem;
  right: 3rem;
  z-index: 5;
`;

const LanguagePicker = () => {
  const { setTranslationLanguage, currentLanguage } = useTextTranslation();

  return (
    <LanguagePickerContainer>
      <Dropdown
        width="small"
        height="large"
        displayType="textWithIcon"
        disableFloat
        options={optionsLang}
        selectedItems={optionsLang.filter(
          (option) => (option.label as string) === currentLanguage?.toUpperCase() || ''
        )}
        onSelect={(value: DropdownItem[]) => {
          if (value.length) {
            console.log((value[0].label as string).toLowerCase());
            setTranslationLanguage((value[0].label as string).toLowerCase() as Languages);
          }
        }}
        dataAttributes={{ dataTestid: 'language-select' }}
        label={''}
      />
    </LanguagePickerContainer>
  );
};

export default LanguagePicker;
