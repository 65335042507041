import { useQueryClient } from '@tanstack/react-query';
import { useLayoutEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { ProductType } from '../../../../enums/productType.enum';
import { ApiResource } from '../../../../enums/resource.enum';
import { useDefineBackgroundColors } from '../../../../hooks/use-define-background-colors/useDefineBackgroundColors';
import { getQueryKey } from '../../../../shared/helpers/getQueryKey.helper';

export const BondsPortfolio = () => {
  const queryClient = useQueryClient();

  useLayoutEffect(() => {
    queryClient.invalidateQueries({ queryKey: getQueryKey(ApiResource.USER_INVESTED_PORTFOLIO) });
    queryClient.invalidateQueries({ queryKey: getQueryKey(ApiResource.PORTFOLIO) });
    queryClient.invalidateQueries({ queryKey: getQueryKey(ApiResource.PORTFOLIO, ProductType.BOND_PAPERS) });
    queryClient.invalidateQueries({ queryKey: getQueryKey(ApiResource.BOND_PORTFOLIO_STATISTICS) });
  }, []);

  useDefineBackgroundColors('purple', 'purple');

  return <Outlet />;
};
