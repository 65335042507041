import { StyledIconButton } from './styles/styledIconButton';

// TODO: add size prop
export type BaseButtonProps = {
  icon: JSX.Element;
  // if onClick not provided, treat button as disabled
  onClick?: () => void;
  padding?: string;
  radius: string;
  borderWidth: string;
  selectedButton?: boolean;
  dataAttribute?: string;
};

export const BaseIconButton: React.FC<BaseButtonProps> = ({
  icon,
  onClick,
  radius,
  borderWidth,
  dataAttribute,
  selectedButton = false,
  padding = '0.5rem',
}) => {
  return (
    <StyledIconButton
      $padding={padding}
      $selectedButton={selectedButton}
      $radius={radius}
      $borderWidth={borderWidth}
      disabled={typeof onClick !== 'function'}
      onClick={() => onClick?.()}
      data-testid={dataAttribute}
    >
      {icon}
    </StyledIconButton>
  );
};
