import { convertToDataAttributes } from '@metaswiss/lib';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from 'styled-components';

import { CloseSmallIcon } from '../../../iconography';
import { Text } from '../../atoms/text';
import { ThemedIcon } from '../theme-icon';

import { CloseToast } from './styles/closeToast';
import { ToastContainer } from './styles/container';
import { CountDown } from './styles/countDown';
import { ParagraphContainer } from './styles/paragraphContainer';
import { ToastProps } from './types';

export const Toast = ({
  backgroundColor,
  heading,
  headingColor,
  headingSize = 'lg',
  headingWeight = 'semi',
  message,
  messageColor,
  messageSize = 'base',
  messageWeight = 'reg',
  borderRadius = 'medium',
  borderColor,
  renderToastIcon,
  id,
  dataAttributes,
  clearToast,
}: ToastProps) => {
  const theme = useTheme();
  const clearToastTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const [isToastOpen, setIsToastOpen] = useState<boolean>(false);
  const [isToastTranslated, setIsToastTranslated] = useState<boolean>(false);

  const toastIcon = renderToastIcon({});

  const selectedBorderRadiusToNum = useMemo(
    () => Number(theme.dimensions.borderRadii[borderRadius].split('rem')[0]),
    [borderRadius, theme.dimensions.borderRadii]
  );
  const containerBorderRadius = useMemo(
    () => (selectedBorderRadiusToNum > 1.5 ? '1.5rem' : theme.dimensions.borderRadii[borderRadius]),
    [borderRadius, selectedBorderRadiusToNum, theme.dimensions.borderRadii]
  );

  const handleToastClose = useCallback(() => {
    setIsToastOpen(false);

    clearToastTimeoutRef.current = setTimeout(() => {
      clearToast(id);
    }, 350);
  }, [id]);

  useEffect(() => {
    const timeouts = [
      setTimeout(() => setIsToastOpen(true), 25),
      setTimeout(() => setIsToastTranslated(true), 250),
      setTimeout(handleToastClose, theme.transitions.durations.toast),
    ];

    return () => {
      timeouts.forEach(clearTimeout);
      if (clearToastTimeoutRef.current) {
        clearTimeout(clearToastTimeoutRef.current);
      }
    };
  }, [handleToastClose]);

  return (
    <ToastContainer
      $isToastOpen={isToastOpen}
      $backgroundColor={backgroundColor}
      $borderRadius={containerBorderRadius}
      $borderColor={borderColor}
      id={id}
      {...convertToDataAttributes(dataAttributes)}
    >
      {toastIcon}
      <Text color={headingColor} fontSize={headingSize} fontWeight={headingWeight}>
        {heading}
      </Text>
      <CloseToast aria-label="CloseIcon Toast" onClick={handleToastClose}>
        <ThemedIcon icon={CloseSmallIcon} size="full" customStrokeColor={borderColor} />
      </CloseToast>
      <ParagraphContainer>
        <Text color={messageColor} fontSize={messageSize} fontWeight={messageWeight}>
          {message}
        </Text>
      </ParagraphContainer>
      <CountDown $backgroundColor={borderColor} $isToastOpen={isToastOpen} $isToastTranslated={isToastTranslated} />
    </ToastContainer>
  );
};
