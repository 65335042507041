import { BaseCard, Chip, CollectibleIcon, Text, ThemedIcon } from '@metaswiss/ui-kit';
import { ArrowRightIcon } from '@metaswiss/ui-kit/src/iconography/ArrowRightIcon';
import { BondTransactionIcon } from '@metaswiss/ui-kit/src/iconography/BondTransactionIcon';
import { ShareTransactionIcon } from '@metaswiss/ui-kit/src/iconography/ShareTransactionIcon';
import { useTextTranslation } from 'client/src/hooks/use-text-translation/useTextTranslation';
import { ComponentProps, FC, useMemo } from 'react';
import { useTheme } from 'styled-components';

import { ProductType, TransactionStatus } from './enums';
import {
  HeaderContainer,
  IconWrapper,
  ProductInfoContainer,
  ProductTypeArrow,
  ProductTypeDot,
  ProductTypeName,
  ProductTypeValuesLabel,
  TransactionCardHeader,
  TransactionInfoWrapper,
} from './transactionCard.styles';
import { ProductDataType, TransactionCardProps } from './types';

export const TransactionCard: FC<TransactionCardProps> = ({
  transaction,
  valueLabel,
  quantityLabel,
  smartLabel,
  createdAt,
  onClick,
}) => {
  const theme = useTheme();
  const { textTranslation } = useTextTranslation();

  const SelectedChip = useMemo(() => {
    if (transaction?.status) {
      return getCardChip(transaction.status, textTranslation);
    }
  }, [transaction]);

  const productData = useMemo(() => {
    const getDataByType = (productType: string) => {
      switch (productType) {
        case ProductType.ACTIONS:
          return {
            icon: ShareTransactionIcon,
            color: theme.v2.icon.primary,
            type: 'Share',
          };
        case ProductType.BOND_PAPERS:
          return {
            icon: BondTransactionIcon,
            color: theme.v2.icon.secondary,
            type: 'Bond',
          };
        case ProductType.NFT:
          return {
            icon: CollectibleIcon,
            color: theme.v2.icon.tertiary,
            type: 'NFT',
          };
        default:
          return { icon: ShareTransactionIcon, color: theme.v2.icon.primary, type: 'Share' };
      }
    };
    return getDataByType(transaction.type);
  }, [transaction]) as ProductDataType;

  return (
    <BaseCard
      padding="0rem"
      disableStateEffects
      borderBox={true}
      hasCursorPointer={true}
      onClick={onClick}
      dataAttributes={{ dataTestid: 'transactions-transaction-button' }}
      override={{
        backgroundColor: theme.v2.surface.primary,
        borderColor: theme.v2.border.primary,
        borderRadius: theme.v2.componentsRadius.cards.default,
      }}
    >
      <HeaderContainer>
        <TransactionCardHeader>
          <Text fontSize={'sm'} fontWeight={'bold'} lineHeight={'medium'} color={theme.v2.text.headingPrimary}>
            {createdAt}
          </Text>
          {SelectedChip}
        </TransactionCardHeader>
      </HeaderContainer>
      <ProductInfoContainer>
        <ProductTypeName>
          <ProductTypeDot color={productData.color} />
          <Text
            dataAttributes={{ dataTestid: ' transactions-producttype' }}
            lineHeight={'medium'}
            fontSize={'sm'}
            color={theme.v2.text.bodyPrimary}
          >{`${smartLabel} ${
            productData.type === 'NFT' ? textTranslation('portfolio.collectible') : productData.type
          }`}</Text>
        </ProductTypeName>
        <TransactionInfoWrapper>
          <IconWrapper>
            <ThemedIcon icon={productData.icon} size="large" customStrokeColor={theme.v2.icon.default} />
          </IconWrapper>
          <ProductTypeValuesLabel>
            <Text lineHeight={'medium'} fontSize={'sm'} color={theme.v2.text.bodyPrimary}>
              {valueLabel}
            </Text>
            <Text lineHeight={'medium'} fontSize={'sm'} color={theme.v2.text.bodyPrimary}>
              {quantityLabel}
            </Text>
          </ProductTypeValuesLabel>
          <ProductTypeValuesLabel>
            <Text
              lineHeight={'medium'}
              fontSize={'sm'}
              fontWeight={'bold'}
              color={theme.v2.text.bodyPrimary}
              dataAttributes={{ dataTestid: 'transaction-value' }}
            >
              {transaction.totalAmount}
            </Text>
            <Text
              lineHeight={'medium'}
              fontSize={'sm'}
              fontWeight={'bold'}
              color={theme.v2.text.bodyPrimary}
              dataAttributes={{ dataTestid: 'transactions-quantity' }}
            >
              {transaction.quantity.toString()}
            </Text>
          </ProductTypeValuesLabel>
          <ProductTypeArrow>
            <ThemedIcon icon={ArrowRightIcon} customStrokeColor={theme.v2.icon.default} />
          </ProductTypeArrow>
        </TransactionInfoWrapper>
      </ProductInfoContainer>
    </BaseCard>
  );
};

const getCardChip = (status: string, textTranslation: (key: string) => string) => {
  let statusChip: ComponentProps<typeof Chip>['status'];
  const label = status;
  switch (status) {
    case TransactionStatus.ACCEPTED:
      statusChip = 'primary';
      break;
    case TransactionStatus.FAILED:
      statusChip = 'error';
      break;
    case TransactionStatus.CANCELLED:
      statusChip = 'error';
      break;
    case TransactionStatus.PENDING:
      statusChip = 'warning';
      break;
    case TransactionStatus.VALIDATING:
      statusChip = 'neutral';
      break;
    case TransactionStatus.STRIPE_PENDING:
      statusChip = 'warning';
      break;
    default:
      statusChip = 'neutral';
      break;
  }

  return <Chip label={textTranslation(`account.${label.toLowerCase()}`)} status={statusChip} />;
};
