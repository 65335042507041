import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { Stepper } from '../../../../components';
import { ChevronLeftIcon, ChevronRightIcon } from '../../../../iconography';

import { SlideButton } from './components/slide-button/SlideButton';
import {
  LeftSliderButtonWrapper,
  RightSliderButtonWrapper,
  CarouselWrapper,
  SlidesWrapper,
  StepperWrapper,
  SliderContainer,
  SingleSlideWrapper,
} from './infiniteCarousel.styles';
import { CarouselConfigProps, CarouselStep, InfiniteCarouselProps } from './types';

const sliderTransition = (duration: number) => `transform ${duration}s ease-in-out`;
const initialConfig: Required<CarouselConfigProps> = {
  autoSlide: true,
  interval: 3000,
  displayArrowButtons: true,
  slideButtonsClickable: false,
  placeSlideButtonsOutside: true,
  showAdjacentSlides: true,
  transitionDuration: 0.25,
};

export const InfiniteCarousel = ({ steps, config }: InfiniteCarouselProps) => {
  const [areButtonsDisabled, setAreButtonsDisabled] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [displayedSteps, setDisplayedSteps] = useState<CarouselStep[]>([]);

  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const slidesWrapperRef = useRef<HTMLDivElement | null>(null);

  const numberOfSteps = steps.length;
  const carouselHasSteps = numberOfSteps > 1;
  const {
    autoSlide,
    interval,
    displayArrowButtons,
    slideButtonsClickable,
    placeSlideButtonsOutside,
    showAdjacentSlides,
    transitionDuration,
  } = useMemo(() => ({ ...initialConfig, ...config }), [config]);

  const handleSliderButtonsClick = () => {
    if (slidesWrapperRef.current) {
      slidesWrapperRef.current.style.transition = sliderTransition(transitionDuration);
    }

    setAreButtonsDisabled(true);
  };

  const onNextSlideClick = () => {
    if (areButtonsDisabled) return;

    handleSliderButtonsClick();
    setCurrentStep((prevState) => prevState + 1);
    resetAutoSlideInterval();
  };

  const onPrevSlideClick = () => {
    if (areButtonsDisabled) return;

    handleSliderButtonsClick();
    setCurrentStep((prevState) => prevState - 1);
    resetAutoSlideInterval();
  };

  const onSlideFinished = () => {
    if (slidesWrapperRef.current) {
      slidesWrapperRef.current.style.transition = 'none';

      if (currentStep >= numberOfSteps + 1) {
        setCurrentStep(1);
      }

      if (currentStep <= 0) {
        setCurrentStep(numberOfSteps);
      }

      setTimeout(() => {
        if (slidesWrapperRef.current) {
          slidesWrapperRef.current.style.transition = sliderTransition(transitionDuration);
        }
      }, 50);
    }

    setAreButtonsDisabled(false);
  };

  const startAutoSlideInterval = useCallback(() => {
    if (autoSlide && carouselHasSteps) {
      if (intervalRef.current) clearInterval(intervalRef.current);
      intervalRef.current = setInterval(onNextSlideClick, interval);
    }
  }, [autoSlide, carouselHasSteps, interval, onNextSlideClick]);

  const resetAutoSlideInterval = useCallback(() => {
    if (intervalRef.current) clearInterval(intervalRef.current);
    startAutoSlideInterval();
  }, [startAutoSlideInterval]);

  const translateSlider = useCallback(() => {
    if (slidesWrapperRef.current) {
      const slideOffset = showAdjacentSlides ? '4.25rem' : '-1rem';

      slidesWrapperRef.current.style.transform = `translateX(calc(${-currentStep} * (100% - ${slideOffset})))`;
    }
  }, [currentStep]);

  const handleStepClick = (stepIndex: number) => {
    setCurrentStep(stepIndex + 1);
    resetAutoSlideInterval();
  };

  useLayoutEffect(() => {
    setDisplayedSteps(steps.length > 1 ? [steps[numberOfSteps - 1], ...steps, steps[0]] : steps);
  }, [steps, numberOfSteps]);

  useLayoutEffect(() => {
    if (carouselHasSteps) {
      translateSlider();
    }
  }, [translateSlider, carouselHasSteps]);

  useEffect(() => {
    if (autoSlide && carouselHasSteps) {
      if (intervalRef.current) clearInterval(intervalRef.current);
      intervalRef.current = setInterval(onNextSlideClick, interval);
    }

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [startAutoSlideInterval]);

  return (
    <CarouselWrapper>
      <SliderContainer>
        <SlidesWrapper ref={slidesWrapperRef} $hasSteps={carouselHasSteps} onTransitionEnd={onSlideFinished}>
          {displayedSteps.map(({ element, id }, index) => {
            return (
              <SingleSlideWrapper
                key={`${id}${index}`}
                onTransitionEnd={(e) => e.stopPropagation()}
                $hasSteps={carouselHasSteps}
                $showAdjacentSlides={showAdjacentSlides}
              >
                {element}
              </SingleSlideWrapper>
            );
          })}
        </SlidesWrapper>
      </SliderContainer>
      {carouselHasSteps && displayArrowButtons && (
        <>
          <LeftSliderButtonWrapper>
            <SlideButton onClick={onPrevSlideClick} icon={ChevronLeftIcon} />
          </LeftSliderButtonWrapper>
          <RightSliderButtonWrapper>
            <SlideButton onClick={onNextSlideClick} icon={ChevronRightIcon} />
          </RightSliderButtonWrapper>
        </>
      )}
      {carouselHasSteps && (
        <StepperWrapper $placeSlideButtonsOutside={placeSlideButtonsOutside}>
          <Stepper
            currentStep={currentStep - 1}
            numberOfSteps={numberOfSteps}
            onClick={slideButtonsClickable ? handleStepClick : undefined}
          />
        </StepperWrapper>
      )}
    </CarouselWrapper>
  );
};
