import { Text, TextLink } from '@metaswiss/ui-kit';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { api } from '../../../../../../api/msApi';
import { ApiResource } from '../../../../../../enums/resource.enum';
import { useTextTranslation } from '../../../../../../hooks/use-text-translation/useTextTranslation';
import { routes } from '../../../../../../router/routes';
import { getFormattedLanguageName } from '../../../../../../shared/helpers/getFormattedLanguageName.helper';
import { getQueryKey } from '../../../../../../shared/helpers/getQueryKey.helper';

import {
  AboutUsCard,
  AboutUsContainer,
  AboutUsContent,
  AboutUsHeader,
  AboutUsTextContainer,
  VideoContainer,
} from './aboutUs.styles';

export const AboutUs = () => {
  const { textTranslation, currentLanguage } = useTextTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  const { data: aboutUs } = useQuery({
    queryKey: getQueryKey(ApiResource.ABOUT_US, currentLanguage),
    queryFn: () => api.contentful.getAboutUs(getFormattedLanguageName(currentLanguage)),
  });

  if (!aboutUs) {
    return null;
  }

  return (
    <AboutUsContainer>
      <AboutUsHeader>
        <Text fontSize={'lg'} fontWeight={'bold'} lineHeight="h4" color={theme.v2.text.headingPrimary}>
          {textTranslation('home.aboutUs')}
        </Text>
        <TextLink onClick={() => navigate(routes.home.aboutUs)} dataTestId="read-more-button">
          {textTranslation('home.readMore')}
        </TextLink>
      </AboutUsHeader>
      <AboutUsCard
        disableStateEffects
        override={{
          borderRadius: theme.v2.componentsRadius.cards.default,
          backgroundColor: theme.v2.surface.primary,
          borderColor: theme.v2.border.primary,
        }}
      >
        <AboutUsContent>
          {aboutUs?.videoUrl && (
            <VideoContainer>
              <iframe
                height="100%"
                width="100%"
                src={`https://www.youtube.com/embed/${aboutUs?.videoUrl?.split('/')[3]}`}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
                style={{
                  borderRadius: `${theme.v2.radius.smaller}`,
                  border: `1px solid ${theme.v2.border.primary}`,
                }}
              />
            </VideoContainer>
          )}
          <AboutUsTextContainer>
            <Text color={theme.v2.text.bodySecondary}>
              {aboutUs?.description?.map((item) => `${item.heading} ${item.description}`).join(' ')}
            </Text>
          </AboutUsTextContainer>
        </AboutUsContent>
      </AboutUsCard>
    </AboutUsContainer>
  );
};
