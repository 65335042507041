import { NftPortfolioAssetsResponse } from '@metaswiss/api';
import { DataStatusPage, FlatList, PageStateContainer, ThemedIcon, TrendUpCollectibleIcon } from '@metaswiss/ui-kit';
import { NftHolder } from '@metaswiss/ui-kit/src/components/molecules/nft-holder/NftHolder';
import { useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { api } from '../../../../../api/msApi';
import { NumberOfItems } from '../../../../../components/number-of-items/NumberOfItems';
import { ApiResource } from '../../../../../enums/resource.enum';
import { useHeaderOptions, useShellNavigationState } from '../../../../../global-state/zustand';
import { usePagination } from '../../../../../hooks/use-pagination/usePagination';
import { useTextTranslation } from '../../../../../hooks/use-text-translation/useTextTranslation';
import { routes } from '../../../../../router/routes';
import { getQueryKey } from '../../../../../shared/helpers/getQueryKey.helper';

export const CollectibleHistory = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { textTranslation } = useTextTranslation();
  const { removeTabs } = useShellNavigationState();
  const { setHeaderTitle, search } = useHeaderOptions();

  const {
    data: nftsData,
    lastItemRef,
    isError,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    totalItems,
  } = usePagination<NftPortfolioAssetsResponse>({
    queryKey: getQueryKey(ApiResource.PORTFOLIO, { search: search.value }),
    queryFn: (offset: number, limit: number) =>
      api.clientPortfolio.getSmartNftInvestments(offset, limit, undefined, search.value || undefined),
    enabled: true,
    limit: 30,
  });

  useLayoutEffect(() => {
    removeTabs();
    setHeaderTitle(textTranslation('portfolio.collectibleHistory'));
  }, [removeTabs, textTranslation, setHeaderTitle]);

  const onTryAgainHandler = async () => {
    await Promise.all([fetchNextPage()]);
  };

  return (
    <PageStateContainer
      isLoading={isLoading || search.isUpdating}
      isError={isError && !nftsData}
      onTryAgain={onTryAgainHandler}
      showError={true}
      showLoading={true}
      textTranslation={textTranslation}
      isEmptyState={!isLoading && nftsData?.length === 0}
      showEmptyState={true}
      customEmptyStateComponent={
        search.value ? (
          <DataStatusPage
            title={textTranslation('account.noResultsFound')}
            content={textTranslation('account.noResultsFoundSub')}
          />
        ) : (
          <DataStatusPage
            icon={
              <ThemedIcon
                icon={TrendUpCollectibleIcon}
                customColor={theme.v2.surface.successLight}
                customStrokeColor={theme.v2.text.success}
                size={'full'}
              />
            }
            title={textTranslation('portfolio.noCollectibles')}
            content={textTranslation('portfolio.noCollectiblesPurchased')}
            buttonText={textTranslation('portfolio.exploreCollectibles')}
            action={() => navigate(routes.offering.smartCollectibles.root)}
            additionalContent={textTranslation('portfolio.noCollectiblesFollow')}
            iconWidth={'8.5rem'}
            iconHeight={'8.5rem'}
          />
        )
      }
    >
      <FlatList
        data={nftsData || []}
        numColumns={'auto-fill'}
        isFetching={isFetchingNextPage}
        ref={lastItemRef}
        fetchingTriggerIndex={7}
        renderItem={(item) => (
          <NftHolder
            serialNumber={item.serialNumber}
            price={`${item.nftPrice}`}
            priceText={textTranslation('global.price')}
            collectionName={item.collectionName}
            imageUrl={item.nftThumbnailUrl}
            onClick={() => navigate(`${routes.portfolio.smartCollectibles.root}/${item.id}`)}
          />
        )}
        keyExtractor={(item) => item.id}
        headerComponent={<NumberOfItems numberOfAssets={totalItems || 0} />}
      />
    </PageStateContainer>
  );
};
